import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { Amplify } from 'aws-amplify';
import outputs from '../amplify_outputs.json';
import { initializeGA } from '@/api/base';


Amplify.configure(outputs);
initializeGA();
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
