import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Navbar } from '@/components/alicia/panel/Navbar';
import { LateralNavbar } from '@/components/alicia/panel/LateralNavbar';
import { Footer } from '@/components/alicia/panel/Footer';
import { getCurrentCognitoUser, getUserTokens } from '@/api/cognito-users'


const withAliciaAuthenticatedSettings = (Component) => {
    return function withAliciaAuthenticatedSettings(props) {
        const navigate = useNavigate();
        const [user, setUser] = useState(null);
        const [userTokens, setUserTokens] = useState([]);

        useEffect(() => {
            const verifyUser = async () => {
                try {
                    const userAttributes = await fetchUserAttributes();
                    let cognitoUser = await getCurrentCognitoUser();
                    cognitoUser = {...cognitoUser, ...userAttributes};
                    setUser(cognitoUser);
                    if (cognitoUser.is_admin) {
                        const tokens = await getUserTokens();
                        setUserTokens(tokens);
                    }
                } catch {
                    navigate('/alicia/sign-in');
                }
            }
            verifyUser();
        }, []);

        return (
            <HelmetProvider>
                <Helmet>
                    <title>αlicia</title>
                    <link rel="icon" type="image/svg+xml" href="/alicia.svg" />
                </Helmet>
                <div className='flex flex-col w-full min-h-screen' style={{ background: "#F0F0F0" }}>
                    <div className='flex grow'>
                        {user ? (
                            user.is_admin && userTokens ? (
                                <div>
                                    <Navbar company_image={null}/>
                                    <h2>ACCESS TOKEN</h2>
                                    <div className='text-wrap break-words w-[1000px]'>
                                        <span>{userTokens[0]}</span>
                                    </div>
                                    <h2>ID TOKEN</h2>
                                    <h4 className='text-wrap break-words w-[1000px]'>{userTokens[1]}</h4>
                                </div>
                                ) : user.company && user.company.alicias.length ? (
                                    <>
                                        <div className='max-w-[243px] w-full grow pt-[78px] pl-[46px] hidden tablet:block' style={{ background: "#FFF" }}>
                                            <LateralNavbar company_image={user.company.image_url}/>
                                        </div>
                                        <div className='grow'>
                                            <Navbar company_image={user.company.image_url}/>
                                            <div className='grow max-tablet:px-[20px] tablet:pl-[20px] tablet:pr-[30px]'>
                                                <div className='mx-auto max-w-[1147px] w-full mb-[64px]'>
                                                {user ? <Component {...props} user={user} /> : <LoadingWheel type='alicia'/>}
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                    ) : null
                            ) :  (
                                <div className='grow'>
                                    <LoadingWheel type='alicia'/>
                                </div>
                            )
                        }
                    </div>
                    <Footer />
                </div>
            </HelmetProvider>
        );
    }
};

export default withAliciaAuthenticatedSettings;