import React from 'react';
import { Button } from '@/components/base/Button';
import { useNavigate } from 'react-router';


export const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
    <div className='mx-auto h-screen' style={{ background: "var(--error, linear-gradient(270deg, #AA8DF8 0%, #814CF2 100%))" }}>
        <div className='flex h-full justify-center items-center max-tablet:mx-[20px] tablet:mx-[127px] desktop:mx-[257px]'>
            <div className='flex flex-col max-w-[768px] max-h-[755px] w-full tablet:h-[90%] rounded-[15px]' style={{ background: "#FFF", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)" }}>
                <div className='flex flex-col items-center text-center mt-[29px] mb-[64px]'>
                    <span className='base-extra-large-title' style={{ color: "#7F3DEA", fontWeight: "500" }}>
                        Error 404
                    </span>
                    <div className='max-w-[493px] w-full mb-[22px]'>
                        <span className='base-p-large-title' style={{ color: "#646464", fontWeight: "300" }}>
                            Lo sentimos, la página que buscas no existe
                        </span>
                    </div>
                    <div className='w-[216px]'>
                        <Button 
                            name='Ir al inicio'
                            type='encodelyt-purple'
                            svg={false}
                            containerStyle={{borderRadius: "8px", boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)", height: "58px" }}
                            textStyle={{fontFamily: "Poppins"}}
                            onClick={() => navigate('/', {replace: true})}
                        />
                    </div>
                </div>
                <svg className='pb-[15px] px-[29px]' viewBox="0 0 926 545" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: "calc(100% - 296px)"}}>
                    <g clipPath="url(#clip0_951_451)">
                        <path d="M9.68214e-06 520.681H424.974L350.921 463.037L283.009 463.498L234.294 418.385L149.896 427.326L64.0404 406.562L-0.0090332 520.671L9.68214e-06 520.681Z" fill="#814CF2"/>
                        <path d="M1 -64C60.51 -60.21 378.41 -33.68 262.08 74.86C232.35 102.46 195.43 126.56 153.66 128.08C138.14 128.4 116.92 124.88 107.77 110.8C103.51 103.86 106.14 96.22 112.94 92.22C122.27 86.77 133.44 86.43 143.89 86.62C152.17 86.95 160.46 87.84 168.58 89.49C176.91 91.46 184.51 95.18 191.62 99.44C219.58 116.91 242.16 141.51 263.52 166.26C274.08 178.76 284.2 191.55 293.82 204.87C294.79 206.21 294.49 208.09 293.14 209.06C291.78 210.05 289.86 209.71 288.91 208.32C279.56 195.18 269.54 182.35 259.17 169.92C237.06 144 200.78 103.03 167.46 94.25C155.78 91.93 143.68 90.62 131.8 91.53C124.57 92.34 116.15 93.51 111.44 99.46C107.08 106.05 113.88 112.85 119.47 116.29C129.6 122.44 141.78 124.29 153.57 124.17C194.27 123.02 230.78 99.48 260.06 72.71C374.57 -30.78 58.27 -59.95 1 -64Z" fill="#C6B6FC"/>
                        <path d="M370.122 267.794C370.122 267.794 565.613 392.221 536.603 526.222C536.603 526.222 416.947 531.303 329.791 300.621L370.122 267.785V267.794Z" fill="#814CF2"/>
                        <path d="M361.486 372.323C361.486 372.323 288.32 368.752 256 262.876C256 262.876 311.18 299.184 329.791 300.621C329.791 300.621 350.906 353.482 361.486 372.314V372.323Z" fill="#AA8DF8"/>
                        <path d="M440.061 322.906C440.061 322.906 448.697 238.186 356.115 185.063C356.115 185.063 379.066 241.676 370.122 267.794C370.122 267.794 407.171 291.879 440.061 322.906Z" fill="#AA8DF8"/>
                        <path d="M370.122 267.794L351.783 242.155L311.632 273.381L329.791 300.63L370.122 267.794Z" fill="#C6B6FC"/>
                        <path d="M343.292 248.755C343.292 248.755 347.117 219.725 277.07 186.347C276.238 185.949 275.307 186.699 275.533 187.603C278.616 199.555 295.907 258.184 322.068 265.272L343.292 248.764V248.755Z" fill="#814CF2"/>
                        <path d="M533.167 456.356C533.167 456.356 484.742 486.064 472.896 501.343" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
                        <path d="M406.023 353.609C411.214 349.567 412.217 342.172 408.266 337.1L361.595 277.35L343.174 292.331L389.465 351.737C393.417 356.809 400.832 357.641 406.023 353.6V353.609Z" fill="white"/>
                        <path d="M472.859 459.412C482.983 459.412 491.19 451.207 491.19 441.086C491.19 430.965 482.983 422.761 472.859 422.761C462.736 422.761 454.529 430.965 454.529 441.086C454.529 451.207 462.736 459.412 472.859 459.412Z" fill="white"/>
                        <path d="M444.067 417.129C450.095 417.129 454.981 412.243 454.981 406.216C454.981 400.19 450.095 395.304 444.067 395.304C438.039 395.304 433.152 400.19 433.152 406.216C433.152 412.243 438.039 417.129 444.067 417.129Z" fill="white"/>
                        <path d="M420.519 386.399C424.644 386.399 427.988 383.056 427.988 378.932C427.988 374.807 424.644 371.464 420.519 371.464C416.393 371.464 413.049 374.807 413.049 378.932C413.049 383.056 416.393 386.399 420.519 386.399Z" fill="white"/>
                        <path d="M552.729 344.952C548.047 350.938 542.772 357.37 537.813 363.166C536.543 364.657 535.339 366.211 534.118 367.744C531.647 370.835 528.643 373.367 525.424 375.654C523.779 376.789 522.086 377.872 520.165 378.735C519.942 378.844 519.664 378.591 519.775 378.358C522.227 372.86 525.793 368.002 530.245 364.01C532.867 361.831 536.478 358.518 539.021 356.174C543.275 352.376 547.985 348.216 552.34 344.567C552.574 344.368 552.934 344.695 552.73 344.943L552.729 344.952Z" fill="#C6B6FC"/>
                        <path d="M482.863 292.371C482.929 299.969 482.805 308.289 482.522 315.907C482.451 317.866 482.48 319.828 482.481 321.796C482.472 325.749 481.702 329.598 480.6 333.397C480.017 335.301 479.369 337.206 478.407 339.082C478.297 339.305 477.927 339.277 477.866 339.033C476.359 333.206 476.134 327.183 477.132 321.285C477.83 317.947 478.596 313.113 479.122 309.689C480.095 304.071 481.184 297.884 482.322 292.322C482.378 292.02 482.866 292.053 482.863 292.371Z" fill="#C6B6FC"/>
                        <path d="M335.895 449.865C342.822 446.728 350.472 443.473 357.562 440.652C359.379 439.929 361.17 439.106 362.96 438.311C366.577 436.72 370.421 435.87 374.336 435.336C376.317 435.092 378.324 434.921 380.422 435.047C380.675 435.047 380.793 435.4 380.594 435.562C375.874 439.296 370.457 441.945 364.66 443.41C361.323 444.124 356.594 445.381 353.248 446.285C347.714 447.677 341.61 449.178 336.066 450.389C335.768 450.462 335.596 450 335.895 449.874V449.865Z" fill="#C6B6FC"/>
                        <path d="M304.506 421.821C315.159 420.238 326.852 418.693 337.586 417.445C340.344 417.119 343.102 416.704 345.851 416.306C351.376 415.528 356.947 415.51 362.517 415.827C365.311 416.017 368.115 416.279 370.945 416.848C371.099 416.875 371.198 417.029 371.162 417.183C371.144 417.282 371.072 417.355 370.981 417.391C368.268 418.376 365.537 419.054 362.797 419.651C360.057 420.238 357.308 420.7 354.55 421.061C349.034 421.857 343.446 421.586 337.902 421.766L329.574 422.02C321.498 422.191 312.582 422.354 304.543 422.354C304.389 422.354 304.271 422.227 304.271 422.083C304.271 421.947 304.371 421.839 304.506 421.812V421.821Z" fill="#C6B6FC"/>
                        <path d="M533.364 273.057C529.034 287.568 524.088 303.433 519.409 317.892C518.208 321.615 517.103 325.367 515.962 329.116C513.679 336.621 510.674 343.854 507.356 350.957C505.693 354.513 503.924 358.037 501.875 361.463C501.796 361.589 501.626 361.632 501.498 361.562C501.396 361.503 501.347 361.388 501.369 361.281C501.951 357.336 502.804 353.488 503.761 349.681C505.682 342.076 507.928 334.575 510.9 327.319C512.381 323.696 513.896 320.085 515.324 316.446C517.442 310.997 521.777 300.039 523.964 294.617C526.935 287.371 529.826 280.105 532.857 272.884C532.911 272.745 533.073 272.683 533.212 272.737C533.343 272.79 533.407 272.934 533.372 273.067L533.364 273.057Z" fill="#C6B6FC"/>
                        <path d="M275.867 366.311C290.219 371.156 305.899 376.653 320.196 381.843C327.548 384.501 335.081 386.743 342.207 389.988C349.387 393.225 356.44 396.651 363.123 400.9L362.924 401.407C358.999 400.692 355.192 399.698 351.412 398.604C343.88 396.416 336.464 393.903 329.311 390.684C325.739 389.075 322.185 387.43 318.595 385.884C313.224 383.578 302.418 378.859 297.082 376.481C289.947 373.263 282.776 370.108 275.668 366.826L275.867 366.32V366.311Z" fill="#C6B6FC"/>
                        <path d="M922.42 522.299H558.022L609.458 492.257L679.848 403.848L749.488 418.629L785.986 369.566L821.785 364.792L843.662 392.639L892.19 405.369L922.42 522.299Z" fill="#814CF2"/>
                        <path d="M413.047 522.299H735.501L730.554 479.762L692.04 426.938L649.981 424.876L588.155 398.125H536.465L513.894 420.266L456.905 441.348L427 472.566L413.047 522.299Z" fill="#AA8DF8"/>
                        <path d="M289.872 536.312H593.372L588.715 506.748L552.471 470.025L512.881 468.597L454.68 450H406.029L384.787 465.387L331.144 480.042L303.003 501.74L289.872 536.312Z" fill="#C6B6FC"/>
                        <path d="M62.9835 71.9498C81.752 71.9498 96.967 56.7388 96.967 37.9749C96.967 19.2111 81.752 4 62.9835 4C44.2149 4 29 19.2111 29 37.9749C29 56.7388 44.2149 71.9498 62.9835 71.9498Z" fill="#AA8DF8"/>
                        <path d="M806.531 56.9934C816.999 56.9934 825.485 48.5095 825.485 38.0441C825.485 27.5787 816.999 19.0948 806.531 19.0948C796.063 19.0948 787.577 27.5787 787.577 38.0441C787.577 48.5095 796.063 56.9934 806.531 56.9934Z" fill="#AA8DF8"/>
                        <path d="M106.951 272.938C117.419 272.938 125.905 264.455 125.905 253.989C125.905 243.524 117.419 235.04 106.951 235.04C96.4832 235.04 87.9971 243.524 87.9971 253.989C87.9971 264.455 96.4832 272.938 106.951 272.938Z" fill="#AA8DF8"/>
                        <path d="M835.966 239.986C846.434 239.986 854.92 231.502 854.92 221.037C854.92 210.572 846.434 202.088 835.966 202.088C825.498 202.088 817.012 210.572 817.012 221.037C817.012 231.502 825.498 239.986 835.966 239.986Z" fill="#C6B6FC"/>
                        <path d="M294.277 101.176C298.192 101.176 301.367 98.0024 301.367 94.0879C301.367 90.1734 298.192 87 294.277 87C290.361 87 287.187 90.1734 287.187 94.0879C287.187 98.0024 290.361 101.176 294.277 101.176Z" fill="#C6B6FC"/>
                        <path d="M655.09 299.176C659.005 299.176 662.179 296.002 662.179 292.088C662.179 288.173 659.005 285 655.09 285C651.174 285 648 288.173 648 292.088C648 296.002 651.174 299.176 655.09 299.176Z" fill="#C6B6FC"/>
                        <path d="M174.249 193.326C178.165 193.326 181.339 190.153 181.339 186.238C181.339 182.324 178.165 179.15 174.249 179.15C170.333 179.15 167.159 182.324 167.159 186.238C167.159 190.153 170.333 193.326 174.249 193.326Z" fill="#C6B6FC"/>
                        <path d="M334.455 133.878C336.907 133.878 338.895 131.891 338.895 129.439C338.895 126.987 336.907 125 334.455 125C332.003 125 330.015 126.987 330.015 129.439C330.015 131.891 332.003 133.878 334.455 133.878Z" fill="#C6B6FC"/>
                        <path d="M549.026 129.058C566.161 129.058 580.052 115.166 580.052 98.0292C580.052 80.8922 566.161 67 549.026 67C531.891 67 518 80.8922 518 98.0292C518 115.166 531.891 129.058 549.026 129.058Z" fill="#AA8DF8"/>
                        <path d="M411.164 60.1929C427.79 60.1929 441.268 46.7183 441.268 30.0965C441.268 13.4746 427.79 0 411.164 0C394.538 0 381.06 13.4746 381.06 30.0965C381.06 46.7183 394.538 60.1929 411.164 60.1929Z" fill="#814CF2"/>
                        <path d="M588.601 268.193C598.875 268.193 607.203 259.867 607.203 249.597C607.203 239.326 598.875 231 588.601 231C578.328 231 570 239.326 570 249.597C570 259.867 578.328 268.193 588.601 268.193Z" fill="#814CF2"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_951_451">
                            <rect width="926" height="545" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
              
            </div>
        </div>
    </div>
    );
};