import React from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const YoutubeSlide = ({ url, isSelected }) => {
    return (
        <div className='flex justify-center'>
            <ReactPlayer 
                width='640px'
                url={url} 
                playing={false} 
            />
        </div>
    )
};

export const TestimonialVideoCarousel = ({ videoIds }) => {
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/0.jpg`;

    const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

    const customRenderThumb = (children) =>
        children.map((item) => {
            const videoId = getVideoId(item.props.url);
            return <img className='w-full h-full' src={getVideoThumb(videoId)}/>;
        });

    return ( 
        <>
        <style>
            {`
            .carousel .slide iframe {
                margin: none;
                height: 315px;
            }
            div.carousel-root {
                width: calc(100% + 80px);
                margin-left: -40px;
            }
            div.carousel:nth-child(1) {
                height: 315px !important;
            }
            }
            /* Override styles for the thumbnail carousel */
            .carousel .thumbs-wrapper {
                margin: 0px;
                margin-top: 36px;
            }
            .thumbs {
                display: flex;
                justify-content: center; 
                gap: 12px;
                flex-wrap: wrap;
            }            
            .carousel .thumb {
                width: 131px !important;
                height: 78px !important;
            }
            @media (min-width: 834px) {
                .carousel .thumb {
                    width: 213px !important;
                    height: 120px !important;
                }
            }
            .carousel .thumb.selected, .carousel .thumb:hover {
                border: 3px solid #DB1F6B;
            }
            `}
        </style>
        <div className='overflow-hidden'>
            
        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showStatus={false} showArrows={false} autoPlay={false} thumbWidth='213px'>
            {videoIds.map((videoId, index) => (
                <YoutubeSlide key={index} url={`https://www.youtube.com/embed/${videoId}`}/>
            )) }
        </Carousel>
        </div>
        </>

    );
};