import React from 'react';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { PostCard } from '@/components/encodelyt/blog/PostCard';


export const PostGrid = ({ posts, smallVersionType = '1' }) => {

    // [1, 2, 3, 4, 5, 6, 7] => [[1, 2, 3], [4, 5, 6], [7]]
    const createListOfTriplet = (elements) => {
        const listOfPairs = [];
        for (let i = 0; i < elements.length; i += 3) {
            const pair = elements.slice(i, i + 3);
            listOfPairs.push(pair);
        }
        return listOfPairs;
    }
    const tripletsPosts = createListOfTriplet(posts);

    if (!posts.length) {
        return <LoadingWheel type='encodelyt' />
    }

    return (
        <>
        {/* Phone version */}
        <div className='w-full hidden max-tablet:block'>
            {posts.map((post, index) => (
                <div key={index + posts.length} className={ `${smallVersionType == '1' ? 'h-[320px]' : ''} ${index === posts.length - 1 ? '' : 'pb-[20px]'}`}>
                    <PostCard post={post} type={smallVersionType}/>
                </div>
            ))}
        </div>
        {/* Desktop version */}
        <div className='w-full hidden tablet:block'>
            {tripletsPosts.map((tripletsPost, index) => (
                <div key={index} className={`flex${index === tripletsPosts.length - 1 ? '' : ' mb-[20px]'}`}>
                    {tripletsPost.length === 3 ? (
                        <div>
                            <div className='h-[550px] mb-[20px]'>
                                <PostCard post={tripletsPost[0]} type='1'/> 
                            </div>
                            <div className='flex gap-[20px] h-[550px]'>
                                <div className='w-1/2'>
                                    <PostCard post={tripletsPost[1]} type='2'/> 
                                </div>
                                <div className='w-1/2'> 
                                    <PostCard post={tripletsPost[2]} type='1'/> 
                                </div>
                            </div>
                        </div>
                    ) : tripletsPost.length === 2 && tripletsPosts.length === 1 ? (
                        <div className='flex gap-[20px] h-[550px]'>
                            <div className='w-1/2'>
                                <PostCard post={tripletsPost[0]} type='1'/> 
                            </div>
                            <div className='w-1/2'> 
                                <PostCard post={tripletsPost[1]} type='2'/> 
                            </div>
                        </div>
                    ) : tripletsPost.length === 2 ? (
                        <div>
                            <div className='h-[550px] mb-[20px]'>
                                <PostCard post={tripletsPost[0]} type='1'/> 
                            </div>
                            <div className='h-[550px]'>
                                <PostCard post={tripletsPost[1]} type='3'/> 
                            </div>
                        </div>
                    ) : (
                        <div className='h-[550px] w-full'>
                            <PostCard post={tripletsPost[0]} type='1'/> 
                        </div>
                    )}
                </div>
            ))}
        </div>
        </>
    )
};