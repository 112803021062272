import React, { useState, useEffect } from 'react';
import withAliciaAuthenticatedSettings from '@/hocs/withAliciaAuthenticatedSettings';
import { SoundSegmentTable } from '@/components/alicia/panel/SoundSegmentTable';
import { VolumeSelector } from '@/components/alicia/panel/VolumeSelector';
import { asyncFoldTry } from '@/api/base';
import { getAliciaVolume, updateAliciaVolume, getGenders, getAges, getAliciaSoundSegments, updateAliciaSoundSegments } from '@/api/cognito-users';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Button } from '@/components/base/Button';


const SoundPage = ({user}) => {
    const [volume, setVolume] = useState(null);
    const [genders, setGenders] = useState(null);
    const [ages, setAges] = useState(null);
    const [soundSegments, setSoundSegments] = useState({});
    const [isSoundSegmentsLoading, setIsSoundSegmentsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAliciaVolume(user.company.alicias[0].id);
            setVolume(data);
        }
        asyncFoldTry(fetchData);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsSoundSegmentsLoading(true);
            const gendersData = await getGenders();
            const agesData = await getAges();
            const genderIdsByValue = gendersData.reduce((acc, current) => ({ ...acc, [current.value]: current.id }), {});
            const ageIdsByValue = agesData.reduce((acc, current) => ({ ...acc, [current.value]: current.id }), {});
            setGenders(genderIdsByValue);
            setAges(ageIdsByValue);
            const data = await getAliciaSoundSegments(user.company.alicias[0].id);
            const customSoundSegments = data.filter(soundSegment => soundSegment.default === false).map(soundSegment => ({'old_segment': soundSegment, 'new_segment': soundSegment}));
            const defaultSoundSegment = data.find(soundSegment => soundSegment.default === true);
            setSoundSegments({'default': defaultSoundSegment, 'custom': customSoundSegments});
            setIsSoundSegmentsLoading(false);
        }
        asyncFoldTry(fetchData, () => setIsSoundSegmentsLoading(false));
    }, []);

    const handleSave = async () => {
        const soundSegmentPayload = soundSegments.custom.map(soundSegment => {
            const segmentPayload = {
                new_segment: {
                    gender_id: genders[soundSegment.new_segment.gender], 
                    age_id: ages[soundSegment.new_segment.age], 
                    active: soundSegment.new_segment.active
                }
            }
            if (soundSegment.old_segment) {
                segmentPayload.old_segment = {
                    gender_id: genders[soundSegment.old_segment.gender], 
                    age_id: ages[soundSegment.old_segment.age], 
                    active: soundSegment.old_segment.active
                }
            }
            return segmentPayload;
        })
        await asyncFoldTry(async () => {
            setIsSaving(true);
            const volumeData = await updateAliciaVolume(user.company.alicias[0].id, {volume: volume});
            setVolume(volumeData);
            const data = await updateAliciaSoundSegments(user.company.alicias[0].id, soundSegmentPayload);
            const customSoundSegments = data.filter(soundSegment => soundSegment.default === false).map(soundSegment => ({'old_segment': soundSegment, 'new_segment': soundSegment}));
            const defaultSoundSegment = data.find(soundSegment => soundSegment.default === true);
            setSoundSegments({'default': defaultSoundSegment, 'custom': customSoundSegments});
            setIsSaving(false);
        }, (errorMessage) => {
            setIsSaving(false); 
            alert(errorMessage);
        })
    }

    return (
        <div className='grow'>
            <div className='rounded-[15px] py-[4px] text-center' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                    <span className='base-p-title' style={{ fontWeight: '600' }}>Configuraciones</span>
            </div>
            <div className='flex gap-[8px] py-[20px]'>
                <svg className='w-[32px] h-[32px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.6667 27.6334V24.9C20.6667 24.3223 22.2778 23.2112 23.5 21.5667C24.7222 19.9223 25.3333 18.0556 25.3333 15.9667C25.3333 13.8778 24.7222 12.0112 23.5 10.3667C22.2778 8.72227 20.6667 7.61116 18.6667 7.03338V4.30005C21.4222 4.92227 23.6667 6.31672 25.4 8.48338C27.1333 10.65 28 13.1445 28 15.9667C28 18.7889 27.1333 21.2834 25.4 23.4501C23.6667 25.6167 21.4222 27.0112 18.6667 27.6334ZM4 20V12H9.33333L16 5.33338V26.6667L9.33333 20H4ZM18.6667 21.3334V10.6C19.7111 11.0889 20.5278 11.8223 21.1167 12.8C21.7056 13.7778 22 14.8445 22 16C22 17.1334 21.7056 18.1834 21.1167 19.15C20.5278 20.1167 19.7111 20.8445 18.6667 21.3334ZM13.3333 11.8L10.4667 14.6667H6.66667V17.3334H10.4667L13.3333 20.2V11.8Z" fill="#181818"/>
                </svg> 
                <span className='base-p-small-title' style={{ fontWeight: '600' }}>Volumen</span>
            </div>
            <div className='flex max-tablet:flex-col items-center rounded-[15px] py-[20px] max-tablet:px-[21px] tablet:px-[54px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                <div className='flex gap-[10px] max-tablet:mr-[153px] tablet:mr-[27px]'>
                    <svg className='w-[25x] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2876 20.7251V18.6751C15.7876 18.2418 16.9959 17.4084 17.9126 16.1751C18.8293 14.9418 19.2876 13.5418 19.2876 11.9751C19.2876 10.4084 18.8293 9.00843 17.9126 7.7751C16.9959 6.54176 15.7876 5.70843 14.2876 5.2751V3.2251C16.3543 3.69176 18.0376 4.7376 19.3376 6.3626C20.6376 7.9876 21.2876 9.85843 21.2876 11.9751C21.2876 14.0918 20.6376 15.9626 19.3376 17.5876C18.0376 19.2126 16.3543 20.2584 14.2876 20.7251ZM3.2876 15.0001V9.0001H7.2876L12.2876 4.0001V20.0001L7.2876 15.0001H3.2876ZM14.2876 16.0001V7.9501C15.0709 8.31676 15.6834 8.86676 16.1251 9.6001C16.5668 10.3334 16.7876 11.1334 16.7876 12.0001C16.7876 12.8501 16.5668 13.6376 16.1251 14.3626C15.6834 15.0876 15.0709 15.6334 14.2876 16.0001ZM10.2876 8.8501L8.1376 11.0001H5.2876V13.0001H8.1376L10.2876 15.1501V8.8501Z" fill="#DB1F6B"/>
                    </svg> 
                    <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>Volumen</span>
                </div>
                <div className='flex justify-between max-w-[260px] w-full'>
                    <VolumeSelector initialValue={volume} valueSetter={setVolume} width={217} />
                    <span className='base-p-small-text ml-[26px]' style={{ fontWeight: '500', color: "#535353" }}>{volume}</span>
                </div>
            </div>
            <div className='flex gap-[8px] pt-[33px] pb-[21px]'>
                <svg className='w-[32px] h-[32px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3333 28C11.8667 28 10.6111 27.4778 9.56667 26.4333C8.52222 25.3889 8 24.1333 8 22.6667C8 21.2 8.52222 19.9444 9.56667 18.9C10.6111 17.8556 11.8667 17.3333 13.3333 17.3333C13.8444 17.3333 14.3167 17.3944 14.75 17.5167C15.1833 17.6389 15.6 17.8222 16 18.0667V4H24V9.33333H18.6667V22.6667C18.6667 24.1333 18.1444 25.3889 17.1 26.4333C16.0556 27.4778 14.8 28 13.3333 28Z" fill="#181818"/> 
                </svg> 
                <span className='base-p-small-title' style={{ fontWeight: '600' }}>Sonido:</span>
            </div>
            <SoundSegmentTable 
                isLoading={isSoundSegmentsLoading} 
                soundSegments={soundSegments}
                soundSegmentsSetter={setSoundSegments}
                genders={genders}
                ages={ages}
                aliciaId={user.company.alicias[0].id}
            />
            <div className='flex w-full max-tablet:justify-center max-tablet:mt-[48px] tablet:justify-end'>
                <div className='max-w-[213px] w-full'>
                    {isSaving ? (
                        <LoadingWheel type='alicia'/>
                    ): (
                        <Button 
                            name='Guardar Cambios' 
                            onClick={handleSave}
                            svg={false}
                            type='alicia-pink'
                            cls='h-[50px] w-[213px]'
                            containerStyle={{
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                                background: "#DB1F6B",
                                border: "none" 
                            }}
                            textStyle={{fontSize: "20px" }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
};

export default withAliciaAuthenticatedSettings(SoundPage);