import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/base/Button';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { SignIn } from '@/components/alicia/auth/SignIn';
import { Footer } from '@/components/alicia/auth/Footer';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAliciaLogo, getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import withAliciaBaseSettings from '@/hocs/withAliciaBaseSettings'


const SignInPage = () => {
    const [logoV1, setLogoV1] = useState(null);
    const [logoV2, setLogoV2] = useState(null);
    const navigate = useNavigate();

    useEffect(()=> {
        const verifyUser = async () => {
            try {
                await getCurrentUser();
                navigate('/alicia/panel');
            } catch (error) {
                return true;
            }
        }
        const fetchData = async () => {
          const logoData = await fetchAliciaLogo();
          setLogoV1(logoData.v1 ? logoData.v1.data : null);
          setLogoV2(logoData.v2 ? logoData.v2.data : null);
        }
        verifyUser() && asyncFoldTry(fetchData);
    }, []);

    return (
    <div className='flex flex-col w-full min-h-screen' style={{ background: "linear-gradient(180deg, #DB1F7B 0%, #A11346 100%)" }}> 
        <div className='max-w-[1440px] w-full h-full grow mx-auto flex items-center relative'>
            <div className='max-w-[212px] w-full absolute top-[86px] left-[20px] desktop:left-[147px] hidden tablet:block'>
                <Button 
                    name='Volver' 
                    onClick={() => navigate('/alicia')}
                    type='alicia-white'
                    svgPosition='left'
                />
            </div>

            <div className='flex flex-col w-full h-full items-center my-[30px] mx-[20px]'>
                {logoV1 && logoV2 ? (
                    <>
                        <img
                            className='h-[63px] mb-[30px] hidden tablet:block' 
                            src={getFullUrl(logoV1.attributes.url)}
                            alt={logoV1.attributes.alternativeText}
                            style={{ filter: "brightness(0) invert(1)" }}
                        />
                        <img
                            className='h-[63px] mb-[30px] hidden max-tablet:block' 
                            src={getFullUrl(logoV2.attributes.url)}
                            alt={logoV2.attributes.alternativeText}
                            style={{ filter: "brightness(0) invert(1)" }}
                        />
                    </>) : (
                    <LoadingWheel type='alicia' />
                )}
                <SignIn />
                <div className='max-w-[486px] mt-[16px] w-full hidden max-tablet:block'>
                    <Button 
                        name='Volver al home' 
                        onClick={() => navigate('/alicia')}
                        type='alicia-white'
                        svg={false}
                        textStyle={{color: "#787987", fontSize: "16px"}}
                        containerStyle={{height: "48px"}}
                    />
                </div>
            </div>
        </div>
        <Footer />
    </div>
    );
};


export default withAliciaBaseSettings(SignInPage);