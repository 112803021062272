import React, { useState } from 'react';
import '@/styles/alicia/Input.css';


export const Input = ({ type, name, placeholder, register, setFocus }) => {
  const [focused, setFocused] = useState(false);

  const handleContainerClick = () => {
    setFocus(name);
    setFocused(true);
  }

  let SvgIcon = null;

  if (type === 'email') {
    SvgIcon = (
      <svg className='sign-in-input-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z" fill="#787987"/>
      </svg>
    );
  } else if (type === 'password') {
    SvgIcon = (
      <svg className='sign-in-input-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z" fill="#787987"/>
      </svg>
    );
  }

  return (
    <>
      <div className={`sign-in-input-container ${focused ? 'sign-in-input-container-focused' : ''} my-[10px]`} onClick={handleContainerClick} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
        {SvgIcon}
        <input 
          className='sign-in-input-text w-3/4' 
          autoComplete="off"
          type={type == 'email' ? 'text' : type} 
          placeholder={placeholder}
          {...register(name, {
            required: true,
            pattern: type == 'email' && /^\S+@\S+$/i
          })}
        />
      </div>
    </>
  );
};