import React, { useState, useEffect } from 'react';
import { SoundSegment } from '@/components/alicia/panel/SoundSegment';
import { LoadingWheel } from '@/components/base/LoadingWheel';


export const SoundSegmentTable = ({ isLoading, soundSegments, soundSegmentsSetter, genders, ages, aliciaId }) => {
    const [openedDropDowns, setOpenedDropDowns] = useState([]);

    useEffect(() => {
        if (!soundSegments.custom) return;
        setOpenedDropDowns(Array.from({ length: 2*soundSegments.custom.length }).fill(false));
    }, [soundSegments]);

    // Agrega un segmento sin old_segment a la lista global.
    const addSegment = () => {
        const newGender = Object.keys(genders).find(gender => gender != 'ANY');
        const newAge = Object.keys(ages).find(age => age != 'ANY');
        const newSegment = {'gender': newGender, 'age': newAge, 'active': true};
        const updatedSegments = JSON.parse(JSON.stringify(soundSegments));
        updatedSegments.custom.push({'new_segment': newSegment});
        soundSegmentsSetter(updatedSegments);
    };

    return (
        <>
            {isLoading ? (
                <LoadingWheel type='alicia'/>
            ) : (
                <div className='flex flex-col items-center gap-[8px]'>
                    <div className='w-full'>
                        <SoundSegment 
                            soundSegment={soundSegments.default} 
                            soundSegments={soundSegments} 
                            soundSegmentsSetter={soundSegmentsSetter}
                            aliciaId={aliciaId}
                        />
                    </div>
                    {soundSegments.custom.map((soundSegment, index) => (
                        <div key={index} className='w-full'>
                            <SoundSegment 
                                soundSegment={soundSegment} 
                                index={index} 
                                soundSegments={soundSegments} 
                                soundSegmentsSetter={soundSegmentsSetter}
                                openedDropDowns={openedDropDowns}
                                openedDropDownsSetter={setOpenedDropDowns}
                                genders={genders}
                                ages={ages}
                                aliciaId={aliciaId}
                            />
                        </div>
                    ))}
                    <div 
                        className='w-[32px] h-[32px] max-tablet:mt-[16px] tablet:mt-[24px] rounded-full cursor-pointer' 
                        style={{ background: "var(--botones-cn-degradado, linear-gradient(180deg, #DB1F6B -5.68%, #C21455 100%))"}}
                        onClick={() => addSegment()}
                    >
                        <svg className='w-[32px] h-[32px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9998 17.0001H6.6665V15.0001H14.9998V6.66675H16.9998V15.0001H25.3332V17.0001H16.9998V25.3334H14.9998V17.0001Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            )}
        </>
    )
};