import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { Button } from '@/components/base/Button';
import { Input } from '@/components/alicia/auth/Input';
import { Error } from '@/components/alicia/auth/Error';
import '@/styles/alicia/SignIn.css';


export const SignIn = () => {
  const { register, handleSubmit, formState: { errors }, setFocus, watch, reset} = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const [signInError, setSignInError] = useState(false);
  const [cognitoError, setCognitoError] = useState(false);
  const [lastEmail, setLastEmail] = useState(null);
  const [lastPassword, setLastPassword] = useState(null);
  const watchedEmail = watch('email');
  const watchedPassword = watch('password');
  const navigate = useNavigate();

  useEffect(() => {
    if (watchedEmail != undefined && watchedPassword != undefined) {
      // OnSubmit
      if (watchedEmail === lastEmail && watchedPassword === lastPassword) {
        setSignInError(errors.email || errors.password || cognitoError);
      }
      // OnChange 
      else {
        setSignInError(false);
      }
      setLastEmail(watchedEmail);
      setLastPassword(watchedPassword);
      reset();
    } 
  }, [watchedEmail, watchedPassword]);

  const onSubmit = async ({ email, password }) => {
    setCognitoError(false);
    const username = email;
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      const { signInStep } = nextStep;
      if (!isSignedIn && signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        const challengeResponse = password;
        confirmSignIn({ challengeResponse });
      } else if (!isSignedIn) {
        throw new Error('not supported signInStep');
      }
      navigate('/alicia/panel');
    } catch {
      setCognitoError(true);
    }
  };

  return (
    <div className='sign-in-container max-w-[486px] w-full px-[20px] flex flex-col items-center'>
      <span className='sign-in-title-text max-tablet:text-[24px] tablet:text-[36px] font-semibold pt-[32px] pb-[10px]' style={{ color: "#DB1F7B" }}>
        Ingreso Clientes
      </span>
      <div className='flex flex-col justify-center w-full'>
        <Input type='email' name='email' placeholder='Email' register={register} setFocus={setFocus} />
        <Input type='password' name='password' placeholder='Contraseña' register={register} setFocus={setFocus} />
        {signInError ? <Error /> : null}
        <div className='flex max-tablet:justify-center tablet:justify-end mt-[12px]'>
          <Link to={'/alicia/change-password'}>
            <span className='sign-in-title-text text-[16px] font-light' style={{color: "#787987"}}>
              ¿olvidaste tu contraseña?
            </span>
          </Link>
        </div>
        <div className={`my-[24px] ${signInError ? 'animate-shake animate-duration-300 animate-normal animate-twice' : ''}`}>
          <Button 
            name='Ingresa' 
            onClick={handleSubmit(onSubmit)} 
            type='alicia-pink' 
            svg={false} 
            cls={`${signInError ? 'animate-color-change pointer-events-none' : ''}`} 
            textStyle={{fontSize: "24px" }}
          />
        </div>
      </div>
    </div>
  );
};
// https://docs.amplify.aws/javascript/start/getting-started/installation/
// https://www.youtube.com/watch?v=puaydMvngj4