import { ClipLoader } from 'react-spinners';


export const LoadingWheel = ({ type, margin=false, wheelColor=null, size=50 }) => {
  let color;
  if (type == 'encodelyt') {
    color = '#814CF2';
  } else if (type == 'alicia') {
    color = '#C21455';
  }
  if (wheelColor) {
    color = wheelColor;
  }
  return (
    <div className={`flex justify-center items-center w-full h-full ${margin ? 'm-10' : ''}`}>
        <ClipLoader color={color} loading={true} size={size} />
    </div>
  );
}