import React from 'react';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/Service.css';


export const Team = ({ team }) => {
    return (
    <div className='tablet:h-[766px]'>
        <div className='flex max-tablet:flex-col max-tablet:items-center tablet:h-1/2 w-full pb-[12px]'>
            <div className='max-tablet:w-full tablet:w-1/2 h-full tablet:pr-[12px]'>
                <img 
                    className='w-full max-phone:h-[200px] max-tablet:h-[300px] tablet:h-full object-cover' 
                    src={getFullUrl(team.image.data.attributes.url)} 
                    alt={team.image.data.attributes.alternativeText} 
                    style={{ borderRadius: '15px', filter: 'grayscale(100%)' }}
                />
            </div>
            <div className='max-tablet:mt-[16px] max-tablet:w-full tablet:w-1/2 tablet:pl-[12px] overflow-hidden'>
                <span className='base-p-text'>{team.introduction}</span>
            </div>
        </div>
        <div className='flex max-tablet:flex-col max-tablet:items-center max-tablet:gap-[24px] tablet:h-1/2 w-full pt-[12px]'>
            <div className='max-tablet:w-full tablet:w-1/2 tablet:pr-[12px]'>
                <div className='w-full h-full px-[16px] py-[10px] overflow-hidden' style={{ borderRadius: '15px', background: "#F5F3FF" }}>
                    <div className='w-full h-full overflow-hidden'>
                        <div className='flex items-center gap-[10px] p-[10px] mb-[10px]'>
                            <img 
                                className='w-[48px] h-[48px]'
                                src={getFullUrl(team.mission_icon.data.attributes.url)}
                                alt={team.mission_icon.data.attributes.alternativeText} 
                            />
                            <span className='base-p-title'>Misión</span>
                        </div>
                        <span className='base-p-text'>{team.mission}</span>
                    </div>
                </div>
                
            </div>
            <div className='max-tablet:w-full tablet:w-1/2 tablet:pl-[12px]'>
                <div className='w-full h-full px-[16px] py-[10px]' style={{ borderRadius: '15px', background: "#F5F3FF" }}>
                    <div className='w-full h-full overflow-hidden'>
                        <div className='flex items-center gap-[10px] p-[10px] mb-[10px]'>
                            <img 
                                className='w-[48px] h-[48px]'
                                src={getFullUrl(team.vision_icon.data.attributes.url)}
                                alt={team.vision_icon.data.attributes.alternativeText}
                            />
                            <span className='base-p-title'>Visión</span>
                        </div>
                        <span className='base-p-text'>{team.vision}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
