import React from 'react';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";


export const ShareIcons = ({ title }) => {
  return (
    <div className='flex gap-[16px]'>
        <TwitterShareButton url={window.location.href} hashtags={['encodelyt']} title={title}>
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_543_366)">
                    <path d="M14.5609 11.0804L23.6691 0.5H21.5107L13.6021 9.68678L7.28546 0.5H0L9.55195 14.392L0 25.4872H2.15847L10.5102 15.7856L17.181 25.4872H24.4665L14.5603 11.0804H14.5609ZM11.6045 14.5144L10.6367 13.1311L2.9362 2.12377H6.25149L12.4659 11.007L13.4337 12.3904L21.5117 23.9372H18.1964L11.6045 14.515V14.5144Z" fill="#181818"/>
                </g>
                <defs>
                    <clipPath id="clip0_543_366">
                        <rect width="24.4665" height="25" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
            </svg> 
        </TwitterShareButton>
        <FacebookShareButton url={window.location.href} hashtag='encodelyt'>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_543_368)">
                    <path d="M25.4666 13C25.4666 6.0965 19.8701 0.5 12.9666 0.5C6.06305 0.5 0.466553 6.0965 0.466553 13C0.466553 18.862 4.50255 23.781 9.94705 25.132V16.82H7.36955V13H9.94705V11.354C9.94705 7.0995 11.8726 5.1275 16.0496 5.1275C16.8416 5.1275 18.2081 5.283 18.7671 5.438V8.9005C18.4721 8.8695 17.9596 8.854 17.3231 8.854C15.2736 8.854 14.4816 9.6305 14.4816 11.649V13H18.5646L17.8631 16.82H14.4816V25.4085C20.6711 24.661 25.4671 19.391 25.4671 13H25.4666Z" fill="#181818"/>
                </g>
                <defs>
                    <clipPath id="clip0_543_368">
                        <rect width="25" height="25" fill="white" transform="translate(0.466553 0.5)"/>
                    </clipPath>
                </defs>
            </svg> 
        </FacebookShareButton>
        <WhatsappShareButton url={window.location.href} title={title}>
            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.51897 23.6596L9.90091 23.8859C11.5051 24.8379 13.3443 25.3415 15.22 25.3424H15.2239C20.9841 25.3424 25.672 20.6556 25.6744 14.895C25.6754 12.1036 24.5894 9.47853 22.6163 7.5039C21.6485 6.53007 20.4973 5.75786 19.2291 5.23198C17.9609 4.70609 16.601 4.43697 15.2281 4.4402C9.46343 4.4402 4.77542 9.1265 4.77337 14.8867C4.77053 16.8536 5.32442 18.7812 6.37102 20.4466L6.61966 20.8417L5.56373 24.6966L9.51897 23.6596ZM2.5448 27.6831L4.32872 21.1698C3.22853 19.2637 2.64973 17.1012 2.65041 14.8858C2.65331 7.95522 8.29335 2.31689 15.2241 2.31689C18.5874 2.3186 21.7442 3.62745 24.1184 6.00332C26.4926 8.3792 27.7989 11.5372 27.7977 14.8959C27.7946 21.826 22.1537 27.4653 15.2239 27.4653H15.2185C13.1143 27.4645 11.0467 26.9366 9.21018 25.9352L2.5448 27.6831Z" fill="#181818"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9524 9.502C11.7169 8.97891 11.4692 8.96831 11.2455 8.95925L10.6432 8.9519C10.4337 8.9519 10.0933 9.03051 9.80555 9.34495C9.51777 9.65939 8.70587 10.4193 8.70587 11.965C8.70587 13.5107 9.83169 15.0043 9.98857 15.2142C10.1454 15.424 12.1619 18.6971 15.3554 19.9563C18.0091 21.0029 18.5491 20.7947 19.1253 20.7424C19.7016 20.6901 20.9841 19.9825 21.2457 19.2489C21.5074 18.5152 21.5075 17.8867 21.4291 17.7553C21.3507 17.6239 21.1412 17.5458 20.8267 17.3885C20.5123 17.2313 18.968 16.4714 18.68 16.3665C18.3921 16.2615 18.1827 16.2094 17.973 16.5238C17.7634 16.8383 17.162 17.5456 16.9786 17.7553C16.7953 17.965 16.6122 17.9913 16.2978 17.8342C15.9834 17.6772 14.9717 17.3453 13.7714 16.275C12.8375 15.4423 12.2071 14.4139 12.0235 14.0996C11.84 13.7853 12.004 13.6151 12.1616 13.4586C12.3026 13.3178 12.4757 13.0917 12.6331 12.9083C12.7905 12.725 12.8423 12.5939 12.9468 12.3846C13.0514 12.1752 12.9993 11.9913 12.9207 11.8343C12.8421 11.6773 12.2317 10.1234 11.9524 9.502Z" fill="#181818"/>
            </svg>
        </WhatsappShareButton>
    </div>
  );
};
