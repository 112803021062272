import React, { useState, useEffect, useRef  } from 'react';
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { ServiceHeader, ServiceContent } from '@/components/encodelyt/home/Service';
import { getFullUrl } from '@/api/strapi';


export const ServiceList = ({ services }) => {
  const [selectedId, setSelectedId] = useState(0);
  const [hasClicked, setHasClicked] = useState(false);
  const serviceRef = useRef(null);

  useEffect(() => {
    if (hasClicked && window.innerWidth <= 834 && serviceRef.current) {
      const headerOffset = 116;
      const { top } = serviceRef.current.getBoundingClientRect();
      const offsetPosition = top + window.scrollY - headerOffset;
      window.scrollTo(0, offsetPosition); // Move 116px up
    }
  }, [selectedId]);

  const handleOnClick = (index) => {
    setHasClicked(true);
    setSelectedId(index);
  }

  return (
    <div className='flex justify-center'>
      <div className='flex flex-col justify-between max-w-[572px] w-full tablet:mr-[20px]'>
        <div className='flex flex-col'>
        {services.map((service, index) => (
          <div ref={index === selectedId ? serviceRef : null} key={index} className="flex flex-col my-[4px]" onClick={() => handleOnClick(index)}>
              <ServiceHeader 
                  name={service.name}
                  icon={service.icon.data}
                  isSelected={index === selectedId}
              />
              <div className='hidden max-tablet:block'>
                {index === selectedId ? (
                  <ServiceContent
                    name={services[selectedId].name}
                    description={services[selectedId].description}
                    image={services[selectedId].image.data} 
                  />) : null}
      
              </div>
          </div>
        ))}
        </div>
        <div className='hidden tablet:block'>
          <MouseParallaxContainer resetOnLeave={true}>
            <MouseParallaxChild factorX={0.2} factorY={0.2}>
              <img 
                className='h-[278px] object-cover mt-[4px] px-[25px] py-[25px] mx-auto'
                src={getFullUrl(services[selectedId].tridimensional_image.data.attributes.url)}
                alt={services[selectedId].tridimensional_image.data.attributes.alternativeText}
              />
            </MouseParallaxChild>
          </MouseParallaxContainer>
        </div>
      </div>
      <div className='hidden tablet:block'>
        <ServiceContent
          name={services[selectedId].name}
          description={services[selectedId].description}
          image={services[selectedId].image.data} 
        />
      </div>
    </div>
  )
};