import React from 'react';
import { Link } from 'react-router-dom';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/SmallCard.css';


export const SmallCard = ({ entity, id, title, icon=null }) => {
    return (
    <Link to={`/${entity}/${id}`}>
        <div className='flex w-full p-[8px] gap-[8px] small-card-container small-card-container-dropshadow' style={{ background: "#F5F3FF" }}>
            <div className='shrink-0'>
                {icon ? (
                    <img 
                        className='w-[48px] h-[48px]'  
                        src={getFullUrl(icon.attributes.url)} 
                        alt={icon.attributes.alternativeText}
                        style={{ filter: "brightness(0%)" }}
                    />
                ): (
                    <svg className='w-[24px] h-[24px]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
                    </svg> 
                )}
            </div>
            <div>
                <span className='base-p-small-text'>{title}</span>
            </div>
        </div>
    </Link>
    )
};


export const SmallCard2= ({ entity, id, title }) => {
    return (
    <Link to={`/${entity}/${id}`}>
        <div className='flex px-[8px] py-[18px] gap-[8px] small-card-container'>
          <svg className='shrink-0 w-[24px] h-[24px]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
            <span className='base-i-small-text'>{title}</span>
        </div>
    </Link>
    )
};


