import React from 'react';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/IntroductionImages.css';


export const IntroductionImages = ({ images }) => {
    return (
        <div className="introduction-images w-[592px] h-[386px] ml-[20px] mt-[37px] mr-[44px] hidden tablet:block z-0 relative">
            {images ? (
                <>
                    <img 
                        className="w-[283px] h-[283px] rounded-[283px] object-cover absolute top-0 right-0"
                        src={getFullUrl(images.large_image.data.attributes.url)}
                        alt={images.large_image.data.attributes.alternativeText}
                    />
                    <img 
                        className="w-[220px] h-[220px] rounded-[220px] object-cover absolute bottom-0 left-0"
                        src={getFullUrl(images.medium_large_image.data.attributes.url)}
                        alt={images.medium_large_image.data.attributes.alternativeText}
                    />
                    <img 
                        className="w-[153px] h-[153px] rounded-[153px] object-cover absolute top-0 left-[16%]"
                        src={getFullUrl(images.medium_image.data.attributes.url)}
                        alt={images.medium_image.data.attributes.alternativeText}
                    />
                    <img 
                        className="w-[111px] h-[111px] rounded-[111px] object-cover absolute bottom-0 right-[36%]"
                        src={getFullUrl(images.small_image.data.attributes.url)}
                        alt={images.small_image.data.attributes.alternativeText}
                    />
                </>
            ) : (
                <div className='h-full flex items-center justify-center '>
                    <LoadingWheel type='encodelyt' />
                </div>
            )}
        </div>
    )
}