import React, {useState, useRef } from 'react';
import { asyncFoldTry } from '@/api/base';
import { uploaAliciaSoundSegmentSound } from '@/api/cognito-users';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { SoundSegmentDropDown } from '@/components/alicia/panel/SoundSegmentDropDown';
import { SoundSegmentSwitch } from '@/components/alicia/panel/SoundSegmentSwitch';


export const SoundSegment = ({ soundSegment, index, soundSegments, soundSegmentsSetter, openedDropDowns, openedDropDownsSetter, genders, ages, aliciaId }) => {
    const fileInputRef = useRef(null);
    const [isFileUploading, setIsFileUploading] = useState(false);

    // Modifica un fieldName con un nuevo fieldValue del soundSegment actual en la lista de soundSegments
    const updateSegmentField = (fieldName, fieldValue) => {
        const updatedSegments = JSON.parse(JSON.stringify(soundSegments));
        // Si es el segmento por defecto, sólo se puede actualizar el sound_name
        if (soundSegment.default) {
            if (fieldName === 'sound_name') {
                updatedSegments.default[fieldName] = fieldValue
                soundSegmentsSetter(updatedSegments);
            }
        }
        // Si es un segmento cualquiera puede editar todos los campos
        else {
            updatedSegments.custom[index].new_segment[fieldName] = fieldValue;
            soundSegmentsSetter(updatedSegments);
        }
    };

    // Elimina un segmento de la lista global a partir de su id.
    const removeSegment = () => {
        if (!soundSegment.default) {
            const updatedSegments = JSON.parse(JSON.stringify(soundSegments));
            updatedSegments.custom = updatedSegments.custom.filter((_, soundSegmentIndex) => soundSegmentIndex !== index);
            soundSegmentsSetter(updatedSegments);
        }
    };

    const canUploadFile = () => {
        return soundSegment.default || ( soundSegment.old_segment &&
            Object.keys(soundSegment.old_segment).filter(field => field != 'sound_name').every(field => soundSegment.old_segment[field] == soundSegment.new_segment[field]));
    }

    const handleUploadIconClick = () => {
        if (canUploadFile()) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = async (event) => {
        if (!canUploadFile()) return;
        const file = event.target.files[0];
        if (!file) return;
        const formData = new FormData();
        const genderId = soundSegment.default ? genders[soundSegment.gender] : genders[soundSegment.new_segment.gender];
        const ageId = soundSegment.default ? ages[soundSegment.age] : ages[soundSegment.new_segment.age];
        formData.append('file', file);
        const uploadFile = async () => await uploaAliciaSoundSegmentSound(aliciaId, genderId, ageId, formData);
        setIsFileUploading(true);
        await asyncFoldTry(uploadFile, null, () => updateSegmentField('sound_name', file.name));
        setIsFileUploading(false);
    }

    return (
        <div className='flex flex-wrap justify-between min-h-[63px] rounded-[15px] px-[28px] pt-[17px] pb-[22px] gap-x-[50px] gap-y-[8px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
            <div className='flex items-center gap-[10px]'>
                <svg className='w-[24px] h-[24px] shrink-0 hidden tablet:block' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 21C8.9 21 7.95833 20.6083 7.175 19.825C6.39167 19.0417 6 18.1 6 17C6 15.9 6.39167 14.9583 7.175 14.175C7.95833 13.3917 8.9 13 10 13C10.3833 13 10.7375 13.0458 11.0625 13.1375C11.3875 13.2292 11.7 13.3667 12 13.55V3H18V7H14V17C14 18.1 13.6083 19.0417 12.825 19.825C12.0417 20.6083 11.1 21 10 21Z" fill="#DB1F6B"/>
                </svg> 
                <span className='base-p-small-text' style={{ color: "#535353", fontWeight: "500" }}>Archivo:</span>
                <div className='flex overflow-hidden max-tablet:w-[170px] tablet:w-[216px]'>
                    {isFileUploading ? (
                        <LoadingWheel type='alicia' size={24}/>
                    ) : (
                        <span className='base-p-small-text w-full tablet:text-center' style={{ color: "#888", fontWeight: "300", fontStyle: "italic" }}>
                            {soundSegment.default ? (soundSegment.sound_name ? soundSegment.sound_name : '-') : (soundSegment.new_segment.sound_name ? soundSegment.new_segment.sound_name : '-')}
                        </span>
                    )}
                </div>
                <svg className={`w-[24px] h-[24px] shrink-0 ${canUploadFile() ? 'cursor-pointer' : 'grayscale'} `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleUploadIconClick}>
                    <path d="M11.3 18.95H12.8V13.925L14.85 15.975L15.9 14.925L12 11.125L8.15 14.975L9.2 16.025L11.3 13.925V18.95ZM5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H14.525L20 7.475V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM13.775 8.15V3.5H5.5V20.5H18.5V8.15H13.775Z" fill="#DB1F6B"/>
                </svg>
                <input
                    ref={fileInputRef}
                    hidden
                    type="file"
                    onChange={handleFileUpload}
                    accept=".mp3, .wav"
                />
            </div>
            <div className='flex items-center max-tablet:gap-[20px] tablet:gap-[10px] max-tablet:max-w-[292px] max-tablet:w-full'>
                <span className='base-p-small-text max-tablet:w-[25%]' style={{ color: "#535353", fontWeight: "500" }}>Género:</span>
                <SoundSegmentDropDown 
                    index={soundSegment.default ? null : index}
                    fieldValue={soundSegment.default ? soundSegment.gender : soundSegment.new_segment.gender}
                    fieldSetter={soundSegment.default ? null : (fieldValue) => updateSegmentField('gender', fieldValue)} 
                    options={soundSegment.default ? null : Object.keys(genders)} 
                    width={85} 
                    openedDropDowns={soundSegment.default ? null : openedDropDowns}
                    openedDropDownsSetter={soundSegment.default ? null : openedDropDownsSetter}
                />
            </div>
            <div className='flex items-center max-tablet:gap-[20px] tablet:gap-[10px] max-tablet:max-w-[292px] max-tablet:w-full'>
                <span className='base-p-small-text max-tablet:w-[25%]' style={{ color: "#535353", fontWeight: "500" }}>Edad:</span>
                <SoundSegmentDropDown
                    index={soundSegment.default ? null : index + soundSegments.custom.length} 
                    fieldValue={soundSegment.default ? soundSegment.age : soundSegment.new_segment.age} 
                    fieldSetter={soundSegment.default ? null : (fieldValue) => updateSegmentField('age', fieldValue)}
                    options={soundSegment.default ? null :  Object.keys(ages)} 
                    width={100} 
                    openedDropDowns={soundSegment.default ? null : openedDropDowns}
                    openedDropDownsSetter={soundSegment.default ? null : openedDropDownsSetter}
                />
            </div>
            <div className='flex grow justify-between'>
             
                <div className='flex items-center max-tablet:gap-[20px] max-tablet:max-w-[292px] max-tablet:w-full'>
                    <span className='base-p-small-text max-tablet:w-[25%] hidden max-tablet:block' style={{ color: "#535353", fontWeight: "500" }}>Estado:</span>
                    <SoundSegmentSwitch 
                        fieldValue={soundSegment.default ? soundSegment.active : soundSegment.new_segment.active} 
                        fieldSetter={soundSegment.default ? null : (fieldValue) => updateSegmentField('active', fieldValue)}
                    />
                </div>
                <div className='flex items-center justify-end'>
                    <div className='max-tablet:w-[32px] max-tablet:h-[32px] tablet:w-[30px] tablet:h-[30px] shrink-0'>
                    {!soundSegment.default ? (
                        <>
                        <svg className='w-[30px] h-[30px] shrink-0 cursor-pointer hidden tablet:block' viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeSegment()}>
                            <path d="M8.75 26.25C8.0625 26.25 7.47396 26.0052 6.98438 25.5156C6.49479 25.026 6.25 24.4375 6.25 23.75V7.5H5V5H11.25V3.75H18.75V5H25V7.5H23.75V23.75C23.75 24.4375 23.5052 25.026 23.0156 25.5156C22.526 26.0052 21.9375 26.25 21.25 26.25H8.75ZM21.25 7.5H8.75V23.75H21.25V7.5ZM11.25 21.25H13.75V10H11.25V21.25ZM16.25 21.25H18.75V10H16.25V21.25Z" fill="#535353"/>
                        </svg> 
                        <div className='w-[32px] h-[32px] cursor-pointer hidden max-tablet:block rounded-full' style={{ background: '#888'}} onClick={() => removeSegment()}>
                            <div className='flex w-full h-full items-center justify-center'>
                                <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeSegment()}>
                                    <path d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        </>
                    ) : null} 
                    </div>
                </div>
            </div>
        </div>
    )
}