import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFullUrl } from '@/api/strapi';
import '@/styles/ColoredIcons.css';


export const ClientCarrousel = ({ clients }) => {
    const containerRef = useRef(null);
    const [repeatedClients, setRepeatedClients] = useState([]);
    const [initialClientWindowWidth, setinitialClientWindowWidth] = useState(0);
    const [clientWindowWidth, setClientWindowWidth] = useState(0);
    const minIconWindowWidth = 1166; // pixels
    const xMargin = 64; // pixels

    const getResizedWidth = (client) => client.icon.data.attributes.width * (80/client.icon.data.attributes.height);
 
    // Set repeated icons
    useEffect(() => {
        if (clients.length) {
            let actualClientWindowWidth = 0;
            for (const client of clients) {
                actualClientWindowWidth += getResizedWidth(client) + 2*xMargin;
            }
            const repeatedClientsArray = [...clients];
            setinitialClientWindowWidth(actualClientWindowWidth);
            // Si el tamaño es menor al mínimo requerido, agrega íconos repetidos hasta 
            // conseguir un exceso de minIconWindowWidth. 
            if (actualClientWindowWidth < minIconWindowWidth) {
                let extraWindowWidth = 0;
                let i = 0;
                while (extraWindowWidth < minIconWindowWidth) {
                    const client = clients[i % clients.length];
                    repeatedClientsArray.push(client);
                    extraWindowWidth += getResizedWidth(client) + 2*xMargin;
                    i++;
                }
                setClientWindowWidth(actualClientWindowWidth + extraWindowWidth);
            // Si el tamaño es mayor al mínimo requerido, simplemente lo duplica
            } else {
                for (const client of clients) {
                    repeatedClientsArray.push(client);
                }
                setClientWindowWidth(2*actualClientWindowWidth);
            }
            setRepeatedClients(repeatedClientsArray);
        }
    }, [clients])

    // Animate Carousel
    useEffect(() => {
        if (containerRef.current) {
            let lastFrameTime = performance.now();
            let position = 0;
            containerRef.current.style.width = `${clientWindowWidth}px`;
            const pxPerFrame60Hz = 1;
            const pxPerSecond = pxPerFrame60Hz * 60;

            const animate = () => {
                const currentTime = performance.now();
                const deltaTime = (currentTime - lastFrameTime)/1000;
                lastFrameTime = currentTime;

                position = Math.max(-initialClientWindowWidth, position-pxPerSecond*deltaTime);
                if (position === -initialClientWindowWidth) {
                    position = 0;
                }
                if (containerRef.current) {
                    containerRef.current.style.transform = `translateX(${position}px)`;
                    requestAnimationFrame(animate);
                }
            };

            const animationId = requestAnimationFrame(animate);
        
            return () => {
                cancelAnimationFrame(animationId);
            };
        }
    }, [repeatedClients]);
  
    return (
        <div className='overflow-hidden relative h-[144px]'>
            <div ref={containerRef} className={`flex absolute h-[144px]`}>
                {repeatedClients.map((client, index) => (
                    <div key={index} className='mx-[64px]'>
                        <Link to={client.url} target="_blank">
                            <img 
                                className='colored-icon h-[80px] w-full' 
                                width={getResizedWidth(client)} 
                                src={getFullUrl(client.icon.data.attributes.url)} 
                                alt={client.icon.data.attributes.alternativeText}
                                viewBox={`0 0 ${getResizedWidth(client)} 80`}
                            />
                        </Link> 
                    </div>
                ))}
            </div>
        </div>
    );
};
  