import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { MovableSmallCardList } from '@/components/encodelyt/MovableSmallCardList';
import withEncodelytSettings from '@/hocs/withEncodelytSettings';
import { fetchEncodelytService, 
         getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import '@/styles/encodelyt/Service.css';


const ServicePage = ({ services }) => {
    const [name, setName] = useState(null);
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState(null);
    const [content, setContent] = useState(null);
    const [otherServices, setOtherServices] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    // Get service instance by id
    useEffect(()=> {
        const fetchData = async () => {
            setName(null);
            setImage(null);
            setDescription(null);
            setContent(null);
            const data = await fetchEncodelytService(id);
            setName(data.name);
            setImage(data.image);
            setDescription(data.description);
            setContent(data.content);
        }
        asyncFoldTry(fetchData, ()=>navigate('/not_found', {replace: true}));
        setOtherServices(services.filter(service => service.id != id));
    }, [id]);

    // Get other services
    useEffect(()=> {
        setOtherServices(services.filter(service => service.id != id));
    }, [id, services]);

    return (
    <>
    {/* Service header */}
    <div className='pt-[128px] pb-[50px] mb-[81px]' style={{ background: "#F5F3FF", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}>
        <div className='flex gap-[20px] max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
            <div className='flex flex-col max-w-[572px] w-full'>
                {/* Service name */}
                <div className='mb-[38px]'>
                    {name ? (
                        <div className='flex flex-col gap-[53px]'>
                            <span className='base-i-text'>{`Servicios/${name}`}</span>
                            <span className='base-i-title'>{name}</span>
                        </div>
                    ) : <LoadingWheel type='encodelyt' />}
                </div>
                {/* Service description */}
                <div className='flex flex-col max-h-[270px] overflow-hidden'>
                    {description ? (
                        <BlocksRenderer
                            content={description} 
                            blocks={{
                                paragraph: ({ children }) => <span className="base-p-text mb-[24px]">{children}</span>,
                                image: ({ children, image }) => <img className="rounded-[15px] mb-[24px]" src={image.url}>{children}</img>,
                            }}
                        />
                    ) : <LoadingWheel type='encodelyt' />}
                </div>
            </div>
            {/* Service image */}
            <div className='mt-[21px]'>
                {image ? (
                    <div className='service-filter relative w-full'>  
                      <img 
                        className="max-phone:h-[186px] max-tablet:h-[206px] tablet:h-[405px] service-image object-cover w-full rounded-[15px]" 
                        src={getFullUrl(image.data.attributes.url)}
                        alt={image.data.attributes.alternativeText}
                      />
                    </div>
                ) : <LoadingWheel type='encodelyt' />}  
            </div>
        </div>
    </div>
    {/* Content */}
    <div className='flex max-tablet:flex-col max-tablet:items-center gap-[24px] mb-[114px] max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
        <div className='mt-[25px] w-full min-h-[550px]'>
            {/* Service content */}
            {content ? (
                <div className='flex flex-col'>
                    <BlocksRenderer
                        content={content} 
                        blocks={{
                            paragraph: ({ children }) => <span className="base-p-text mb-[24px]">{children}</span>,
                            image: ({ children, image }) => <img className="rounded-[15px] mb-[24px]" src={image.url}>{children}</img>,
                        }}
                    />
                </div>
            ) : <div className='h-[550px]'><LoadingWheel type='encodelyt'/></div>}
        </div>
        {/* Other services */}
        <MovableSmallCardList 
            title='Otros servicios' 
            entityList={otherServices} 
            entityType='servicios' 
            entityTitleGetter={(entity) => entity.attributes.name}
            entityIconGetter={(entity) => entity.attributes.icon.data}
             
        />
    </div>
    </>
    );
};

export default withEncodelytSettings(ServicePage);