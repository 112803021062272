import React from 'react';
import '@/styles/Button.css';


export const Button = ({ name, onClick, type, svg = true, svgContent = null, svgPosition = 'right', cls = '', containerStyle = {}, textStyle = {} }) => {
  let suffix = '';
  if (type === 'encodelyt-purple') {
    import('@/styles/encodelyt/Button-purple.css');
    suffix = '-encodelyt-purple';
  } else if (type === 'encodelyt-white') {
    import('@/styles/encodelyt/Button-white.css');
    suffix = '-encodelyt-white';
  } else if (type === 'alicia-pink') {
    import('@/styles/alicia/Button-pink.css');
    suffix = '-alicia-pink';
  } else if (type === 'alicia-white') {
    import('@/styles/alicia/Button-white.css');
    suffix = '-alicia-white';
  } else if (type === 'mopit') {
    import('@/styles/mopit/Button-mopit.css');
    suffix = '-mopit';
  }
  
  if (svgPosition === 'right') {
    if (!svgContent) {
      svgContent = (
        <svg className={`button-svg-base button-svg${suffix} mr-[6%] absolute right-0`} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.2188 16.25H5V13.75H20.2188L13.2188 6.75L15 5L25 15L15 25L13.2188 23.25L20.2188 16.25Z"/>
        </svg>
      )
    } else {
      svgContent = (
        <svgContent.type
          {...svgContent.props}
          className={`${svgContent.props.className} button-svg-base button-svg${suffix} mr-[6%] absolute right-0`}
        />
      )
    }
  } else if (svgPosition === 'left') {
    if (!svgContent) {
      svgContent = (
        <svg className={`button-svg-base button-svg${suffix} ml-[6%] absolute left-0`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0417 18.3334L33.3334 18.3334L33.3334 21.6667L13.0417 21.6667L22.375 31L20 33.3334L6.66671 20L20 6.6667L22.375 9.00004L13.0417 18.3334Z"/>
        </svg> 
      )
    } else {
      svgContent = (
        <svgContent.type
          {...svgContent.props}
          className={`${svgContent.props.className ? svgContent.props.className : ''} button-svg-base button-svg${suffix} ml-[6%] absolute left-0`}
        />
      )
    }
  }
  
  return (
    <div className={`button-container-base button-container${suffix} ${cls}`} style={containerStyle} onClick={onClick}>
        <span className={`button-text-base button-text${suffix} w-full`} style={textStyle}>{name}</span>
        {svg && svgContent}
    </div>
  );
};