import React from 'react';
import { Objective } from '@/components/alicia/home/Objective';


export const ObjectiveList = ({ objectives }) => {

  return (
    <div className="flex flex-wrap justify-evenly pt-[50px]">
        {objectives.map((objective, index) => (
            <div key={index} className="max-w-[364px] w-full my-[30px]">
                <Objective 
                    title={objective.title}
                    description={objective.description}
                    icon={objective.icon.data}
                />
            </div>
        ))}
    </div>
  );
};