import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@/components/base/Button';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { HoverableSvgIcon } from '@/components/base/HoverableSvgIcon';
import { getFullUrl } from '@/api/strapi';


export const Navbar = ({ logoV1, logoV2 }) => {
    const [isEncodelytHovered, setIsEncodelytHovered] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    const encodelytIcon = (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_601_125)">
                <path d="M8 31.9983V7.11108H24.8889V11.2705H15.9667C14.2322 11.2705 12.8506 12.611 12.8506 14.2959C12.8506 15.9799 14.2313 17.3212 15.9667 17.3212H23.5082V21.4806H15.9667C14.2322 21.4806 12.8506 22.8211 12.8506 24.506V24.8152C12.8506 26.4992 14.2313 27.8405 15.9667 27.8405H24.8889V32H8V31.9983Z" fill="white"/>
                <path d="M29.7781 23.2222C31.4963 23.2222 32.8892 21.8293 32.8892 20.1111C32.8892 18.3929 31.4963 17 29.7781 17C28.0599 17 26.667 18.3929 26.667 20.1111C26.667 21.8293 28.0599 23.2222 29.7781 23.2222Z" fill="white"/>
            </g>
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="white"/>
            <defs>
                <clipPath id="clip0_601_125">
                    <rect width="40" height="40" rx="4" fill="white"/>
                </clipPath>
            </defs>
        </svg> 
    );
    const hoveredEncodelyIcon = (
        <svg className='h-[40px] w-[40px]' viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_601_121)">
            <rect width="40" height="40" rx="4" fill="#814CF2"/>
                <path d="M8 31.9983V7.11108H24.8889V11.2705H15.9667C14.2322 11.2705 12.8506 12.611 12.8506 14.2959C12.8506 15.9799 14.2313 17.3212 15.9667 17.3212H23.5082V21.4806H15.9667C14.2322 21.4806 12.8506 22.8211 12.8506 24.506V24.8152C12.8506 26.4992 14.2313 27.8405 15.9667 27.8405H24.8889V32H8V31.9983Z" fill="white"/>
                <path d="M29.7781 23.2222C31.4963 23.2222 32.8892 21.8293 32.8892 20.1111C32.8892 18.3929 31.4963 17 29.7781 17C28.0599 17 26.667 18.3929 26.667 20.1111C26.667 21.8293 28.0599 23.2222 29.7781 23.2222Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_601_121">
                <rect width="40" height="40" rx="4" fill="white"/>
            </clipPath>
            </defs>
        </svg> 
    );

    return (
    <>
    {/* Navbar phone
    <div className='w-full z-50 navbar-phone-bg hidden max-tablet:block' >
        <div className='max-w-[1440px] mx-auto'>
            <div className='flex items-center px-[20px] py-[10px]'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 30V26.6667H35V30H5ZM5 21.6667V18.3333H35V21.6667H5ZM5 13.3333V10H35V13.3333H5Z" fill="white"/>
                </svg>   
            </div>
        </div>
    </div>
    */}
    {/* Navbar desktop */}
    <div className='w-full z-50'>
        <div className='max-w-[1440px] mx-auto'>
            <div className='flex max-tablet:justify-center tablet:justify-between mx-[20px] desktop:mx-[147px] py-[70px] px-[10px] relative'>
                <Link to='/alicia' className='max-w-[305px] w-full hidden tablet:block' aria-label="Go to Alicia homepage">
                    {logoV1 ? (
                        <img 
                            src={getFullUrl(logoV1.attributes.url)} 
                            alt={logoV1.attributes.alternativeText} 
                            style={{ filter: "brightness(0) invert(1)" }}
                        />
                    ) : <LoadingWheel type='alicia' />
                    }
                </Link>
                <div className='absolute left-0 hidden max-tablet:block'>
                    <HoverableSvgIcon 
                        isHovered={isEncodelytHovered} 
                        setIsHovered={setIsEncodelytHovered} 
                        icon={encodelytIcon} 
                        hoveredIcon={hoveredEncodelyIcon} 
                        to='/' 
                        ariaLabel='Go to Encodelyt home'
                        hideOnPhone={false}
                    />
                </div>
                <div className='flex max-tablet:flex-col-reverse items-center gap-[27px]'>
                    <div className='w-[215px] shrink-0'>
                        <Button 
                            name={`${(currentPath === '/alicia' || currentPath === '/alicia/') ? 'Acceso clientes' : 'Volver al home'}`} 
                            onClick={() => navigate((currentPath == '/alicia' || currentPath === '/alicia/') ? '/alicia/sign-in' : '/alicia')}
                            type='alicia-white'
                            svg={false}
                            cls='h-[40px]'
                            containerStyle={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", border: "none" }}
                        />
                    </div>
                    <div>
                        <Link to='/alicia' className='max-w-[305px] w-full hidden max-tablet:block' aria-label="Go to Alicia homepage">
                            {logoV2 ? (
                                <img 
                                    src={getFullUrl(logoV2.attributes.url)} 
                                    alt={logoV2.attributes.alternativeText} 
                                    style={{ filter: "brightness(0) invert(1)" }}
                                />
                            ) : <LoadingWheel type='alicia' />
                            }
                        </Link>
                        <HoverableSvgIcon 
                            isHovered={isEncodelytHovered} 
                            setIsHovered={setIsEncodelytHovered} 
                            icon={encodelytIcon} 
                            hoveredIcon={hoveredEncodelyIcon} 
                            to='/' 
                            ariaLabel='Go to Encodelyt home'
                            hideOnPhone={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};