import React from 'react';
import { Button } from '@/components/base/Button';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { getFullUrl } from '@/api/strapi';


export const MopitShowcase = ({ mopitData }) => {
    if (!mopitData) return <LoadingWheel type='encodelyt' />

    return (
        <div>
            {/* Mopit logo phone version */}
            <img 
                className='h-[90px] w-[190px] mx-auto hidden max-tablet:block'
                src={getFullUrl(mopitData.logo.data.attributes.url)}
                alt={mopitData.logo.data.attributes.alternativeText}
            />
            <div className='flex max-tablet:flex-col-reverse max-tablet:items-center tablet:justify-between min-h-[613px] relative'>
                {/* Mopit glitters */}
                <svg className='max-tablet:w-[129px] max-tablet:w-[108px] tablet:w-[217px] tablet:h-[181px] absolute top-0 max-tablet:right-[62%] tablet:right-[33%] z-10' viewBox="0 0 217 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1282_857)">
                        <path d="M84.0701 15C84.0701 15 80.9001 97.94 0.0800781 98C2.95008 97.96 84.0601 97.52 84.0601 181C84.0601 97.52 165.17 97.96 168.04 98C87.2301 97.94 84.0701 15 84.0701 15Z" fill="#F2BF46" stroke="black" strokeWidth="3" strokeLinejoin="round"/>
                        <path d="M84.5036 68C84.5036 68 83.3523 97.9783 54 98C55.0423 97.9855 84.5 97.8265 84.5 128C84.5 97.8265 113.958 97.9855 115 98C85.6513 97.9783 84.5036 68 84.5036 68Z" fill="white" stroke="black" strokeWidth="3" strokeLinejoin="round"/>
                    </g>
                    <g clipPath="url(#clip1_1282_857)">
                        <path d="M192.812 35.8345C196.723 38.4477 201.425 40.4821 207.069 41.5847C203.45 42.3303 199.208 43.5854 194.991 45.6957C189.713 48.3374 184.452 52.33 180.515 58.3568C178.034 62.1527 176.095 66.7316 174.998 72.2478C173.9 66.7316 171.961 62.1527 169.48 58.3568C165.543 52.33 160.282 48.3374 155.004 45.6957C150.788 43.5855 146.546 42.3305 142.927 41.5848C148.572 40.4823 153.275 38.4478 157.187 35.8345C163.184 31.829 167.269 26.5002 170.049 21.2035C172.712 16.13 174.187 11.0676 175.002 7.17705C175.817 11.0676 177.291 16.1299 179.953 21.2034C182.732 26.5 186.816 31.829 192.812 35.8345Z" fill="#66CDFF" stroke="black" strokeWidth="2"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1282_857">
                            <rect width="168.13" height="166" fill="white" transform="translate(0 15)"/>
                        </clipPath>
                        <clipPath id="clip1_1282_857">
                            <rect width="84" height="83" fill="white" transform="translate(133)"/>
                        </clipPath>
                    </defs>
                </svg>
                <div className='flex flex-col max-tablet:items-center tablet:mr-[20px] mt-[60px] max-w-[572px] w-full h-full'>
                    {/* Mopit logo desktop version */}
                    <div className='ml-[20%]'>                
                        <img 
                            className='h-[102px] w-[214px] hidden tablet:block'
                            src={getFullUrl(mopitData.logo.data.attributes.url)}
                            alt={mopitData.logo.data.attributes.alternativeText}
                        />
                    </div>
                    {/* Mopit description */}
                    <div 
                        className='min-h-[250px] p-[20px] mb-[24px] hidden tablet:block'
                        style={{ border: "2px solid #000", 
                                 background: "#FFF",
                                boxShadow: "2px 2px 0px 0px #000" 
                        }}
                    >
                        <span className='base-p-text' style={{ fontWeight: "400" }}>{mopitData.description}</span>
                    </div>
                    <div className='mb-[24px] hidden max-tablet:block'>
                        <span className='base-p-text' style={{ fontWeight: "400" }}>{mopitData.description}</span>
                    </div>
                    <div className='w-[133px] tablet:ml-auto'>
                        <Button 
                            name='Ver más'
                            onClick={() => {window.location.href = 'https://dev.d13yi98wzz4y2y.amplifyapp.com/'}}
                            svg={false}
                            type={'mopit'}
                        />
                    </div>
                </div>
                {/* Mopit image with circle background */}
                <div className='flex justify-center max-tablet:mt-[30px] tablet:mt-[45px] max-tablet:w-[295px] max-tablet:h-[295px] tablet:w-[507px] tablet:h-[441px] flex-shrink-0 relative'>
                    <svg className='max-tablet:w-[295px] max-tablet:h-[295px] tablet:w-[441px] tablet:h-[441px] absolute right-0'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437 437">
                        <defs>
                            <style>
                                {`
                                  .cls-100 {
                                    fill: #1a1a1a;
                                  }
                                  .cls-100, .cls-200 {
                                    stroke-width: 0px;
                                  }
                                  .cls-200 {
                                    fill: #f2bf47;
                                  }
                                `}
                            </style>
                        </defs>
                        <g id="Capa_1-2" data-name="Capa 1">
                            <g>
                                <ellipse className="cls-100" cx="220.38" cy="220.85" rx="216.62" ry="216.15"/>
                                <g>
                                    <path className="cls-200" d="M216.62,431.79c-57.73,0-112-22.43-152.82-63.16C22.98,327.9.5,273.75.5,216.15S22.98,104.39,63.8,63.66C104.62,22.93,158.9.5,216.62.5s112,22.43,152.82,63.16c40.82,40.73,63.3,94.88,63.3,152.48s-22.48,111.75-63.3,152.48c-40.82,40.73-95.09,63.16-152.82,63.16Z"/>
                                    <path className="cls-100" d="M216.62,1c29.11,0,57.35,5.69,83.93,16.91c25.68,10.84,48.74,26.35,68.54,46.11c19.8,19.76,35.35,42.77,46.21,68.39c11.24,26.52,16.94,54.7,16.94,83.74s-5.7,57.22-16.94,83.74c-10.86,25.62-26.41,48.63-46.21,68.39c-19.8,19.76-42.86,35.27-68.54,46.11c-26.58,11.22-54.82,16.91-83.93,16.91s-57.35-5.69-83.93-16.91c-25.68-10.84-48.74-26.35-68.54-46.11c-19.8-19.76-35.35-42.77-46.21-68.39C6.7,273.37,1,245.19,1,216.15s5.7-57.22,16.94-83.74c10.86-25.62,26.41-48.63,46.21-68.39c19.8-19.76,42.86-35.27,68.54-46.11c26.58-11.22,54.82-16.91,83.93-16.91M216.62,0C96.99,0,0,96.77,0,216.15s96.99,216.15,216.62,216.15,216.62-96.77,216.62-216.15S336.26,0,216.62,0h0Z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <img 
                        className='max-tablet:h-[193px] max-tablet:w-[290px] max-tablet:mt-[64px] tablet:h-[337px] tablet:w-[507px] tablet:mt-[79px] relative'
                        src={getFullUrl(mopitData.image.data.attributes.url)}
                        alt={mopitData.image.data.attributes.alternativeText}
                    />  
                </div>
            </div>
        </div>
    );
};