import React from 'react';
import { Link } from 'react-router-dom';


export const HoverableSvgIcon = ({ isHovered, setIsHovered, icon, hoveredIcon, to, ariaLabel, hideOnPhone=true }) => {
    return (
    <Link 
      to={to}
      className={`${hideOnPhone ? 'hidden tablet:block' : ''}`}
      target="_blank"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label={ariaLabel}
    >
      {isHovered ? hoveredIcon : icon}
    </Link>
  );
};