import React, { useState, useEffect } from 'react';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { AliciaShowCase } from '@/components/encodelyt/success_showcases/AliciaShowcase';
import { MopitShowcase } from '@/components/encodelyt/success_showcases/MopitShowcase';
import withEncodelytSettings from '@/hocs/withEncodelytSettings';
import { fetchEncodelytSuccessShowcase, getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';


const SuccessShowcasePage = () => {
    const [introduction, setIntroduction] = useState(null);
    const [introductionImage, setIntroductionImage] = useState(null);
    const [aliciaData, setAliciaData] = useState(null);
    const [mopitData, setMopitData] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
          const data = await fetchEncodelytSuccessShowcase();
          setIntroduction(data.introduction);
          setIntroductionImage(data.introduction_image);
          setAliciaData(data.alicia);
          setMopitData(data.mopit);
        }
        asyncFoldTry(fetchData);
    }, []);

    return (  
    <>
        {/* Introduction */}
        <div className='flex gap-[20px] my-[32px] max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
            <div className='max-w-[572px] w-full'>
                <div>
                    <div className='inline-block mb-[29px] rounded-[15px] px-[10px]' style={{ background: "#F5F3FF" }}>
                        <span className='base-p-title'>Casos de éxito</span>
                    </div>
                </div>
                <span className='base-p-text' style={{ fontWeight: "400" }}>{introduction}</span>
            </div>
            <div className='my-auto mx-auto hidden tablet:block'>
                {introductionImage ? (
                    <img 
                        className='h-[387px] w-full object-cover rounded-[15px]'
                        src={getFullUrl(introductionImage.data.attributes.url)}
                        alt={introductionImage.data.attributes.alternativeText}
                    />
                ) : <LoadingWheel type='encodelyt' />}  
            </div>
        </div>
        {/* Alicia */}
        <div style={{ background: "#F5F3FF" }}>
            <div className='max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
                <AliciaShowCase aliciaData={aliciaData} />
            </div>
        </div> 
        {/* Mopit */}
        <div className='max-tablet:mt-[51px] tablet:mt-[18px] max-tablet:mb-[148px] tablet:mb-[124px] max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
            <MopitShowcase mopitData={mopitData}/>
        </div>
    </>
    );
};

export default withEncodelytSettings(SuccessShowcasePage);