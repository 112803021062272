import React, { useRef, useEffect, useState } from 'react';
import { Industry } from '@/components/encodelyt/home/Industry';


export const IndustryCarousel = ({ industries }) => {
    const containerRef = useRef(null);
    const viewportRef = useRef(null);
    const spaceRef = useRef(null);
    const [repeatedIndustries, setRepeatedIndustries] = useState([]);
    const [viewportWindowWidth, setViewportWindowWidth] = useState(0); // se refiere al ancho que ocupan las card de industrias sin repetición
    const [viewportWidth, serViewportWidth] = useState(0); // corresponde a la parte visible del viewport, inicialmente 928
    const [visibleIndustries, setVisibleIndustries] = useState(4);
    const [containerWidth, setContainerWidth] = useState(0);
    const minContainerWidth = 928; // pixels
    const xMargin = 10; // pixels
    const industryWidth = 217 // pixels


    useEffect(() => {
        if (!viewportRef.current) return;
        const onResizeHandler = () => {
            let actualWidth = industryWidth;
            let cnt = 1;
            while (actualWidth + 2*xMargin+industryWidth <= spaceRef.current.offsetWidth) {
                actualWidth += industryWidth+2*xMargin;
                cnt++;
            }
            viewportRef.current.style.width = `${actualWidth}px`;
            serViewportWidth(actualWidth);
            if (visibleIndustries !== cnt) {
                setVisibleIndustries(cnt);
            }
        }
        onResizeHandler();
        window.addEventListener('resize', onResizeHandler);

        return () => {
            window.removeEventListener('resize', onResizeHandler);
        }
    }, [viewportRef]);

    // Set repeated idndustries
    useEffect(() => {
        if (industries.length) {
            const actualContainerWidth = industries.length*industryWidth + (industries.length-1)*2*xMargin;
            const repeatedIndustriesArray = [...industries];
            setViewportWindowWidth(actualContainerWidth);
            // Si el tamaño es menor al mínimo requerido, agrega íconos repetidos hasta 
            // conseguir un exceso de minIconWindowWidth. 
            if (actualContainerWidth < minContainerWidth) {
                let extraContainerWidth = 0;
                let i = 0;
                while (extraContainerWidth < minContainerWidth) {
                    const industry = industries[i % industries.length];
                    repeatedIndustriesArray.push(industry);
                    extraContainerWidth += 2*xMargin + industryWidth;
                    i++;
                }
                setContainerWidth(actualContainerWidth + extraWindowWidth);
            // Si el tamaño es mayor al mínimo requerido, simplemente lo duplica
            } else {
                for (const industry of industries) {
                    repeatedIndustriesArray.push(industry);
                }
                setContainerWidth(2*actualContainerWidth);
            }
            setRepeatedIndustries(repeatedIndustriesArray);
        }
    }, [industries])

    // Animate Carousel
    useEffect(() => {
        if (containerRef.current && viewportWindowWidth) {
            let lastFrameTime = performance.now();
            let position = 0;
            containerRef.current.style.width = `${containerWidth}px`;
            const pxPerFrame60Hz = 20;
            const pxPerSecond = pxPerFrame60Hz * 60;
            let timeoutId;

            const breakpoints = [];
            for (let i = 0; i <= viewportWindowWidth-20; i += viewportWidth+20) {
                breakpoints.push({reached: false, value: -i});
            }
            
            const animate = () => {
                const currentTime = performance.now();
                let deltaTime = (currentTime - lastFrameTime)/1000;
                deltaTime = deltaTime > 1.25*visibleIndustries ? deltaTime - 1.25*visibleIndustries : deltaTime;
                lastFrameTime = currentTime;

                position = Math.max(-viewportWindowWidth-20, position-pxPerSecond*deltaTime);
                if (position === -viewportWindowWidth-20) {
                    for (let i = 0; i < breakpoints.length; i++) {
                        breakpoints[i].reached=false;
                    }
                    position = 0;
                }
                if (containerRef.current) {
                    const actualBreakpoint = breakpoints.find(e => e.reached == false);
                    if (actualBreakpoint && actualBreakpoint.value >= position) {
                        position = actualBreakpoint.value;
                        containerRef.current.style.transform = `translateX(${position}px)`;

                        clearTimeout(timeoutId);
                        timeoutId = setTimeout(() => {                            
                            // After 5 seconds, resume the animation
                            const index = breakpoints.findIndex(e => e.reached == false);
                            breakpoints[index].reached = true;
                            requestAnimationFrame(animate);
                        }, 1250*visibleIndustries);
                    } else {
                        containerRef.current.style.transform = `translateX(${position}px)`;
                        requestAnimationFrame(animate);
                    }
                }
            };
            const animationId = requestAnimationFrame(animate);
        
            return () => {
                cancelAnimationFrame(animationId);
                clearTimeout(timeoutId);
            };
        }
    }, [repeatedIndustries, viewportWidth, visibleIndustries]);
  
    return (
        <div ref={spaceRef} className='w-full overflow-hidden flex justify-center'>
            <div ref={viewportRef} className='overflow-hidden relative max-w-[928px] w-full h-[164px] mb-[13px]'>
                <div ref={containerRef} className='flex absolute h-[164px] left-[-10px]'>
                    {repeatedIndustries.map((industry, index) => (
                        <div key={index} className='mx-[10px]'>
                            <Industry 
                                name={industry.name} 
                                icon={industry.icon.data}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};