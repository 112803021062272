import React from 'react';
import { Feature } from '@/components/alicia/home/Feature';
import '@/styles/alicia/FeatureList.css';


export const FeatureList = ({ features }) => {

  const content = (
    <div className="flex flex-wrap justify-center max-tablet:pt-[70px] max-tablet:pb-[40px] tablet:py-[10px] desktop:py-[40px]">
      {features.map((feature, index) => (
        <div key={index} className="max-w-[290px] w-full max-tablet:my-[10px] tablet:my-[20px] max-tablet:mx-[25px] max-desktop:mx-[28px] desktop:mx-[64px]">
          <Feature
            name={feature.name}
            icon={feature.icon.data}
          />
        </div>
      ))}
    </div>
  )

  return (
    <>
    {/* Feature List phone */}
    <div className='fl-phone-background hidden max-phone:block'>
      <div className='max-w-[1440px] w-full h-full mx-auto px-[2px]'>
        {content}
      </div>
    </div>
    {/* Feature List desktop */}
    <div className='fl-background hidden phone:block'>
      <div className='max-w-[1440px] w-full h-full mx-auto max-desktop:px-[2px] desktop:px-[83px] '>
        {content}
      </div>
    </div>
    </>
  )
};