import React from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/Service.css';


export const ServiceHeader = ({ name, icon, isSelected }) => {
  return (
    <div className={`${isSelected ? 'selected' : 'non-selected'}-service-header-container flex items-center p-[16px] tablet:h-[81px]`} style={{ background: '#FFFFFF', cursor: 'pointer' }}>
        <img 
          className='max-tablet:w-[34px] max-tablet:h-[34px] tablet:w-[40px] tablet:h-[40px]' 
          src={getFullUrl(icon.attributes.url)}
          alt={icon.attributes.alternativeText}
        />
        <div className='ml-[24px]'>
            <span className='base-i-text' style={{ fontSize: '20px'}}>{name}</span>
        </div>
    </div>
  );
};


export const ServiceContent = ({ name, description, image }) => {
  return (
    <div className='p-[24px] max-w-[572px] w-full' style={{ background: '#FFFFFF'}}>
      <div className='flex flex-col tablet:max-h-[674px] overflow-hidden'>
          <div className='service-filter relative w-full'>  
            <img 
              className="service-image object-cover w-full max-phone:h-[180px] max-tablet:h-[250px] tablet:h-[309px]" 
              src={getFullUrl(image.attributes.url)}
              alt={image.attributes.alternativeText}
            />
          </div>
          <div className='mx-auto p-[10px] mt-[24px] mb-[12px] max-w-[388px] w-full text-center rounded-[15px]' style={{ background: "#F5F3FF" }}>
            <span className='base-i-text' style={{ fontSize: '20px', fontWeight: '500' }}>{name}</span>
          </div>
          <BlocksRenderer
            content={description} 
            blocks={{
              paragraph: ({ children }) => <span className="base-p-text my-[12px]">{children}</span>
            }}
          />
      </div>
    </div>
  );
};