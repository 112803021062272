import React, { useEffect, useState, useRef } from 'react';
import { Title } from '@/components/base/Title';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { PostGrid } from '@/components/encodelyt/blog/PostGrid';
import withEncodelytSettings from '@/hocs/withEncodelytSettings';
import { fetchEncodelytLastBlogPosts } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';


const BlogPage = () => {
    const [loading, setLoading] = useState(true);
    const [lastPosts, setLastPosts] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const bottomBoundaryRef = useRef(null);

    useEffect(()=> {
        let ignore = false;
        const fetchData = async () => {
            if (totalPages && page > totalPages) {
                return;
            }
            setLoading(true);
            const { data, meta } = await fetchEncodelytLastBlogPosts(6, null, page);
            setTotalPages(meta.pagination.pageCount);
            setLoading(false);
            if (!ignore) {
                if (data.length) {
                    setLastPosts(prevPosts => [...prevPosts, ...data]);
                    setLastPage(prev => prev + 1);
                }
            }
        } 
        asyncFoldTry(fetchData);   
        return () => { ignore = true }    
    }, [page]);


    useEffect(() => {
        const handleScroll = () => {
            if (
              bottomBoundaryRef.current &&
              window.innerHeight + document.documentElement.scrollTop >= bottomBoundaryRef.current.offsetTop &&
              page === lastPage
            ) {
              setPage(prev => prev + 1);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [page, lastPage]);

    return (
    <div className='max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
        <div className='max-tablet:mb-[33px] tablet:mb-[100px]'>
            <div className='mt-[35px] mb-[60px]'>
                <Title type='encodelyt' name='Blog' center={true} />
            </div>
            <div className='px-[20px] desktop:px-[137px] text-center mt-[35px] mb-[90px] hidden tablet:block'>
                <span className='base-p-title' style={{ fontStyle: 'italic' }}>Revisa las últimas entradas de nuestro blog</span> 
            </div>
            <PostGrid posts={lastPosts} smallVersionType='4' />
            {loading ? (
                <div className='max-tablet:mt-[33px] tablet:mt-[100px]'>
                    <LoadingWheel type='encodelyt' />
                </div>
            ) : null}
            <div ref={bottomBoundaryRef}></div>
        </div>
    </div>
    );
};

export default withEncodelytSettings(BlogPage);