import { strapiImages, getStrapiResponseData } from './base';


export const getFullUrl = (url) => {
    return `${strapiImages}${url}`
}


// Encodelyt home
export const fetchEncodelytHome = async () => {
    let query = 'fields[0]=introduction&populate[introduction_images][populate][large_image][fields][0]=url&populate[introduction_images][populate][large_image][fields][1]=alternativeText';
    query += '&populate[introduction_images][populate][medium_large_image][fields][0]=url&populate[introduction_images][populate][medium_large_image][fields][1]=alternativeText';
    query += '&populate[introduction_images][populate][medium_image][fields][0]=url&populate[introduction_images][populate][medium_image][fields][1]=alternativeText';
    query += '&populate[introduction_images][populate][small_image][fields][0]=url&populate[introduction_images][populate][small_image][fields][1]=alternativeText';

    query += '&populate[services][fields][0]=name&populate[services][fields][1]=description&populate[services][populate][icon][fields][0]=url&populate[services][populate][icon][fields][1]=alternativeText';
    query += '&populate[services][populate][image][fields][0]=url&populate[services][populate][image][fields][1]=alternativeText';
    query += '&populate[services][populate][tridimensional_image][fields][0]=url&populate[services][populate][tridimensional_image][fields][1]=alternativeText'

    query += '&populate[industries][fields][0]=name&populate[industries][populate][icon][fields][0]=url&populate[industries][populate][icon][fields][1]=alternativeText';
    
    query += '&populate[stack][fields][0]=url&populate[stack][fields][1]=alternativeText&populate[stack][fields][2]=width';

    query += '&populate[team][populate][image][fields][0]=url&populate[team][populate][image][fields][1]=alternativeText';
    query += '&populate[team][populate][mission_icon][fields][0]=url&populate[team][populate][mission_icon][fields][1]=alternativeText';
    query += '&populate[team][populate][vision_icon][fields][0]=url&populate[team][populate][vision_icon][fields][1]=alternativeText';

    query += '&fields[1]=press_quote&fields[2]=press_quote_source&fields[3]=press_quote_url';
    query += '&populate[press_appearances][fields][0]=url&populate[press_appearances][populate][icon][fields][0]=url&populate[press_appearances][populate][icon][fields][1]=alternativeText';

    const response = await getStrapiResponseData(`/encodelyt-home?${query}`);
    return response.data.attributes;
}

// Encodelyt logo
export const fetchEncodelytLogo = async () => {
    const query = 'populate[v1][fields][0]=url&populate[v1][fields][1]=alternativeText';
    const response = await getStrapiResponseData(`/encodelyt-logo?${query}`);
    return response.data.attributes;
}

// Encodelyt partner logos
export const fetchEncodelytPartnerLogos = async () => {
    let query = 'populate[anid][fields][0]=url&populate[anid][fields][1]=alternativeText&populate[openBeauchef][fields][0]=url&populate[openBeauchef][fields][1]=alternativeText';
    query += '&populate[corfo][fields][0]=url&populate[corfo][fields][1]=alternativeText&populate[ohcowork][fields][0]=url&populate[ohcowork][fields][1]=alternativeText';
    const response = await getStrapiResponseData(`/encodelyt-partner-gallery?${query}`);
    return response.data.attributes;
}

// Last n encodelyt blog posts
export const fetchEncodelytLastBlogPosts = async (n, excludedId=null, page=1) => {
    const query = `populate[image][fields][0]=url&populate[image][fields][1]=alternativeText&fields[0]=title&fields[1]=content&fields[2]=publishedAt&sort=publishedAt:desc&pagination[pageSize]=${n}&pagination[page]=${page}`;
    const response = await getStrapiResponseData(`/encodelyt-blogs?${query}${excludedId ? `&filters[id][$ne]=${excludedId}` : ''}`);
    return response;
}

// n random encodelyt blog posts
export const fetchEncodelytRandomBlogPosts = async (n, excludedId=null) => {
    const idData = await getStrapiResponseData(`/encodelyt-blogs?fields[0]=title&sort=publishedAt:desc&pagination[pageSize]=100${excludedId ? `&filters[id][$ne]=${excludedId}` : ''}`);
    const ids = idData.data.map(element => element.id);
    const selectNUnique = (numbers, n) => {
        if (n > numbers.length) {
            throw new Error("n cannot be greater than the length of the list");
        }
        const shuffledNumbers = numbers.sort(() => Math.random() - 0.5);
        return shuffledNumbers.slice(0, n);
    }
    const randomIds = selectNUnique(ids, n);
    const randomFilter = randomIds.map((id, index) => `filters[id][$in][${index}]=${id}`).join('&');
    const query = `populate[image][fields][0]=url&populate[image][fields][1]=alternativeText&fields[0]=title&fields[1]=content&pagination[pageSize]=${n}&${randomFilter}`;
    const response = await getStrapiResponseData(`/encodelyt-blogs?${query}${excludedId ? `&filters[id][$ne]=${excludedId}` : ''}`);
    return response.data;
}

// Encodelyt blog post by id
export const fetchEncodelytBlogPost = async (id) => {
    const query = 'fields[0]=title&populate[image][fields][0]=url&populate[image][fields][1]=alternativeText&fields[1]=content';
    const response = await getStrapiResponseData(`/encodelyt-blogs/${id}?${query}`);
    return response.data.attributes;
}

// First 5 encodelyt services
export const fetchEncodelytFirst5Services = async (excludedId=null) => {
    let query = `pagination[pageSize]=${5}&sort=id:asc`;

    query += '&fields[0]=name&fields[1]=description&populate[icon][fields][0]=url&populate[icon][fields][1]=alternativeText';
    query += '&populate[image][fields][0]=url&populate[image][fields][1]=alternativeText';
    query += '&populate[tridimensional_image][fields][0]=url&populate[tridimensional_image][fields][1]=alternativeText'

    const response = await getStrapiResponseData(`/encodelyt-services?${query}${excludedId ? `&filters[id][$ne]=${excludedId}` : ''}`);
    return response;
}

// Encodelyt service by id
export const fetchEncodelytService = async (id) => {
    const query = 'fields[0]=name&populate[image][fields][0]=url&populate[image][fields][1]=alternativeText&fields[1]=description&fields[2]=content';
    const response = await getStrapiResponseData(`/encodelyt-services/${id}?${query}`);
    return response.data.attributes;
}

// Encodelyt sucess showcase
export const fetchEncodelytSuccessShowcase = async () => {
    let query = 'fields[0]=introduction&populate[introduction_image][fields][0]=url&populate[introduction_image][fields][1]=alternativeText';

    query += '&populate[alicia][fields][0]=description';
    query += '&populate[alicia][populate][logo][fields][0]=url&populate[alicia][populate][logo][fields][1]=alternativeText';
    query += '&populate[alicia][populate][image][fields][0]=url&populate[alicia][populate][image][fields][1]=alternativeText';

    query += '&populate[mopit][fields][0]=description';
    query += '&populate[mopit][populate][logo][fields][0]=url&populate[mopit][populate][logo][fields][1]=alternativeText';
    query += '&populate[mopit][populate][image][fields][0]=url&populate[mopit][populate][image][fields][1]=alternativeText';
    const response = await getStrapiResponseData(`/encodelyt-success-showcase/?${query}`);
    return response.data.attributes;
}


// Alicia logo
export const fetchAliciaLogo = async () => {
    const query = 'populate[v1][fields][0]=url&populate[v1][fields][1]=alternativeText&populate[v2][fields][0]=url&populate[v2][fields][1]=alternativeText';
    const response = await getStrapiResponseData(`/alicia-logo?${query}`);
    return response.data.attributes;
}

// Alicia home
export const fetchAliciaHome = async () => {
    let query = 'fields[0]=introduction&populate[introduction_image][fields][0]=url&populate[introduction_image][fields][1]=alternativeText';
    query += '&populate[introduction_background][fields][0]=url&populate[introduction_background][fields][1]=alternativeText&fields[1]=youtube_video_id';

    query += '&populate[objectives][fields][0]=title&populate[objectives][fields][1]=description&populate[objectives][populate][icon][fields][0]=url&populate[objectives][populate][icon][fields][1]=alternativeText';
    query += '&populate[features][fields][0]=name&populate[features][populate][icon][fields][0]=url&populate[features][populate][icon][fields][1]=alternativeText';
    query += '&populate[steps][fields][0]=description&populate[steps][populate][image][fields][0]=url&populate[steps][populate][image][fields][1]=alternativeText';

    query += '&fields[2]=versatility_text&populate[versatility_image][fields][0]=url&populate[versatility_image][fields][1]=alternativeText';
    query += '&populate[versatility_background][fields][0]=url&populate[versatility_background][fields][1]=alternativeText';

    query += '&fields[3]=analytics_text&populate[analytics_image][fields][0]=url&populate[analytics_image][fields][1]=alternativeText';
    query += '&populate[analytics_background][fields][0]=url&populate[analytics_background][fields][1]=alternativeText';

    query += '&populate[clients][fields][0]=url&populate[clients][populate][icon][fields][0]=url&populate[clients][populate][icon][fields][1]=alternativeText&populate[clients][populate][icon][fields][2]=width&populate[clients][populate][icon][fields][3]=height';

    query += '&populate[testimony_videos][fields][0]=youtube_video_id';
    const response = await getStrapiResponseData(`/alicia-home?${query}`);
    return response.data.attributes;
}