import React, { useRef, useEffect, useCallback } from 'react';
import '@/styles/encodelyt/Title.css';


export const Title = ({name, type, underline=true, center=false, style={}}) => {
    const titleRef = useRef(null);

    let suffix;
    if (type === 'encodelyt') {
        import('@/styles/encodelyt/Title.css');
        suffix = '-encodelyt';
    } else if (type === 'alicia') {
        import('@/styles/alicia/Title.css');
        suffix = '-alicia';
    }

    const onResize = useCallback(() => {
        if (titleRef.current) {
            const element = titleRef.current;
            const sibling = element.nextElementSibling;
            sibling.style.width = `${element.offsetWidth}px`;
        } 
    }, []);

    useEffect(() => {
        if (!titleRef.current || !underline) return;
        window.addEventListener('load', onResize);
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('load', onResize);
        }
    }, [titleRef.current]);



    useEffect(() => {
        if (!titleRef.current || !underline) return;

        const element = titleRef.current;
        const sibling = element.nextElementSibling;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target === element) {
                    sibling.style.width = `${element.offsetWidth}px`;
                }
            }
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver.disconnect();
        };
    }, [titleRef.current, underline]);
    
    return (
        <div className={`relative pb-2 ${center ? 'text-center' : ''}`}>
            <span ref={titleRef} className={`title-text${suffix}`} style={style}>{name}</span>
            {underline && <div className={`title-underline${suffix} absolute mt-1 ${center ? 'left-1/2 transform -translate-x-1/2' : ''}`}/>}
        </div>
  );
};