import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const withAliciaBaseSettings = (Component) => {
  return (props) => (
    <>
      <HelmetProvider>
        <Helmet>
          <title>αlicia</title>
          <link rel="icon" type="image/svg+xml" href="/alicia.svg" />
        </Helmet>
        <Component {...props} />
      </HelmetProvider>
    </>
  );
};

export default withAliciaBaseSettings;