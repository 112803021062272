import React, { useState, useEffect } from 'react';
import withAliciaAuthenticatedSettings from '@/hocs/withAliciaAuthenticatedSettings';
import { asyncFoldTry } from '@/api/base';
import { getAliciaMovement, updateAliciaMovement } from '@/api/cognito-users';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Button } from '@/components/base/Button';


const MovementPage = ({user}) => {
    const [movement, setMovement] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAliciaMovement(user.company.alicias[0].id);
            setMovement(data);
        }
        asyncFoldTry(fetchData);
    }, []);

    const handleSave = async () => {
        await asyncFoldTry(async () => {
            setIsSaving(true);
            const data = await updateAliciaMovement(user.company.alicias[0].id, {movement: movement});
            setMovement(data);
            setIsSaving(false);
        }, () => setIsSaving(false))
    }

    const RadioButton = ({ id, option }) => (
        <div className='p-[8px]'>
            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill={`url(#paint0_linear_1612_382-${id})`}/>
                {option === movement ? (
                    <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" fill={`url(#paint1_linear_1612_382-${id})`}/>
                ) : null}
                <defs>
                <linearGradient id={`paint0_linear_1612_382-${id}`} x1="2" y1="0.863636" x2="2" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB1F6B"/>
                <stop offset="1" stopColor="#C21455"/>
                </linearGradient>
                <linearGradient id={`paint1_linear_1612_382-${id}`} x1="2" y1="0.863636" x2="2" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB1F6B"/>
                <stop offset="1" stopColor="#C21455"/>
                </linearGradient>
                </defs>
            </svg> 
        </div>
    )

    const Row = ({id, optionName, optionValue, svg}) => (
    <>
        <div 
            className='flex items-center max-tablet:pt-[10px] max-tablet:pb-[12px] tablet:py-[20px] tablet:px-[28px] cursor-pointer' 
            onClick={() => setMovement(optionValue)}
        >
            <div className='flex gap-[10px]'>
                {svg}
                <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>{optionName}</span>
            </div>
            <div className='flex grow justify-end'>
                <RadioButton id={id} option={optionValue}/>
            </div>
        </div>
    </>
    )

    return (
        <div className='grow'>
            <div className='rounded-[15px] py-[4px] text-center hidden tablet:block' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                    <span className='base-p-title' style={{ fontWeight: '600' }}>Configuraciones</span>
            </div>
            <div className='max-w-[797px] w-full'>
                <div className='flex items-center max-tablet:gap-[16px] tablet:gap-[8px] max-tablet:pt-[6px] max-tablet:pb-[10px] tablet:py-[20px]'>
                    <svg className='max-tablet:w-[24px] max-tablet:h-[24px] tablet:w-[32px] tablet:h-[32px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3335 29.3334V26.6667H26.6668V29.3334H5.3335ZM16.0002 25.3334L10.6668 20.0001L12.5335 18.1334L14.6668 20.2001V11.8001L12.5335 13.8667L10.6668 12.0001L16.0002 6.66675L21.3335 12.0001L19.4668 13.8667L17.3335 11.8001V20.2001L19.4668 18.1334L21.3335 20.0001L16.0002 25.3334ZM5.3335 5.33341V2.66675H26.6668V5.33341H5.3335Z" fill="#181818"/> 
                    </svg> 
                    <span className='base-p-small-title max-tablet:text-base' style={{ fontWeight: '600' }}>Movimiento</span>
                </div>
                {/* Desktop */}
                <div className='hidden tablet:block'>
                    {/* Movement */}
                    <div className='rounded-[15px] mb-[8px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                        <Row id='1-0' optionName='Horizontal' optionValue='HORIZONTAL' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4L4 4L4 20L2 20L2 4ZM5 12L9 8L10.4 9.4L8.85 11L15.15 11L13.6 9.4L15 8L19 12L15 16L13.6 14.6L15.15 13L8.85 13L10.4 14.6L9 16L5 12ZM20 4L22 4L22 20L20 20L20 4Z" fill="#DB1F6B"/> 
                            </svg> 
                        )}/>
                    </div>
                    <div className='rounded-[15px] mb-[8px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                        <Row id='2-0' optionName='Vertical' optionValue='VERTICAL' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 22V20H20V22H4ZM12 19L8 15L9.4 13.6L11 15.15V8.85L9.4 10.4L8 9L12 5L16 9L14.6 10.4L13 8.85V15.15L14.6 13.6L16 15L12 19ZM4 4V2H20V4H4Z" fill="#DB1F6B"/> 
                            </svg> 
                        )}/>
                    </div>
                    {/* Custom movement */}
                    <div className='flex gap-[8px] py-[20px]'>
                        <svg className='w-[32px] h-[32px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6667 28V20H17.3333V22.6667H28V25.3333H17.3333V28H14.6667ZM4 25.3333V22.6667H12V25.3333H4ZM9.33333 20V17.3333H4V14.6667H9.33333V12H12V20H9.33333ZM14.6667 17.3333V14.6667H28V17.3333H14.6667ZM20 12V4H22.6667V6.66667H28V9.33333H22.6667V12H20ZM4 9.33333V6.66667H17.3333V9.33333H4Z" fill="#181818"/> 
                        </svg> 
                        <span className='base-p-small-title' style={{ fontWeight: '600' }}>Movimientos personalizados</span>
                    </div>
                    <div className='rounded-[15px] mb-[8px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                        <Row id='3-0' optionName='Movimiento 1' optionValue='TYPE_1' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22L7.75 17.75L9.175 16.325L11 18.15V14H13V18.125L14.8 16.3L16.25 17.75L12 22ZM6.25 16.25L2 12L6.225 7.775L7.65 9.2L5.85 11H10V13H5.875L7.7 14.8L6.25 16.25ZM17.75 16.25L16.325 14.825L18.15 13H14V11H18.125L16.3 9.2L17.75 7.75L22 12L17.75 16.25ZM11 10V5.85L9.175 7.675L7.75 6.25L12 2L16.25 6.25L14.825 7.675L13 5.85V10H11Z" fill="#DB1F6B"/>        
                            </svg> 
                        )}/>
                    </div>
                    <div className='rounded-[15px] mb-[16px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                        <Row id='4-0' optionName='Movimiento 2' optionValue='TYPE_2' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22L7.75 17.75L9.175 16.325L11 18.15V14H13V18.125L14.8 16.3L16.25 17.75L12 22ZM6.25 16.25L2 12L6.225 7.775L7.65 9.2L5.85 11H10V13H5.875L7.7 14.8L6.25 16.25ZM17.75 16.25L16.325 14.825L18.15 13H14V11H18.125L16.3 9.2L17.75 7.75L22 12L17.75 16.25ZM11 10V5.85L9.175 7.675L7.75 6.25L12 2L16.25 6.25L14.825 7.675L13 5.85V10H11Z" fill="#DB1F6B"/>                
                            </svg> 
                        )}/>
                    </div>
                </div>
                {/* Phone */}
                <div className='rounded-[15px] mb-[48px] px-[32px] pt-[18px] pb-[54px] hidden max-tablet:block' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                    {/* Movement */}
                    <div className='mb-[2px]' style={{borderBottom: "2px solid #888"}}>
                        <Row id='1-1' optionName='Horizontal' optionValue='HORIZONTAL' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4L4 4L4 20L2 20L2 4ZM5 12L9 8L10.4 9.4L8.85 11L15.15 11L13.6 9.4L15 8L19 12L15 16L13.6 14.6L15.15 13L8.85 13L10.4 14.6L9 16L5 12ZM20 4L22 4L22 20L20 20L20 4Z" fill="#DB1F6B"/> 
                            </svg> 
                        )}/>
                    </div>
                    <div className='mb-[2px]' style={{borderBottom: "2px solid #888"}}>
                        <Row id='2-1' optionName='Vertical' optionValue='VERTICAL' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 22V20H20V22H4ZM12 19L8 15L9.4 13.6L11 15.15V8.85L9.4 10.4L8 9L12 5L16 9L14.6 10.4L13 8.85V15.15L14.6 13.6L16 15L12 19ZM4 4V2H20V4H4Z" fill="#DB1F6B"/> 
                            </svg> 
                        )}/>
                    </div>
                    {/* Custom movement */}
                    <div className='flex items-center gap-[8px] py-[22px]'>
                        <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6667 28V20H17.3333V22.6667H28V25.3333H17.3333V28H14.6667ZM4 25.3333V22.6667H12V25.3333H4ZM9.33333 20V17.3333H4V14.6667H9.33333V12H12V20H9.33333ZM14.6667 17.3333V14.6667H28V17.3333H14.6667ZM20 12V4H22.6667V6.66667H28V9.33333H22.6667V12H20ZM4 9.33333V6.66667H17.3333V9.33333H4Z" fill="#181818"/> 
                        </svg> 
                        <span className='base-p-small-title max-tablet:text-base' style={{ fontWeight: '600' }}>Movimientos personalizados</span>
                    </div>
                    <div className='mb-[2px]' style={{borderBottom: "2px solid #888"}}>
                        <Row id='3-1' optionName='Movimiento 1' optionValue='TYPE_1' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22L7.75 17.75L9.175 16.325L11 18.15V14H13V18.125L14.8 16.3L16.25 17.75L12 22ZM6.25 16.25L2 12L6.225 7.775L7.65 9.2L5.85 11H10V13H5.875L7.7 14.8L6.25 16.25ZM17.75 16.25L16.325 14.825L18.15 13H14V11H18.125L16.3 9.2L17.75 7.75L22 12L17.75 16.25ZM11 10V5.85L9.175 7.675L7.75 6.25L12 2L16.25 6.25L14.825 7.675L13 5.85V10H11Z" fill="#DB1F6B"/>        
                            </svg> 
                        )}/>
                    </div>
                    <div className='' style={{borderBottom: "2px solid #888"}}>
                        <Row id='4-1' optionName='Movimiento 2' optionValue='TYPE_2' svg={(
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22L7.75 17.75L9.175 16.325L11 18.15V14H13V18.125L14.8 16.3L16.25 17.75L12 22ZM6.25 16.25L2 12L6.225 7.775L7.65 9.2L5.85 11H10V13H5.875L7.7 14.8L6.25 16.25ZM17.75 16.25L16.325 14.825L18.15 13H14V11H18.125L16.3 9.2L17.75 7.75L22 12L17.75 16.25ZM11 10V5.85L9.175 7.675L7.75 6.25L12 2L16.25 6.25L14.825 7.675L13 5.85V10H11Z" fill="#DB1F6B"/>                
                            </svg> 
                        )}/>
                    </div>
                </div>
                {/* Save button */}
                <div className='flex w-full max-tablet:justify-center tablet:justify-end'>
                    <div className='max-w-[213px] w-full'>
                        {isSaving ? (
                            <LoadingWheel type='alicia'/>
                        ): (
                            <Button 
                                name='Guardar Cambios' 
                                onClick={handleSave}
                                svg={false}
                                type='alicia-pink'
                                cls='h-[50px] w-[213px]'
                                containerStyle={{
                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                                    background: "#DB1F6B",
                                    border: "none" 
                                }}
                                textStyle={{fontSize: "20px" }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withAliciaAuthenticatedSettings(MovementPage);