import axios from 'axios';
import ReactGA from 'react-ga4';


const environmentGet = (key, defaultValue) => (
    import.meta.env.DEV ? defaultValue : import.meta.env[`VITE_${key}`]
);

export const asyncFoldTry = async (fun, errorHandler=null, successHandler=null) => {
    try {
        await fun();
        if (successHandler) {
            successHandler();
        }
    } catch (error) {
        if (errorHandler) {
            try {
                errorHandler(error?.response?.data?.detail);
            } catch {
                errorHandler();
            }
        }
        console.error('Error:', error.message);
    }
};

export const fastapi = axios.create({
    baseURL: environmentGet('FASTAPI_URL', 'http://localhost:8000/api')
});

export const strapiImages = import.meta.env.DEV ? 'http://localhost:8001' : '';

export const strapi = axios.create({
    baseURL: environmentGet('STRAPI_URL', 'http://localhost:8001/api')
});

export const getStrapiResponseData = async (endpoint) => {
    const response = await strapi.get(endpoint);
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
};


export const initializeGA = () => {
    const trackingId = environmentGet('GA_TRACKING_ID', 'G-8PNXGEZ4R5');
    ReactGA.initialize([
        {
            trackingId: trackingId,
            gtagOptions: {
                send_page_view: false,
                cookie_domain: window.location.hostname,
                cookie_flags: 'SameSite=None;Secure'
            }
        },
      ]);
    ReactGA.initialize(trackingId);
} 

export const trackPageView = (location) => {
    let path = location.pathname + location.search;
    path = path.length !== 1 && path[path.length-1] === '/' ? path.slice(0, path.length-1) : path;
    ReactGA.send({ hitType: "pageview", page: path, title: path});
}