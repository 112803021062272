import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Button } from '@/components/base/Button';
import { Title } from '@/components/base/Title';
import { Shortcut } from '@/components/encodelyt/Shortcut';
import { AnimatedBackground } from '@/components/encodelyt/home/AnimatedBackground';
import { IntroductionImages } from '@/components/encodelyt/home/IntroductionImages';
import { ServiceList } from '@/components/encodelyt/home/ServiceList';
import { IndustryCarousel } from '@/components/encodelyt/home/IndustryCarousel';
import { StackIconCarousel } from '@/components/encodelyt/home/StackIconCarousel';
import { Team } from '@/components/encodelyt/home/Team';
import { Meeting } from '@/components/base/Meeting';
import { PostGrid } from '@/components/encodelyt/blog/PostGrid';
import withEncodelytSettings from '@/hocs/withEncodelytSettings';
import { fetchEncodelytHome, fetchEncodelytLastBlogPosts, getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import '@/styles/ColoredIcons.css';


const HomePage = ({ services }) => {
    const [introduction, setIntroduction] = useState(null);
    const [introductionImages, setIntroductionImages] = useState(null);
    const [homepageServices, setHomepageServices] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [stack, setStack] = useState([]);
    const [team, setTeam] = useState(null);
    const [pressQuote, setPressQuote] = useState(null);
    const [pressQuoteUrl, setPressQuoteUrl] = useState(null);
    const [pressQuoteSource, setPressQuoteSource] = useState(null);
    const [pressAppearances, setPressAppearances] = useState([]);
    const [lastPosts, setLastPosts] = useState([]);
    const meetingRef = useRef(null);

    useEffect(()=> {
        const fetchData = async () => {
          const data = await fetchEncodelytHome();
          setIntroduction(data.introduction);
          setIntroductionImages(data.introduction_images);
          setIndustries(data.industries ? data.industries.data.map(industry => industry.attributes) : []);
          setStack(data.stack? data.stack.data.map(icon => icon.attributes) : []);
          setTeam(data.team);
          setPressQuote(data.press_quote);
          setPressQuoteSource(data.press_quote_source);
          setPressQuoteUrl(data.press_quote_url);
          setPressAppearances(data.press_appearances ? data.press_appearances.data : []);          
        }
        asyncFoldTry(fetchData);
    }, []);

    useEffect(()=> {
        setHomepageServices(services.map(service => service.attributes));
    }, [services]);

    useEffect(()=> {
        const fetchData = async () => {
          setLastPosts([]);
          const { data } = await fetchEncodelytLastBlogPosts(3);
          setLastPosts(data);
        }
        asyncFoldTry(fetchData);
    }, []);

    const handleIntroductionButton = () => {
      if (meetingRef.current) {
        meetingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };
    
    return (
    <>
        {/* Introduction */}
        <div className='flex pb-24 mt-10 relative max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>
            <AnimatedBackground />     
            <div className='flex justify-between w-full'>
                <div className="max-w-[455px] z-0" style={{ background: "rgba(255, 255, 255, 0.7)" }}>
                    <div className='animate-fade-right animate-duration-[1500ms] animate-ease-linear'>
                        <Title type='encodelyt' name='GENERAMOS SOLUCIONES INNOVADORAS'/>
                    </div>
                        {introduction ? (
                            <div className='base-p-text mt-9 mb-8 desktop:max-h-[245px]' style={{ overflow: 'hidden' }}>
                                {introduction}
                            </div>
                        ): <LoadingWheel type='encodelyt' margin={true} />}
                    <div className="max-w-[455px]">
                        <Button name='¡IMPULSA TU NEGOCIO!' onClick={handleIntroductionButton} type='encodelyt-purple' />
                    </div>
                </div>
                <IntroductionImages images={introductionImages}/>
            </div>   
        </div>
        {/* Our services */}
        <div style={{ backgroundColor: '#F5F3FF' }}>
            <div className='pt-10 pb-9 max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>            
                <div className='hidden max-tablet:block mb-[58px]'> 
                    <Title type='encodelyt' name='Descubre nuestros servicios' underline={false}/>
                </div>
                <div className='hidden tablet:block mb-[58px]'>
                    <Title type='encodelyt' name='Descubre nuestros servicios' underline={false} center={true}/>
                </div>
                {homepageServices.length ? <ServiceList services={homepageServices}/> : <LoadingWheel type='encodelyt' margin={true} />}
            </div>
        </div>
        {/* Industries */}
        <div className='pt-[22px] pb-[15px]' style={{ background: "linear-gradient(270deg, #AA8DF8 0%, #814CF2 100%)" }}>
            <div className='flex max-tablet:px-[20px] max-desktop:pr-[20px] desktop:pr-[137px] max-w-[1440px] w-full h-full mx-auto'>
                <svg className='w-[123px] h-[184px] mr-[18px] shrink-0 hidden tablet:block' viewBox="0 0 118 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="12" r="12" fill="#C6B6FC"/>
                    <circle cx="10" cy="44" r="12" fill="#C6B6FC"/>
                    <circle cx="10" cy="76" r="12" fill="#C6B6FC"/>
                    <circle cx="10" cy="108" r="12" fill="#C6B6FC"/>
                    <circle cx="10" cy="140" r="12" fill="#C6B6FC"/>
                    <circle cx="10" cy="172" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="12" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="44" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="76" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="108" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="140" r="12" fill="#C6B6FC"/>
                    <circle cx="42" cy="172" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="12" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="44" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="76" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="108" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="140" r="12" fill="#C6B6FC"/>
                    <circle cx="74" cy="172" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="12" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="44" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="76" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="108" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="140" r="12" fill="#C6B6FC"/>
                    <circle cx="106" cy="172" r="12" fill="#C6B6FC"/>
                </svg>
                <div className='flex max-tablet:flex-col w-full tablet:mt-[10px]'>
                    <svg className='w-[30px] h-[30px] mr-[2px] shrink-0 hidden tablet:block' viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.2188 16.25H5V13.75H20.2188L13.2188 6.75L15 5L25 15L15 25L13.2188 23.25L20.2188 16.25Z" fill="white"/>
                    </svg> 
                    <div className='base-i-small-title tablet:max-w-[186px] w-full tablet:mr-[16px] max-tablet:mb-[32px]' style={{ color: '#FFF'}}>
                        <span>Sabemos que cada </span><span style={{ fontWeight: '700' }}>industria</span><span> es única</span>
                    </div>
                    {industries.length ? <IndustryCarousel industries={industries}/> : <LoadingWheel type='encodelyt'/>}
                </div>
            </div>
        </div>
        {/* Our stack */}
        <div className='pt-[100px] max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>
            <div className='max-w-[572px]'>
                <Title type='encodelyt' name='TRABAJAMOS CON UN AMPLIO STACK TECNOLÓGICO' underline={false} />
            </div>
            {stack.length ? <StackIconCarousel stack={stack}/> : <LoadingWheel type='encodelyt' margin={true} />}
        </div>
        {/* Our team */}
         <div className='max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>
            <Title type='encodelyt' name='Nosotros' underline={false} center={true}/>
            <div className='pt-[40px] pb-[89px]'>
                {team ? <Team team={team}/> : <LoadingWheel type='encodelyt' margin={true} />}
            </div>
        </div>
        {/* Press appearances */}
        {pressAppearances.length ? (
            <div className='pt-[42px] pb-[12px]' style={{ background: "linear-gradient(270deg, #AA8DF8 0%, #814CF2 100%)" }}>
                <div className='max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>
                    <div className='mb-[42px]'>
                        <Title type='encodelyt' name='Encodelyt en los medios' underline={false} center={true} style={{ color: "#FFF" }}/>
                    </div>
                    {pressQuote && pressQuoteSource ? (
                        <div className='flex flex-col mx-auto max-w-[690px] mb-[9px]'>
                            {pressQuoteUrl ? (
                                <>
                                    <Link to={pressQuoteUrl} target='_blank' className='mb-[14px]'>
                                        <div className='w-full py-[28px] px-[40px]' style={{ borderRadius: "15px 15px 15px 0px", background: "#FFF", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                                            <span className='base-i-title text-center'>{pressQuote}</span>
                                        </div>
                                    </Link>
                                    <div className='flex justify-end'>
                                        <Link to={pressQuoteUrl} target='_blank'>    
                                            <span className='base-p-text' style={{ color: "#FFF", fontStyle: "italic" }}>{pressQuoteSource}</span>
                                        </Link>
                                    </div>  
                                </>
                            ) : (
                                <>
                                    <div className='w-full py-[28px] px-[40px] mb-[14px]' style={{ borderRadius: "15px 15px 15px 0px", background: "#FFF", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                                        <span className='base-i-title text-center'>{pressQuote}</span>
                                    </div>
                                    <div className='flex justify-end'>
                                         <span className='base-p-text' style={{ color: "#FFF", fontStyle: "italic" }}>{pressQuoteSource}</span>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : null}
                    <div className='flex flex-wrap justify-center'>
                        {pressAppearances.map(pressAppearance => (
                            <div key={pressAppearance.id} className='mx-[24px] my-[30px]'>
                                <Link to={pressAppearance.attributes.url} target='_blank'>
                                    <img 
                                        className='colored-icon h-[80px] w-full' 
                                        src={getFullUrl(pressAppearance.attributes.icon.data.attributes.url)} 
                                        alt={pressAppearance.attributes.icon.data.attributes.alternativeText} 
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ): null}
        {/* Meeting*/}
        <div className='max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] mt-[100px] w-full h-full mx-auto' ref={meetingRef}>
            <Title type='encodelyt' name='Agenda una reunión hoy mismo' underline={false} center={true}/>
            <Meeting type='encodelyt'/>
        </div>
        {/* Blog */}
        <div className='max-tablet:mb-[33px] tablet:mb-[324px] max-desktop:px-[20px] desktop:px-[137px] max-w-[1440px] w-full h-full mx-auto'>
            <div className='px-[20px] desktop:px-[137px] py-[50px] hidden max-tablet:block'>
                <span className='base-p-title' style={{ fontStyle: 'italic' }}>Revisa las últimas entradas de nuestro blog</span> 
            </div>
            <div className='px-[20px] desktop:px-[137px] text-center py-[50px] hidden tablet:block'>
                <span className='base-p-title' style={{ fontStyle: 'italic' }}>Revisa las últimas entradas de nuestro blog</span> 
            </div>
            <PostGrid posts={lastPosts}/>
            <div className='max-w-[400px] ml-auto tablet:mx-auto max-tablet:mt-[22px] tablet:mt-[60px]'>
                <Shortcut name='Ir al blog' to='/blog' />
            </div>
        </div>
    </>
    );
};

export default withEncodelytSettings(HomePage);