import React, { useEffect, useState, useRef } from 'react';
import { SmallCard } from '@/components/encodelyt/SmallCard';


export const MovableSmallCardList = ({ title, entityList, entityType, entityTitleGetter, entityIconGetter=(entity)=>null }) => {
    const [isFixed, setIsFixed] = useState(false);
    const [containerInitialPosition, setContainerInitialPosition] = useState({ x: 0, topY: 0, bottomY: 0 });
    const [containerContentHeight, setContainerContentHeight] = useState(0);
    const containerRef = useRef(null);
    const contentRef = useRef(null);

    // Get containerRef div position dynamically
    useEffect(() => {
        if (!containerRef.current) return;
        const eventHandler = () => {
            const divRect = containerRef.current.getBoundingClientRect();
            setContainerInitialPosition({ x: window.scrollX + divRect.left, topY: window.scrollY + divRect.top, bottomY: window.scrollY + divRect.bottom });
            setIsFixed(window.scrollY + divRect.top <= window.scrollY + 24);
        }
        eventHandler();
        window.addEventListener('resize', eventHandler);
        window.addEventListener('scroll', eventHandler);

        return () => {
            window.removeEventListener('resize', eventHandler);
            window.removeEventListener('scroll', eventHandler);
        }
    }, [containerRef]);

    // Get contentRef height dynamically
    useEffect(() => {
        if (!(contentRef.current)) return;
        const resizeObserver = new ResizeObserver(() => {
            setContainerContentHeight(contentRef.current.offsetHeight);
        });
        resizeObserver.observe(contentRef.current);
        return () => {
            resizeObserver.disconnect(); 
        }
    }, []);

    const calculateContainerFixedDivTopPosition = () => {
        if (containerInitialPosition.bottomY > (window.scrollY + containerContentHeight + 24)) {
            return '24px';
        } else {
            return `${containerInitialPosition.bottomY - (window.scrollY + containerContentHeight )}px`;
        }
    }

    return (
        <div ref={containerRef} className='tablet:max-w-[335px] w-full mt-[10px]'>
            <div
                ref={contentRef} 
                className='flex flex-col hidden tablet:block tablet:max-w-[335px]'
                style={{
                    position: isFixed ? 'fixed' : 'static',
                    top: isFixed ? calculateContainerFixedDivTopPosition() : 'auto',
                    left: isFixed ? containerInitialPosition.x + 'px' : 'auto',
                }}
            >
                {entityList.length ? (
                    <div className='pb-[5px]'>
                        <span className='base-p-text' style={{ fontWeight: "600" }}>
                            {title}
                        </span>
                    </div>
                ) : null}
                {entityList.map((entity, index) => (
                    <div key={index} className='py-[5px]'>
                        <SmallCard entity={entityType} id={entity.id} title={entityTitleGetter(entity)} icon={entityIconGetter(entity)} />
                    </div>
                ))}
            </div>
            <div className='flex flex-col hidden max-tablet:block tablet:max-w-[335px]'>
                {entityList.length ? (
                    <div className='pb-[5px] text-center'>
                        <span className='base-p-text' style={{ fontWeight: "600" }}>
                            {title}
                        </span>
                    </div>
                ) : null}
                {entityList.slice(0, 3).map((entity, index) => (
                    <div key={index} className='py-[5px]'>
                        <SmallCard entity={entityType} id={entity.id} title={entityTitleGetter(entity)} icon={entityIconGetter(entity)} />
                    </div>
                ))}
            </div>
        </div>
    )
};