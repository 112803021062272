import React from 'react';
import { getFullUrl } from '@/api/strapi';


export const Objective = ({ title, description, icon }) => {

  return (
    <div className='flex'>
        <div className="relative w-[74px] h-[74px]">
            <svg
                viewBox="0 0 73 73"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 left-0 w-[74px] h-[74px]"
            >
                <circle cx="36.5" cy="36.5" r="36.5" fill="#DF387B" />
            </svg>
            <img
                className="absolute top-0 left-0 transform translate-x-[11px] translate-y-[11px] w-[52px] h-[52px]"
                src={getFullUrl(icon.attributes.url)}
                alt={icon.attributes.alternativeText}
            />
        </div>
        <div className='max-w-[270px] w-full pl-[20px]'>
            <div className='mb-[10px]'>
                <span className='base-p-small-title font-semibold' style={{borderBottom: "2px solid #DB1F6B"}}>{title}</span>
            </div>
            <span className='base-p-small-text'>{description}</span>
        </div>
    </div>
  );
};