import React from 'react';
import { Link } from 'react-router-dom';
import '@/styles/encodelyt/Shortcut.css';


export const Shortcut = ({ name, to }) => {
  return (
    <Link to={to}>
        <div className='shortcut-container'>
            <div className='shortcut-text w-3/4'>{name}</div>
            <svg className='shortcut-arrow' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.9596 15.666H0.667969V12.3327H20.9596L11.6263 2.99935L14.0013 0.666016L27.3346 13.9994L14.0013 27.3327L11.6263 24.9994L20.9596 15.666Z" fill="#702BD6"/>
            </svg>
        </div>
    </Link>
  );
};