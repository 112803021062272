import React, {useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PostCardContentContainer } from '@/components/encodelyt/blog/PostCardContentContainer';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/PostCard.css';


export const PostCard = ({ post, type }) => {

    if (type == '1') {
        return (
            <Link to={`/blog/${post.id}`}>
                <div className='blog-image-container relative w-full h-full'>
                    <div className='blog-filter w-full h-full'>
                        <img 
                            className="blog-image w-full h-full object-cover" 
                            src={getFullUrl(post.attributes.image.data.attributes.url)}
                            alt={post.attributes.image.data.attributes.alternativeText}
                        />
                    </div>
                    <div className='absolute-blog-text-container p-[10px] m-[10px] tablet:p-[20px] tablet:m-[20px]'>
                        <span className='blog-title'>{post.attributes.title}</span>
                    </div>
                </div>
            </Link>
        )
    } if (type == '2') {
        const text = post.attributes.content.filter(element => element.type === 'paragraph').map(element => element.children.filter(childElement => childElement.type === 'text').map(childElement => childElement.text)).flat();
        const textSpan = text.map((element, index) => <span key={index} className='break-words blog-content py-[12px]'>{element}</span>)
        const parentRef = useRef(null);
        const titleRef = useRef(null);

        return (
            <Link to={`/blog/${post.id}`}>
                <div className='blog-image-container w-full h-full'>
                    <div className='relative h-1/2'>
                        <div className='blog-filter w-full h-full'>
                            <img 
                                className="blog-image w-full h-full object-cover" 
                                src={getFullUrl(post.attributes.image.data.attributes.url)}
                                alt={post.attributes.image.data.attributes.alternativeText}
                            />
                        </div>
                    </div>
                    <div ref={parentRef} className='w-full h-1/2'>
                        <div ref={titleRef} className='max-w-[470px] py-[20px]'>
                            <span className='blog-title'>{post.attributes.title}</span>
                        </div>
                        <PostCardContentContainer content={textSpan} parentRef={parentRef} titleRef={titleRef} />
                    </div>
                </div>
            </Link>
        )
    } if (type == '3') {
        const text = post.attributes.content.filter(element => element.type === 'paragraph').map(element => element.children.filter(childElement => childElement.type === 'text').map(childElement => childElement.text)).flat();
        const textSpan = text.map((element, index) => <span key={index} className='break-words blog-content py-[12px]'>{element}</span>)
        const parentRef = useRef(null);
        const titleRef = useRef(null);
        
        return (
            <Link to={`/blog/${post.id}`}>
                <div className='flex blog-image-container w-full h-full gap-[20px]'>
                    <div className='relative w-1/2'>
                        <div className='blog-filter w-full h-full'>
                            <img 
                                className="blog-image w-full h-full object-cover" 
                                src={getFullUrl(post.attributes.image.data.attributes.url)}
                                alt={post.attributes.image.data.attributes.alternativeText}
                            />
                        </div>
                    </div>
                    <div ref={parentRef} className='w-1/2 h-full' style={{ overflow: 'hidden' }}>
                        <div ref={titleRef} className='max-w-[470px] mb-[12px]'>
                            <span className='blog-title'>{post.attributes.title}</span>
                        </div>
                        <PostCardContentContainer content={textSpan} parentRef={parentRef} titleRef={titleRef} />
                    </div>
                </div>
            </Link>
        )
    } if (type == '4') {

        return (
            <Link to={`/blog/${post.id}`}>
                <div className='h-full w-full py-[16px] px-[8px]' style={{ background: "#F5F3FF" }}>
                    <div className='blog-image-container w-full h-full'>
                        <div className='relative max-phone:h-[206px] max-tablet:h-[276px]'>
                            <img 
                                className="blog-image w-full h-full object-cover" 
                                src={getFullUrl(post.attributes.image.data.attributes.url)}
                                alt={post.attributes.image.data.attributes.alternativeText}
                                style={{ filter: "grayscale(0%)" }}
                            />
                        </div>
                        <div className='w-full mt-[16px]'>
                            <span className='blog-title'>{post.attributes.title}</span>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
};