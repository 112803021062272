import React, { useState, useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import '@/styles/encodelyt/PostCard.css';


const binarySearch = (container, availableHeight, content, saveInitialHtml) => {
    let min = 0;
    if (content.length === 1) {
        const initialContainerInnerHTML = saveInitialHtml ? container.innerHTML : null;
        const words = content[0].props.children.split(' ');
        let max = words.length;
        let mid;
    
        while (min < max) {
            mid = Math.floor((min + max) / 2);
            container.innerHTML = container.innerHTML + `<span class='break-words blog-content py-[12px]'>${words.slice(0, mid).join(' ')}<span class='blog-title'> (ver más...)</span></span>`;
            if (container.scrollHeight <= availableHeight) {
              min = mid + 1;
            } else {
              max = mid;
            }
            container.innerHTML = initialContainerInnerHTML;
        }
        if (max === 0) {
            container.innerHTML = container.innerHTML + `<span class='blog-title'> (ver más...)</span>`;
        } else if (max !== words.length) {
            container.innerHTML = container.innerHTML + `<span class='break-words blog-content py-[12px]'>${words.slice(0, mid-1).join(' ')}<span class='blog-title'> (ver más...)</span></span>`;
        }
    } else {
        let max = content.length-1;
        let mid;
        while (min < max) {
            mid = Math.floor((min + max) / 2);
            container.innerHTML = content.slice(0, mid).map(element => ReactDOMServer.renderToString(element)).join('');

            if (container.scrollHeight <= availableHeight) {
              min = mid + 1;
            } else {
              max = mid;
            }
        }
        binarySearch(container, availableHeight, [content[mid]], true);
    }        
};


export const PostCardContentContainer = ({ content, parentRef, titleRef }) => {
    const containerRef = useRef(null);
    const [availableHeight, setAvailableHeight] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);
    
    useEffect(() => {
        if (!(titleRef.current && parentRef.current)) return;
        const titleObserver = new ResizeObserver(() => {
            if (titleRef.current && parentRef.current)
                setAvailableHeight(parentRef.current.offsetHeight-titleRef.current.offsetHeight);
        });
        const contentObserver = new ResizeObserver(() => {
            setContentHeight(containerRef.current.scrollHeight);
        });
        titleObserver.observe(titleRef.current);
        contentObserver.observe(containerRef.current);
        return () => {
            titleObserver.disconnect(); 
            contentObserver.disconnect(); 
        }
    }, []);

      useEffect(() => {
        if (!(containerRef.current)) return;
        binarySearch(containerRef.current, availableHeight, content, false); 
    }, [contentHeight, availableHeight]);

    return (
      <div ref={containerRef} className='flex flex-col'></div>
    );
};