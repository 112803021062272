import React, { useRef, useEffect, useState } from 'react';
import { getFullUrl } from '@/api/strapi';
import '@/styles/ColoredIcons.css';


export const StackIconCarousel = ({ stack }) => {
    const containerRef = useRef(null);
    const [repeatedIcons, setRepeatedIcons] = useState([]);
    const [initialIconWindowWidth, setinitialIconWindowWidth] = useState(0);
    const [iconWindowWidth, setIconWindowWidth] = useState(0);
    const minIconWindowWidth = 1166; // pixels
    const xMargin = 64; // pixels
 
    // Set repeated icons
    useEffect(() => {
        if (stack.length) {
            let actualIconWindowWidth = 0;
            for (const icon of stack) {
                actualIconWindowWidth += icon.width + 2*xMargin
            }
            const repeatedIconsArray = [...stack];
            setinitialIconWindowWidth(actualIconWindowWidth);
            // Si el tamaño es menor al mínimo requerido, agrega íconos repetidos hasta 
            // conseguir un exceso de minIconWindowWidth. 
            if (actualIconWindowWidth < minIconWindowWidth) {
                let extraWindowWidth = 0;
                let i = 0;
                while (extraWindowWidth < minIconWindowWidth) {
                    const icon = stack[i % stack.length];
                    repeatedIconsArray.push(icon);
                    extraWindowWidth += icon.width + 2*xMargin;
                    i++;
                }
                setIconWindowWidth(actualIconWindowWidth + extraWindowWidth);
            // Si el tamaño es mayor al mínimo requerido, simplemente lo duplica
            } else {
                for (const icon of stack) {
                    repeatedIconsArray.push(icon);
                }
                setIconWindowWidth(2*actualIconWindowWidth);
            }
            setRepeatedIcons(repeatedIconsArray);
        }
    }, [stack])

    // Animate Carousel
    useEffect(() => {
        if (containerRef.current) {
            let lastFrameTime = performance.now();
            let position = 0;
            containerRef.current.style.width = `${iconWindowWidth}px`;
            const pxPerFrame60Hz = 1;
            const pxPerSecond = pxPerFrame60Hz * 60;

            const animate = () => {
                const currentTime = performance.now();
                const deltaTime = (currentTime - lastFrameTime)/1000;
                lastFrameTime = currentTime;

                position = Math.max(-initialIconWindowWidth, position-pxPerSecond*deltaTime);
                if (position === -initialIconWindowWidth) {
                    position = 0;
                }
                if (containerRef.current) {
                    containerRef.current.style.transform = `translateX(${position}px)`;
                    requestAnimationFrame(animate);
                }
            };

            const animationId = requestAnimationFrame(animate);
        
            return () => {
                cancelAnimationFrame(animationId);
            };
        }
    }, [repeatedIcons]);
  
    return (
        <div className='overflow-hidden my-[100px] relative h-[144px]'>
            <div ref={containerRef} className={`flex absolute h-[144px]`}>
                {repeatedIcons.map((icon, index) => (
                    <div key={index} className='my-8 mx-16'>
                        <img 
                            className='colored-icon' 
                            height='80' 
                            width={icon.width} 
                            src={getFullUrl(icon.url)} 
                            alt={icon.alternativeText}
                            viewBox={`0 0 ${icon.width} 80`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
  