import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/base/Button';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { HoverableSvgIcon } from '@/components/base/HoverableSvgIcon';
import { SmallCard2 } from '@/components/encodelyt/SmallCard';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/Navbar.css';


export const Navbar = ({ logo, services }) => {
  const [currentServices, setCurrentServices] = useState([]);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const [isAliciaHovered, setIsAliciaHovered] = useState(false);
  const [isLinkedInHovered, setIsLinkedInHovered] = useState(false);
  const [isWhatsAppHovered, setIsWhatsAppHovered] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isServicePanelVisible, setIsServicePanelVisible] = useState(false);
  const serviceButtonRef = useRef(null);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setCurrentServices(services);
  }, [services])


  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const toggleServicePanelVisibility = () => {
    setIsServicePanelVisible(!isServicePanelVisible);
  };

  const aliciaIcon = (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1331_762)">
        <path d="M20.325 17.9762C19.6322 17.8496 18.3815 17.1619 17.9762 13.9597C18.9306 12.0695 19.9808 9.53387 20.6039 6.58053C20.6824 6.21347 20.3904 5.86751 19.9982 5.86751H18.9044C18.5994 5.86751 18.3379 6.07846 18.2943 6.36536C18.1636 7.14588 17.9544 8.8757 17.5753 9.19213C17.3749 9.35667 17.1701 9.33135 16.974 9.20478C16.9565 9.16259 16.9435 9.11618 16.9217 9.07399C16.1634 7.39481 14.8474 6.25566 13.2176 5.86329C9.95372 5.07433 6.485 7.62264 5.47837 11.5421C4.46739 15.4616 6.29762 19.2883 9.55281 20.0773C9.95808 20.1743 10.3677 20.225 10.7773 20.225C12.0193 20.225 13.2656 19.782 14.3811 18.9255C14.5859 18.7652 14.782 18.5922 14.9738 18.415L14.9694 18.4234C14.9694 18.4234 14.9781 18.415 14.9912 18.3981C15.1742 18.2209 15.3529 18.0311 15.5185 17.837C15.9848 17.3982 16.621 16.9678 16.8432 17.5796C17.7191 19.976 19.3881 20.2882 20.2509 20.2798C20.556 20.2756 20.8 20.0309 20.8 19.7356V18.512C20.8 18.2462 20.5952 18.0268 20.325 17.9762ZM13.0128 17.7737C12.0759 18.4909 11.0388 18.7567 10.0845 18.5289C8.00583 18.0268 7.05586 14.8794 7.77052 12.1033C8.40674 9.63091 10.1193 7.54247 11.9888 7.54247C12.2197 7.54247 12.4507 7.56779 12.6816 7.62264C13.636 7.85047 14.4204 8.87148 14.8997 9.93046C15.4226 11.0823 15.7146 13.8795 15.7146 13.8795C15.7146 13.8795 14.0325 16.9932 13.0128 17.7737Z" fill="#181818"/>
      </g>
      <rect x="0.5" y="0.5" width="25" height="25" rx="4.5" stroke="#181818"/>
      <defs>
        <clipPath id="clip0_1331_762">
          <rect width="26" height="26" rx="5" fill="white"/>
        </clipPath>
      </defs>
    </svg> 
  )

  const hoveredAliciaIcon = (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1331_753)">
        <rect width="26" height="26" rx="5" fill="#DB1F6B"/>
        <path d="M20.325 17.9762C19.6322 17.8496 18.3815 17.1619 17.9762 13.9597C18.9306 12.0695 19.9808 9.53387 20.6039 6.58053C20.6824 6.21347 20.3904 5.86751 19.9982 5.86751H18.9044C18.5994 5.86751 18.3379 6.07846 18.2943 6.36536C18.1636 7.14588 17.9544 8.8757 17.5753 9.19213C17.3749 9.35667 17.1701 9.33135 16.974 9.20478C16.9565 9.16259 16.9435 9.11618 16.9217 9.07399C16.1634 7.39481 14.8474 6.25566 13.2176 5.86329C9.95372 5.07433 6.485 7.62264 5.47837 11.5421C4.46739 15.4616 6.29762 19.2883 9.55281 20.0773C9.95808 20.1743 10.3677 20.225 10.7773 20.225C12.0193 20.225 13.2656 19.782 14.3811 18.9255C14.5859 18.7652 14.782 18.5922 14.9738 18.415L14.9694 18.4234C14.9694 18.4234 14.9781 18.415 14.9912 18.3981C15.1742 18.2209 15.3529 18.0311 15.5185 17.837C15.9848 17.3982 16.621 16.9678 16.8432 17.5796C17.7191 19.976 19.3881 20.2882 20.2509 20.2798C20.556 20.2756 20.8 20.0309 20.8 19.7356V18.512C20.8 18.2462 20.5952 18.0268 20.325 17.9762ZM13.0128 17.7737C12.0759 18.4909 11.0388 18.7567 10.0845 18.5289C8.00583 18.0268 7.05586 14.8794 7.77052 12.1033C8.40674 9.63091 10.1193 7.54247 11.9888 7.54247C12.2197 7.54247 12.4507 7.56779 12.6816 7.62264C13.636 7.85047 14.4204 8.87148 14.8997 9.93046C15.4226 11.0823 15.7146 13.8795 15.7146 13.8795C15.7146 13.8795 14.0325 16.9932 13.0128 17.7737Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1331_753">
          <rect width="26" height="26" rx="5" fill="white"/>
        </clipPath>
      </defs>
    </svg> 
  )

  const linkedInIcon = (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.2 5H9C7.344 5 6 6.344 6 8V27.2C6 28.856 7.344 30.2 9 30.2H28.2C29.856 30.2 31.2 28.856 31.2 27.2V8C31.2 6.344 29.856 5 28.2 5ZM13.8 14.6V26H10.2V14.6H13.8ZM10.2 11.282C10.2 10.442 10.92 9.8 12 9.8C13.08 9.8 13.758 10.442 13.8 11.282C13.8 12.122 13.128 12.8 12 12.8C10.92 12.8 10.2 12.122 10.2 11.282ZM27 26H23.4C23.4 26 23.4 20.444 23.4 20C23.4 18.8 22.8 17.6 21.3 17.576H21.252C19.8 17.576 19.2 18.812 19.2 20C19.2 20.546 19.2 26 19.2 26H15.6V14.6H19.2V16.136C19.2 16.136 20.358 14.6 22.686 14.6C25.068 14.6 27 16.238 27 19.556V26Z" fill="#181818"/>
    </svg> 
  )
  const hoveredLinkedInIcon = (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_816_186)">
        <g filter="url(#filter0_d_816_186)">
          <path d="M28.2 5H9C7.344 5 6 6.344 6 8V27.2C6 28.856 7.344 30.2 9 30.2H28.2C29.856 30.2 31.2 28.856 31.2 27.2V8C31.2 6.344 29.856 5 28.2 5ZM13.8 14.6V26H10.2V14.6H13.8ZM10.2 11.282C10.2 10.442 10.92 9.8 12 9.8C13.08 9.8 13.758 10.442 13.8 11.282C13.8 12.122 13.128 12.8 12 12.8C10.92 12.8 10.2 12.122 10.2 11.282ZM27 26H23.4C23.4 26 23.4 20.444 23.4 20C23.4 18.8 22.8 17.6 21.3 17.576H21.252C19.8 17.576 19.2 18.812 19.2 20C19.2 20.546 19.2 26 19.2 26H15.6V14.6H19.2V16.136C19.2 16.136 20.358 14.6 22.686 14.6C25.068 14.6 27 16.238 27 19.556V26Z" fill="#181818"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_816_186" x="2" y="5" width="33.2002" height="33.2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_816_186"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_816_186" result="shape"/>
        </filter>
        <clipPath id="clip0_816_186">
          <rect width="37.9273" height="38" fill="white"/>
        </clipPath>
      </defs>
    </svg> 
  )
  const whatsAppIcon = (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9742 25.3427L12.3561 25.569C13.9602 26.521 15.7995 27.0246 17.6752 27.0255H17.6791C23.4393 27.0255 28.1272 22.3387 28.1296 16.5782C28.1306 13.7867 27.0446 11.1616 25.0715 9.18701C24.1037 8.21318 22.9525 7.44097 21.6843 6.91508C20.4161 6.3892 19.0562 6.12008 17.6833 6.1233C11.9186 6.1233 7.23062 10.8096 7.22857 16.5698C7.22573 18.5367 7.77962 20.4643 8.82622 22.1297L9.07486 22.5248L8.01893 26.3797L11.9742 25.3427ZM5 29.3662L6.78392 22.8529C5.68373 20.9468 5.10493 18.7843 5.10561 16.5689C5.10851 9.63833 10.7486 4 17.6793 4C21.0426 4.00171 24.1994 5.31055 26.5736 7.68643C28.9478 10.0623 30.2541 13.2203 30.2529 16.579C30.2498 23.5091 24.6089 29.1484 17.6791 29.1484H17.6737C15.5695 29.1476 13.5019 28.6197 11.6654 27.6183L5 29.3662Z" fill="#181818"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4077 11.1851C14.1722 10.662 13.9244 10.6514 13.7007 10.6424L13.0985 10.635C12.889 10.635 12.5486 10.7136 12.2608 11.0281C11.973 11.3425 11.1611 12.1024 11.1611 13.6481C11.1611 15.1938 12.287 16.6874 12.4438 16.8973C12.6007 17.1071 14.6172 20.3802 17.8106 21.6394C20.4644 22.686 21.0044 22.4778 21.5806 22.4255C22.1568 22.3732 23.4394 21.6656 23.701 20.932C23.9626 20.1983 23.9628 19.5698 23.8844 19.4384C23.8059 19.307 23.5964 19.2289 23.282 19.0717C22.9676 18.9144 21.4232 18.1545 21.1353 18.0496C20.8473 17.9446 20.638 17.8925 20.4283 18.207C20.2186 18.5214 19.6173 19.2287 19.4339 19.4384C19.2505 19.6481 19.0675 19.6744 18.7531 19.5173C18.4386 19.3603 17.427 19.0284 16.2266 17.9581C15.2927 17.1254 14.6623 16.097 14.4788 15.7827C14.2952 15.4685 14.4593 15.2982 14.6169 15.1417C14.7578 15.0009 14.931 14.7748 15.0883 14.5914C15.2457 14.4081 15.2975 14.277 15.4021 14.0677C15.5067 13.8583 15.4546 13.6745 15.376 13.5174C15.2973 13.3604 14.6869 11.8065 14.4077 11.1851Z" fill="#181818"/>
    </svg> 
  )
  const hoveredWhatsAppIcon = (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_808_164)">
        <g filter="url(#filter0_d_808_164)">
          <path d="M11.9742 25.3427L12.3561 25.569C13.9603 26.521 15.7995 27.0246 17.6752 27.0255H17.6791C23.4393 27.0255 28.1272 22.3387 28.1296 16.5782C28.1306 13.7867 27.0446 11.1616 25.0715 9.18701C24.1037 8.21318 22.9525 7.44097 21.6843 6.91508C20.4161 6.3892 19.0562 6.12008 17.6833 6.1233C11.9186 6.1233 7.23062 10.8096 7.22857 16.5698C7.22573 18.5367 7.77962 20.4643 8.82622 22.1297L9.07486 22.5248L8.01893 26.3797L11.9742 25.3427ZM5 29.3662L6.78392 22.8529C5.68373 20.9468 5.10493 18.7843 5.10561 16.5689C5.10851 9.63833 10.7486 4 17.6793 4C21.0426 4.00171 24.1994 5.31055 26.5736 7.68643C28.9478 10.0623 30.2541 13.2203 30.2529 16.579C30.2498 23.5091 24.6089 29.1484 17.6791 29.1484H17.6737C15.5695 29.1476 13.5019 28.6197 11.6654 27.6183L5 29.3662Z" fill="#181818"/>
        </g>
        <g filter="url(#filter1_d_808_164)">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.4077 11.1851C14.1722 10.662 13.9244 10.6514 13.7007 10.6424L13.0985 10.635C12.889 10.635 12.5486 10.7136 12.2608 11.0281C11.973 11.3425 11.1611 12.1024 11.1611 13.6481C11.1611 15.1938 12.287 16.6874 12.4438 16.8973C12.6007 17.1071 14.6172 20.3802 17.8106 21.6394C20.4644 22.686 21.0044 22.4778 21.5806 22.4255C22.1568 22.3732 23.4394 21.6656 23.701 20.932C23.9626 20.1983 23.9628 19.5698 23.8844 19.4384C23.8059 19.307 23.5964 19.2289 23.282 19.0717C22.9675 18.9144 21.4232 18.1545 21.1353 18.0496C20.8473 17.9446 20.638 17.8925 20.4283 18.207C20.2186 18.5214 19.6173 19.2287 19.4339 19.4384C19.2505 19.6481 19.0675 19.6744 18.7531 19.5173C18.4386 19.3603 17.427 19.0284 16.2266 17.9581C15.2927 17.1254 14.6623 16.097 14.4788 15.7827C14.2952 15.4685 14.4593 15.2982 14.6169 15.1417C14.7578 15.0009 14.931 14.7748 15.0883 14.5914C15.2457 14.4081 15.2975 14.277 15.4021 14.0677C15.5067 13.8583 15.4546 13.6745 15.376 13.5174C15.2973 13.3604 14.6869 11.8065 14.4077 11.1851Z" fill="#181818"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_808_164" x="1" y="4" width="33.2529" height="33.3662" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_808_164"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_808_164" result="shape"/>
        </filter>
        <filter id="filter1_d_808_164" x="7.16113" y="10.635" width="20.7686" height="19.8425" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_808_164"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_808_164" result="shape"/>
        </filter>
        <clipPath id="clip0_808_164">
          <rect width="35.9311" height="36" fill="white"/>
        </clipPath>
      </defs>
    </svg> 
  )

  return (
    <>
    <div className='max-tablet:fixed max-tablet:w-full max-tablet:z-50' style={{background: "#FFFFFF"}}>
      <div className='h-full max-w-[1440px] mx-auto'>
        <div className='flex justify-between items-center py-[40px] px-[20px] desktop:px-[137px]'>
          {/* Logo */}
          <div className='flex items-center'>
            <div className='mr-[32px] hidden max-tablet:block' onClick={togglePanelVisibility}>
            {!isPanelVisible ? (
              <svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 32V29H37V32H10ZM10 24.5V21.5H37V24.5H10ZM10 17V14H37V17H10Z" fill="black"/>
              </svg> 
            ) : (
              <svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="46" height="49" fill="#F5F3FF"/>
                <path d="M10 32V29H37V32H10ZM10 24.5V21.5H37V24.5H10ZM10 17V14H37V17H10Z" fill="black"/>
              </svg> 
            )}
            </div>
            <div className='max-tablet:w-[235px] tablet:w-[163px]'>
            <Link to='/' aria-label='Go to Encodelyt homepage'>
                {logo ? (
                  <img 
                    src={getFullUrl(logo.attributes.url)} 
                    alt={logo.attributes.alternativeText}
                  />
                ) : <LoadingWheel type='encodelyt' />
                }
            </Link>
            </div>
          </div>
          {/* Sections */}
          <div className='flex justify-between items-center max-w-[700px] w-full ml-[20px]'>
            <Link to='/' className='hidden tablet:block'>
              <span className={`navbar-text ${currentPath === '/' ? 'navbar-active' : ''}`}>Inicio</span>
            </Link>
            <Link to='/casos-de-exito' className='hidden tablet:block'>
              <span className={`navbar-text ${(currentPath === '/casos-de-exito' || currentPath === '/casos-de-exito/')  ? 'navbar-active' : ''}`}>Casos de éxito</span>
            </Link>
            <Link to='/blog' className='hidden tablet:block'>
              <span className={`navbar-text ${(currentPath.startsWith('/blog')) ? 'navbar-active' : ''}`}>Blog</span>
            </Link>
            <div
              ref={serviceButtonRef} 
              className='max-w-[120px] w-full hidden tablet:block relative'
              onMouseEnter={() => {setIsServicesHovered(true); toggleServicePanelVisibility()}} 
              onMouseLeave={() => {setIsServicesHovered(false); toggleServicePanelVisibility()}} 
            >
              <Button 
                name='Servicios'
                type={isServicesHovered || currentPath.startsWith('/servicios') ? 'encodelyt-purple' : 'encodelyt-white'}
                svg={false}
                containerStyle={isServicesHovered || currentPath.startsWith('/servicios') ? {borderRadius: "0px" } : null}
                textStyle={{fontFamily: "Poppins"}}
              />
              {/* Displayable service panel */}
              {isServicePanelVisible && (
                <div className='w-[234px] absolute z-50 hidden tablet:block' style={{ background: "#F5F3FF" }}>
                  {currentServices.map(service => (
                    <div key={service.id}>
                      <SmallCard2 entity='servicios' id={service.id} title={service.attributes.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <HoverableSvgIcon 
              isHovered={isAliciaHovered} 
              setIsHovered={setIsAliciaHovered} 
              icon={aliciaIcon} 
              hoveredIcon={hoveredAliciaIcon} 
              to='/alicia' 
              ariaLabel='Go to Alicia home'
            />
            <HoverableSvgIcon 
              isHovered={isLinkedInHovered} 
              setIsHovered={setIsLinkedInHovered} 
              icon={linkedInIcon} 
              hoveredIcon={hoveredLinkedInIcon} 
              to='https://www.linkedin.com/company/encodelyt' 
              ariaLabel='Go to Encodelyt LinkedIn profile'
            />
            <HoverableSvgIcon 
              isHovered={isWhatsAppHovered} 
              setIsHovered={setIsWhatsAppHovered} 
              icon={whatsAppIcon} 
              hoveredIcon={hoveredWhatsAppIcon} 
              to='https://api.whatsapp.com/send/?phone=56931762756&text&type=phone_number&app_absent=0'
              ariaLabel={'Go to Encodelyt WhatsApp chat'}
            />
          </div>
        </div>
      </div>
    </div>
    {/* Displayable panel */}
    {isPanelVisible && (
      <div className='w-[234px] fixed z-50 top-[88px] left-[20px] hidden max-tablet:block' style={{ background: "#F5F3FF" }}>
        <div className='flex px-[8px] py-[18px] gap-[8px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
          <Link to='/' onClick={togglePanelVisibility}>
            <span className='base-i-small-text'>Inicio</span>
          </Link>
        </div>
        <div className='flex px-[8px] py-[18px] gap-[8px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
          <Link to='/casos-de-exito' onClick={togglePanelVisibility}>
            <span className='base-i-small-text'>Casos de éxito</span>
          </Link>
        </div>
        <div className='flex px-[8px] py-[18px] gap-[8px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
          <Link to='/blog' onClick={togglePanelVisibility}>
            <span className='base-i-small-text'>Blog</span>
          </Link>
        </div>
        <div className='flex px-[8px] py-[18px] gap-[8px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
          <Link to={`/servicios/${currentServices[0]?.id}`} onClick={togglePanelVisibility}>
            <span className='base-i-small-text'>Servicios</span>
          </Link>
        </div>
        <div className='flex px-[8px] py-[18px] gap-[8px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6.4L7.4 5L17 14.6L17 6L19 6L19 18L7 18L7 16L15.6 16L6 6.4Z" fill="#181818"/>
          </svg> 
          <Link to='/alicia' onClick={togglePanelVisibility}>
            <span className='base-i-small-text'>Alicia</span>
          </Link>
        </div>
      </div>
    )}
    </>

  );
};