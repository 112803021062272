import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { fastapi } from './base';


const usersApi = axios.create({
    baseURL: `${fastapi.defaults.baseURL}/cognito_users`
});

const dashboardsApi = axios.create({
    baseURL: `${fastapi.defaults.baseURL}/dashboards`
});

const aliciasApi = axios.create({
    baseURL: `${fastapi.defaults.baseURL}/alicias`
});

const gendersApi = axios.create({
    baseURL: `${fastapi.defaults.baseURL}/genders`
});

const agesApi = axios.create({
    baseURL: `${fastapi.defaults.baseURL}/ages`
});


// Obtiene el id token y el access token de un usuario logeado.
export const getUserTokens = async () => {
    const session = await fetchAuthSession();
    // Sin el toString obtengo la cookie sin codificar.
    const accessToken = session.tokens.accessToken.toString();
    const idToken = session.tokens.idToken.toString();
    return [accessToken, idToken]
}

// Obtiene al usuario de Cognito actual guardado en la base de datos.
export const getCurrentCognitoUser = async () => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await usersApi.get(
        '/me', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
        })
    return response.data;
};

// Obtiene la url firmada de un dashboard a partir de su id.
export const getDashboardUrl = async (dashboardId) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await dashboardsApi.get(
        `/${dashboardId}/url`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
        })
    return response.data;
};

// Obtiene el primer Alicia al cual la compañía del usuario logeado tiene acceso.
export const getAliciaLastEvent = async (aliciaId) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.get(
        `/${aliciaId}/events/latest`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
        })
    return response.data;
};

// Obtiene el volumen actual de un Alicia dado su id.
export const getAliciaVolume = async (aliciaId) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.get(
        `/${aliciaId}/config/volume`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Actualiza el volumen de un Alicia dado su id.
export const updateAliciaVolume = async (aliciaId, payload) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.put(
        `/${aliciaId}/config/volume`, payload, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Obtiene el movimiento actual de un Alicia dado su id.
export const getAliciaMovement = async (aliciaId) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.get(
        `/${aliciaId}/config/movement`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Actualiza el movimiento de un Alicia dado su id.
export const updateAliciaMovement = async (aliciaId, payload) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.put(
        `/${aliciaId}/config/movement`, payload, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Obtiene la lista de géneros disponibles.
export const getGenders = async () => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await gendersApi.get(
        '/', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Obtiene la lista de edades disponibles.
export const getAges = async () => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await agesApi.get(
        '/', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Obtiene la lista de segmentos de sonido actuales de un Alicia dado su id.
export const getAliciaSoundSegments = async (aliciaId) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.get(
        `/${aliciaId}/config/sound_segments`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Actualiza lista de segmentos de sonido de un Alicia dado su id.
export const updateAliciaSoundSegments = async (aliciaId, payload) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.put(
        `/${aliciaId}/config/sound_segments`, payload, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
    return responseData;
}

// Sube un nuevo sonido a un segmento de sonido dado el id el alicia, el id del género y el id de la edad.
export const uploaAliciaSoundSegmentSound = async (aliciaId, genderId, ageId, formData) => {
    const [accessToken, idToken] = await getUserTokens();
    const response = await aliciasApi.put(`/${aliciaId}/config/sound_segments/${genderId}/${ageId}/sound`, formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        }
    })
    const responseData = response.data;
    if (responseData.error) {
        throw Error(responseData.error.name);
    }
}