import React, { useState, useEffect } from 'react';
import withAliciaAuthenticatedSettings from '@/hocs/withAliciaAuthenticatedSettings'
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { asyncFoldTry } from '@/api/base';
import { getAliciaLastEvent, getDashboardUrl } from '@/api/cognito-users';


const HomePage = ({user}) => {
    const [isAliciaLoading, setIsAliciaLoading] = useState(true);
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [aliciaLastEvent, setAliciaLastEvent] = useState(null);
    const [active, setActive] = useState(null);
    const [date, setDate] = useState(null);
    const [dashboards, setDashboards] = useState([]);
 
    useEffect(() => {
        setIsAliciaLoading(true);
        const fetchData = async () => {
            const data = await getAliciaLastEvent(user.company.alicias[0].id);
            const dateObject = new Date(data.data_timestamp);
            const now = new Date();
            const difference = now - dateObject;
            const tenMinutes = 10 * 60 * 1000;
            const activeStatus = difference <= tenMinutes ? 'Conectado' : 'Desconectado'; 
            const day = String(dateObject.getDate()).padStart(2, '0');
            const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
            const year = dateObject.getFullYear();
            setAliciaLastEvent(data);
            setActive(activeStatus);
            setDate(`${day}/${month}/${year}`);
        }
        asyncFoldTry(fetchData, () => setIsAliciaLoading(false), () => setIsAliciaLoading(false));
    }, [])

    useEffect(() => {
        setIsDashboardLoading(true);
        const fetchData = async () => {
            const dashboardDataPromises = user.company.dashboards.map(async (dashboard) => await getDashboardUrl(dashboard.id));
            const dashboardData = await Promise.all(dashboardDataPromises);
            setDashboards(dashboardData);
        }
        asyncFoldTry(fetchData, () => setIsDashboardLoading(false), () => setIsDashboardLoading(false));
    }, [])

    return (
        <div className='grow'>
            <>
            <div className='rounded-[15px] py-[4px] hidden tablet:block' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                <span className='base-p-title ml-[30%]' style={{ fontWeight: '600' }}>Vista general</span>
            </div>
            <div className='max-tablet:pb-[8px] tablet:py-[20px]'>
                <span className='base-p-small-title max-tablet:text-xl' style={{ fontWeight: '600' }}>Mis datos</span>
            </div>
            <div className='flex max-tablet:flex-col gap-[20px] max-tablet:mb-[47px]'>
                <div 
                    className='flex flex-col max-tablet:w-full tablet:w-[29%] rounded-[15px] max-tablet:py-[14px] max-tablet:pl-[24px] max-tablet:pr-[17px] tablet:p-[24px] tablet:pb-[48px]' 
                    style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                    {/* User Info Desktop */}
                    <div className='hidden tablet:block'>
                        <div className='flex flex-col overflow-hidden'>
                            <span className='base-p-text' style={{ fontWeight: '500', color: "#535353" }}>{user.company ? user.company.name : ''}</span>
                            <span className='base-p-text' style={{ fontWeight: '500', color: "#535353" }}>{user.given_name} {user.family_name}</span>
                            <span className='base-p-small-text mt-[24px]' style={{ fontWeight: '300' }}>{user.email}</span>
                        </div>
                    </div>
                    {/* User Info Phone */}
                    <div className='hidden max-tablet:block min-h-[264px]'>
                        <div className='flex flex-col gap-[8px] pb-[8px] mb-[18px]' style={{borderBottom: "2px solid #888"}}>
                            <span className='base-p-text' style={{ fontWeight: '500', color: "#535353" }}>{user.company ? user.company.name : ''}</span>
                            <span className='base-p-text' style={{ fontWeight: '500', color: "#535353" }}>{user.given_name} {user.family_name}</span>
                            <span className='base-p-small-text max-tablet:text-sm' style={{ fontWeight: '300' }}>{user.email}</span>
                        </div>
                        {/* Alicia Info Phone */}
                        {!isAliciaLoading ? (
                            <>
                            <div className='mb-[20px] pb-[10px]' style={{borderBottom: "2px solid #888"}}>
                                <div className='flex items-center gap-[10px] mb-[10px]'>
                                    <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 13H19V11H14V13ZM14 10H19V8H14V10ZM5 16H13V15.45C13 14.7 12.6333 14.1042 11.9 13.6625C11.1667 13.2208 10.2 13 9 13C7.8 13 6.83333 13.2208 6.1 13.6625C5.36667 14.1042 5 14.7 5 15.45V16ZM9 12C9.55 12 10.0208 11.8042 10.4125 11.4125C10.8042 11.0208 11 10.55 11 10C11 9.45 10.8042 8.97917 10.4125 8.5875C10.0208 8.19583 9.55 8 9 8C8.45 8 7.97917 8.19583 7.5875 8.5875C7.19583 8.97917 7 9.45 7 10C7 10.55 7.19583 11.0208 7.5875 11.4125C7.97917 11.8042 8.45 12 9 12ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V6H4V18Z" fill="#DB1F6B"/>
                                    </svg> 
                                    <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>{user.company.alicias[0].id}</span>
                                </div>
                                <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>/ {aliciaLastEvent?.customer_id}</span>
                            </div>
                            <div className='flex gap-[10px]'>
                                <svg className='w-[20px] h-[20px] shrink-0' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1099_5029)">
                                        <path d="M10 17.4999C9.41667 17.4999 8.92361 17.2985 8.52083 16.8958C8.11806 16.493 7.91667 15.9999 7.91667 15.4166C7.91667 14.8333 8.11806 14.3402 8.52083 13.9374C8.92361 13.5346 9.41667 13.3333 10 13.3333C10.5833 13.3333 11.0764 13.5346 11.4792 13.9374C11.8819 14.3402 12.0833 14.8333 12.0833 15.4166C12.0833 15.9999 11.8819 16.493 11.4792 16.8958C11.0764 17.2985 10.5833 17.4999 10 17.4999ZM5.29167 12.7916L3.54167 10.9999C4.36111 10.1805 5.32292 9.53117 6.42708 9.052C7.53125 8.57284 8.72222 8.33325 10 8.33325C11.2778 8.33325 12.4688 8.57631 13.5729 9.06242C14.6771 9.54853 15.6389 10.2083 16.4583 11.0416L14.7083 12.7916C14.0972 12.1805 13.3889 11.7013 12.5833 11.3541C11.7778 11.0069 10.9167 10.8333 10 10.8333C9.08333 10.8333 8.22222 11.0069 7.41667 11.3541C6.61111 11.7013 5.90278 12.1805 5.29167 12.7916ZM1.75 9.24992L0 7.49992C1.27778 6.19436 2.77083 5.17353 4.47917 4.43742C6.1875 3.70131 8.02778 3.33325 10 3.33325C11.9722 3.33325 13.8125 3.70131 15.5208 4.43742C17.2292 5.17353 18.7222 6.19436 20 7.49992L18.25 9.24992C17.1806 8.18047 15.941 7.34367 14.5312 6.7395C13.1215 6.13534 11.6111 5.83325 10 5.83325C8.38889 5.83325 6.87847 6.13534 5.46875 6.7395C4.05903 7.34367 2.81944 8.18047 1.75 9.24992Z" fill="#DB1F6B"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1099_5029">
                                            <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg> 
                                <div className='flex flex-col gap-[10px]'>
                                    <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>Conectada a la red:</span>
                                    <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>{aliciaLastEvent?.ssid_wifi}</span>
                                </div>
                            </div>
                            </>
                        ) : (
                            <div className='h-[151px]'>
                                <LoadingWheel type='alicia'/>
                            </div>
                        )}
                    </div>
                </div>
                {/* Alicia Status Phone */}
                <div className='rounded-[15px] py-[10px] px-[24px] hidden max-tablet:block' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                    {!isAliciaLoading ? (
                        <div>
                            <div className='flex items-center gap-[8px] mb-[10px]'>
                                <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.625 18.175L1 17L6 9L9 12.5L13 6L15.725 10.075C15.3417 10.0917 14.9792 10.1375 14.6375 10.2125C14.2958 10.2875 13.9583 10.3917 13.625 10.525L13.075 9.7L9.275 15.875L6.25 12.35L2.625 18.175ZM21.575 23L18.45 19.875C18.1167 20.1083 17.7458 20.2833 17.3375 20.4C16.9292 20.5167 16.5083 20.575 16.075 20.575C14.825 20.575 13.7625 20.1375 12.8875 19.2625C12.0125 18.3875 11.575 17.325 11.575 16.075C11.575 14.825 12.0125 13.7625 12.8875 12.8875C13.7625 12.0125 14.825 11.575 16.075 11.575C17.325 11.575 18.3875 12.0125 19.2625 12.8875C20.1375 13.7625 20.575 14.825 20.575 16.075C20.575 16.5083 20.5167 16.9292 20.4 17.3375C20.2833 17.7458 20.1083 18.125 19.875 18.475L23 21.575L21.575 23ZM16.075 18.575C16.775 18.575 17.3667 18.3333 17.85 17.85C18.3333 17.3667 18.575 16.775 18.575 16.075C18.575 15.375 18.3333 14.7833 17.85 14.3C17.3667 13.8167 16.775 13.575 16.075 13.575C15.375 13.575 14.7833 13.8167 14.3 14.3C13.8167 14.7833 13.575 15.375 13.575 16.075C13.575 16.775 13.8167 17.3667 14.3 17.85C14.7833 18.3333 15.375 18.575 16.075 18.575ZM18.3 10.575C17.9833 10.4417 17.6542 10.3333 17.3125 10.25C16.9708 10.1667 16.6167 10.1167 16.25 10.1L21.375 2L23 3.175L18.3 10.575Z" fill="#DB1F6B"/>
                                </svg> 
                                <span className='base-p-small-text' style={{ fontWeight: '300', fontStyle: 'italic', color: "#535353" }}>Estado del robot: </span>
                                <div className='flex items-center gap-[10px]'>
                                    <span className='base-p-small-text' style={{ fontWeight: '500', fontStyle: 'italic', color: `${active == 'Conectado' ? '#007126' : ' #888888' }` }}>{active}</span>
                                    <svg className='w-[14px] h-[14px] shrink-0 m-[2px]' viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle className='shrink-0' cx="7" cy="7" r="7" fill="url(#paint0_radial_1173_75)"/>
                                        <defs>
                                            <radialGradient id="paint0_radial_1173_75" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7 7) rotate(90) scale(7)">
                                            <stop stopColor={`${active == 'Conectado' ? '#00D748' : '#888888'}`}/>
                                            <stop offset="1" stopColor={`${active == 'Conectado' ? '#007126' : '#888888'}`}/>
                                            </radialGradient>
                                        </defs>
                                    </svg> 
                                </div>
                            </div>
                            <span className='base-p-small-text' style={{ fontWeight: '300', fontStyle: 'italic', color: "#888" }}>Última actualización: {date}</span>
                        </div>
                    ) : <LoadingWheel type='alicia'/>}
                    
                </div>
                {/* Alicia Info & Status Desktop */}
                <div className='grow rounded-[15px] px-[25px] py-[34px] hidden tablet:block' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                    {!isAliciaLoading ? (
                        <>
                        <div className='flex items-center gap-[10px] mb-[20px]'>
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 13H19V11H14V13ZM14 10H19V8H14V10ZM5 16H13V15.45C13 14.7 12.6333 14.1042 11.9 13.6625C11.1667 13.2208 10.2 13 9 13C7.8 13 6.83333 13.2208 6.1 13.6625C5.36667 14.1042 5 14.7 5 15.45V16ZM9 12C9.55 12 10.0208 11.8042 10.4125 11.4125C10.8042 11.0208 11 10.55 11 10C11 9.45 10.8042 8.97917 10.4125 8.5875C10.0208 8.19583 9.55 8 9 8C8.45 8 7.97917 8.19583 7.5875 8.5875C7.19583 8.97917 7 9.45 7 10C7 10.55 7.19583 11.0208 7.5875 11.4125C7.97917 11.8042 8.45 12 9 12ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V6H4V18Z" fill="#DB1F6B"/>
                            </svg> 
                            <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>{user.company.alicias[0].id}</span>
                            <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>/ {aliciaLastEvent?.customer_id}</span>
                        </div>
                        <div className='flex items-center gap-[10px] mb-[20px]'>
                            <svg className='w-[20px] h-[20px] shrink-0' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1099_5029)">
                                    <path d="M10 17.4999C9.41667 17.4999 8.92361 17.2985 8.52083 16.8958C8.11806 16.493 7.91667 15.9999 7.91667 15.4166C7.91667 14.8333 8.11806 14.3402 8.52083 13.9374C8.92361 13.5346 9.41667 13.3333 10 13.3333C10.5833 13.3333 11.0764 13.5346 11.4792 13.9374C11.8819 14.3402 12.0833 14.8333 12.0833 15.4166C12.0833 15.9999 11.8819 16.493 11.4792 16.8958C11.0764 17.2985 10.5833 17.4999 10 17.4999ZM5.29167 12.7916L3.54167 10.9999C4.36111 10.1805 5.32292 9.53117 6.42708 9.052C7.53125 8.57284 8.72222 8.33325 10 8.33325C11.2778 8.33325 12.4688 8.57631 13.5729 9.06242C14.6771 9.54853 15.6389 10.2083 16.4583 11.0416L14.7083 12.7916C14.0972 12.1805 13.3889 11.7013 12.5833 11.3541C11.7778 11.0069 10.9167 10.8333 10 10.8333C9.08333 10.8333 8.22222 11.0069 7.41667 11.3541C6.61111 11.7013 5.90278 12.1805 5.29167 12.7916ZM1.75 9.24992L0 7.49992C1.27778 6.19436 2.77083 5.17353 4.47917 4.43742C6.1875 3.70131 8.02778 3.33325 10 3.33325C11.9722 3.33325 13.8125 3.70131 15.5208 4.43742C17.2292 5.17353 18.7222 6.19436 20 7.49992L18.25 9.24992C17.1806 8.18047 15.941 7.34367 14.5312 6.7395C13.1215 6.13534 11.6111 5.83325 10 5.83325C8.38889 5.83325 6.87847 6.13534 5.46875 6.7395C4.05903 7.34367 2.81944 8.18047 1.75 9.24992Z" fill="#DB1F6B"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1099_5029">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg> 
                            <span className='base-p-small-text' style={{ fontWeight: '500', color: "#535353" }}>Conectada a la red: {aliciaLastEvent?.ssid_wifi}</span>
                        </div>
                        <div className='flex flex-wrap items-center gap-[8px]'>
                            <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.625 18.175L1 17L6 9L9 12.5L13 6L15.725 10.075C15.3417 10.0917 14.9792 10.1375 14.6375 10.2125C14.2958 10.2875 13.9583 10.3917 13.625 10.525L13.075 9.7L9.275 15.875L6.25 12.35L2.625 18.175ZM21.575 23L18.45 19.875C18.1167 20.1083 17.7458 20.2833 17.3375 20.4C16.9292 20.5167 16.5083 20.575 16.075 20.575C14.825 20.575 13.7625 20.1375 12.8875 19.2625C12.0125 18.3875 11.575 17.325 11.575 16.075C11.575 14.825 12.0125 13.7625 12.8875 12.8875C13.7625 12.0125 14.825 11.575 16.075 11.575C17.325 11.575 18.3875 12.0125 19.2625 12.8875C20.1375 13.7625 20.575 14.825 20.575 16.075C20.575 16.5083 20.5167 16.9292 20.4 17.3375C20.2833 17.7458 20.1083 18.125 19.875 18.475L23 21.575L21.575 23ZM16.075 18.575C16.775 18.575 17.3667 18.3333 17.85 17.85C18.3333 17.3667 18.575 16.775 18.575 16.075C18.575 15.375 18.3333 14.7833 17.85 14.3C17.3667 13.8167 16.775 13.575 16.075 13.575C15.375 13.575 14.7833 13.8167 14.3 14.3C13.8167 14.7833 13.575 15.375 13.575 16.075C13.575 16.775 13.8167 17.3667 14.3 17.85C14.7833 18.3333 15.375 18.575 16.075 18.575ZM18.3 10.575C17.9833 10.4417 17.6542 10.3333 17.3125 10.25C16.9708 10.1667 16.6167 10.1167 16.25 10.1L21.375 2L23 3.175L18.3 10.575Z" fill="#DB1F6B"/>
                            </svg> 
                            <span className='base-p-small-text' style={{ fontWeight: '300', fontStyle: 'italic', color: "#535353" }}>Estado del robot: </span>
                            <div className='flex items-center gap-[10px]'>
                                <span className='base-p-small-text' style={{ fontWeight: '500', fontStyle: 'italic', color: `${active == 'Conectado' ? '#007126' : ' #888888' }` }}>{active}</span>
                                <svg className='w-[14px] h-[14px] shrink-0 m-[2px]' viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle className='shrink-0' cx="7" cy="7" r="7" fill="url(#paint0_radial_1173_751)"/>
                                    <defs>
                                        <radialGradient id="paint0_radial_1173_751" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7 7) rotate(90) scale(7)">
                                            <stop stopColor={`${active == 'Conectado' ? '#00D748' : '#888888'}`}/>
                                            <stop offset="1" stopColor={`${active == 'Conectado' ? '#007126' : '#888888'}`}/>
                                        </radialGradient>
                                    </defs>
                                </svg> 
                            </div>
                            <span className='base-p-small-text' style={{ fontWeight: '300', fontStyle: 'italic', color: "#888" }}>Última actualización: {date}</span>
                        </div>
                        </>
                    ) : <LoadingWheel type='alicia'/>}
                </div>
            </div>
            <div className='max-tablet:pb-[8px] tablet:py-[16px]'>
                <span className='base-p-small-title max-tablet:text-xl' style={{ fontWeight: '600' }}>Reporte</span>
            </div>
            <div className='rounded-[15px] min-h-[500px] pt-[14px] pb-[32px] px-[25px]' style={{ background: "#FFF", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}>
                {!isDashboardLoading ? (
                    dashboards.map((dashboardUrl, index) => (
                        <div key={index} className={`flex justify-center ${index ? 'mt-[45px]' : ''}`}>
                            <iframe className='w-[100%] h-[450px] tablet:h-[650px]'src={dashboardUrl} style={{ border: "0px" }}></iframe>
                        </div>
                    ))
                ): <div className='h-[500px]'><LoadingWheel type='alicia'/></div>}
            </div>
            </>
        </div>
    );
};

export default withAliciaAuthenticatedSettings(HomePage);