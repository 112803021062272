import React from 'react';
import { Step } from '@/components/alicia/home/Step';


export const StepList = ({ steps }) => {
    const svgNumberBalls = [
        <svg className='hidden mx-auto max-desktop:block' width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_299_54)">
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#DB1F6B"/>
        <path d="M23.27 21.44V15.08H34.35V44.28H27.23V21.44H23.27Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_299_54">
        <rect width="60" height="60" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        <svg className='hidden mx-auto max-desktop:block' width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_299_57)">
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#DB1F6B"/>
        <path d="M20.13 39.16C21.04 38.44 21.4501 38.11 21.3701 38.16C23.9801 36 26.0401 34.23 27.5301 32.84C29.0501 31.45 30.3301 30 31.3701 28.48C32.4101 26.96 32.93 25.48 32.93 24.04C32.93 22.95 32.68 22.09 32.17 21.48C31.66 20.87 30.9 20.56 29.89 20.56C28.88 20.56 28.08 20.95 27.49 21.72C26.93 22.47 26.65 23.53 26.65 24.92H20.05C20.1 22.65 20.58 20.76 21.49 19.24C22.42 17.72 23.64 16.6 25.13 15.88C26.65 15.16 28.33 14.8 30.17 14.8C33.34 14.8 35.7301 15.61 37.3301 17.24C38.9601 18.87 39.7701 20.99 39.7701 23.6C39.7701 26.45 38.8 29.11 36.85 31.56C34.9 33.99 32.42 36.36 29.41 38.68H40.21V44.24H20.13V39.16Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_299_57">
        <rect width="60" height="60" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        <svg className='hidden mx-auto max-desktop:block' width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_299_60)">
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#DB1F6B"/>
        <path d="M19.9299 23.68C20.0399 20.83 20.9699 18.63 22.7299 17.08C24.4899 15.53 26.8799 14.76 29.8899 14.76C31.8899 14.76 33.5999 15.11 35.0099 15.8C36.4499 16.49 37.5299 17.44 38.2499 18.64C38.9999 19.84 39.3699 21.19 39.3699 22.68C39.3699 24.44 38.9299 25.88 38.0499 27C37.1699 28.09 36.1399 28.84 34.9699 29.24V29.4C36.4899 29.91 37.6899 30.75 38.5699 31.92C39.4499 33.09 39.8899 34.6 39.8899 36.44C39.8899 38.09 39.4999 39.56 38.7299 40.84C37.9799 42.09 36.8799 43.08 35.4099 43.8C33.9699 44.52 32.2499 44.88 30.2499 44.88C27.0499 44.88 24.4899 44.09 22.5699 42.52C20.6799 40.95 19.6799 38.57 19.5699 35.4H26.2099C26.2399 36.57 26.5699 37.51 27.2099 38.2C27.8499 38.87 28.7799 39.2 30.0099 39.2C31.0499 39.2 31.8499 38.91 32.4099 38.32C32.9999 37.71 33.2899 36.91 33.2899 35.92C33.2899 34.64 32.8799 33.72 32.0499 33.16C31.2499 32.57 29.9599 32.28 28.1699 32.28H26.8899V26.72H28.1699C29.5299 26.72 30.6199 26.49 31.4499 26.04C32.2999 25.56 32.7299 24.72 32.7299 23.52C32.7299 22.56 32.4599 21.81 31.9299 21.28C31.3999 20.75 30.6599 20.48 29.7299 20.48C28.7199 20.48 27.9599 20.79 27.4499 21.4C26.9699 22.01 26.6899 22.77 26.6099 23.68H19.9299Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_299_60">
        <rect width="60" height="60" fill="white"/>
        </clipPath>
        </defs>
        </svg>  
    ]

    return (
        <div className='desktop:mt-[45px]'>
            <svg className='hidden mx-auto desktop:block' width="891" height="60" viewBox="0 0 891 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_299_65)">
                    <path d="M861 0C845.44 0 832.66 11.84 831.15 27H478.85C477.34 11.84 464.56 0 449 0C433.44 0 420.66 11.84 419.15 27H59.85C58.34 11.84 45.56 0 30 0C13.43 0 0 13.43 0 30C0 46.57 13.43 60 30 60C46.57 60 58.9 47.63 59.93 32H419.08C420.11 47.63 433.11 60 449.01 60C464.91 60 477.91 47.63 478.94 32H831.09C832.12 47.63 845.12 60 861.02 60C876.92 60 891.02 46.57 891.02 30C891.02 13.43 877.59 0 861.02 0H861Z" fill="#DB1F6B"/>
                    <path d="M850.93 22.76C851.04 19.91 851.97 17.71 853.73 16.16C855.49 14.61 857.88 13.84 860.89 13.84C862.89 13.84 864.6 14.19 866.01 14.88C867.45 15.57 868.53 16.52 869.25 17.72C870 18.92 870.37 20.27 870.37 21.76C870.37 23.52 869.93 24.96 869.05 26.08C868.17 27.17 867.14 27.92 865.97 28.32V28.48C867.49 28.99 868.69 29.83 869.57 31C870.45 32.17 870.89 33.68 870.89 35.52C870.89 37.17 870.5 38.64 869.73 39.92C868.98 41.17 867.88 42.16 866.41 42.88C864.97 43.6 863.25 43.96 861.25 43.96C858.05 43.96 855.49 43.17 853.57 41.6C851.68 40.03 850.68 37.65 850.57 34.48H857.21C857.24 35.65 857.57 36.59 858.21 37.28C858.85 37.95 859.78 38.28 861.01 38.28C862.05 38.28 862.85 37.99 863.41 37.4C864 36.79 864.29 35.99 864.29 35C864.29 33.72 863.88 32.8 863.05 32.24C862.25 31.65 860.96 31.36 859.17 31.36H857.89V25.8H859.17C860.53 25.8 861.62 25.57 862.45 25.12C863.3 24.64 863.73 23.8 863.73 22.6C863.73 21.64 863.46 20.89 862.93 20.36C862.4 19.83 861.66 19.56 860.73 19.56C859.72 19.56 858.96 19.87 858.45 20.48C857.97 21.09 857.69 21.85 857.61 22.76H850.93Z" fill="white"/>
                    <path d="M23.27 21.12V14.76H34.35V43.96H27.23V21.12H23.27Z" fill="white"/>
                    <path d="M439.13 38.88C440.04 38.16 440.45 37.83 440.37 37.88C442.98 35.72 445.04 33.95 446.53 32.56C448.05 31.17 449.33 29.72 450.37 28.2C451.41 26.68 451.93 25.2 451.93 23.76C451.93 22.67 451.68 21.81 451.17 21.2C450.66 20.59 449.9 20.28 448.89 20.28C447.88 20.28 447.08 20.67 446.49 21.44C445.93 22.19 445.65 23.25 445.65 24.64H439.05C439.1 22.37 439.58 20.48 440.49 18.96C441.42 17.44 442.64 16.32 444.13 15.6C445.65 14.88 447.33 14.52 449.17 14.52C452.34 14.52 454.73 15.33 456.33 16.96C457.96 18.59 458.77 20.71 458.77 23.32C458.77 26.17 457.8 28.83 455.85 31.28C453.9 33.71 451.42 36.08 448.41 38.4H459.21V43.96H439.13V38.88Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_299_65">
                        <rect width="891" height="60" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <div className="flex max-desktop:items-center max-desktop:flex-col justify-between">
                {steps.map((step, index) => (
                    <div key={index} className="max-desktop:max-w-[576px] desktop:max-w-[318px] w-full max-tablet:my-[55px] tablet:my-[60px]">
                        {svgNumberBalls[index]}
                        <Step 
                            description={step.description} 
                            image={step.image.data}
                        />
                    </div>
                ))}
            </div>
        </div>
  );
};