import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { PartnerList } from '@/components/base/PartnerList';
import { HoverableMail } from '@/components/base/HoverableMail';
import { getFullUrl, fetchEncodelytLogo } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import '@/styles/alicia/Footer.css';


export const Footer = ({ logo, serviceId }) => {
    const [encodelytLogo, setEncodelytLogo] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
          const data = await fetchEncodelytLogo();
          setEncodelytLogo(data.v1.data);
        }
        asyncFoldTry(fetchData);
    }, []);

    return (
    <div className='w-full'>
        <div style={{ backgroundColor: '#FDF2F8', borderTop: "6px solid #F04492" }}>
            <div className='h-full max-w-[1440px] mx-auto'>
                <div className='flex max-tablet:flex-col tablet:justify-between px-[20px] desktop:px-[137px] max-tablet:pb-[47px] tablet:pb-[67px]'>
                    {/* Alicia */}
                    <div className='flex flex-col max-w-[320px] mt-[37px] max-tablet:items-center tablet:items-start max-tablet:text-center max-tablet:mx-auto w-full'>
                        {logo ? (
                                <Link to='/alicia'>
                                    <img
                                        className="max-tablet:w-[95px] tablet:w-[133px]" 
                                        src={getFullUrl(logo.attributes.url)}
                                        alt={logo.attributes.alternativeText}
                                        style={{filter: 'grayscale(100%) contrast(500%) brightness(45%)'}}
                                    />
                                </Link>
                            ) :  <LoadingWheel type='alicia' />
                        }
                        <span className='base-p-small-text max-tablet:mb-[36px] tablet:mb-[15px] mt-[32px]'>
                            Alicia es un robot promotor para tiendas, creado por Encodelyt.
                        </span>
                        {/* Contact */}
                        <span className='base-i-small-title font-medium max-tablet:mb-[12px] tablet:mb-[16px]'>Contacto</span>
                        <div className='flex w-[135px] p-[10px] mb-[16px]'>
                            <Link to='https://www.linkedin.com/company/encodelyt' target="_blank" className='mr-[10px]' aria-label='Go to Encodelyt LinkedIn profile'>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.2 0H3C1.344 0 0 1.344 0 3V22.2C0 23.856 1.344 25.2 3 25.2H22.2C23.856 25.2 25.2 23.856 25.2 22.2V3C25.2 1.344 23.856 0 22.2 0ZM7.8 9.6V21H4.2V9.6H7.8ZM4.2 6.282C4.2 5.442 4.92 4.8 6 4.8C7.08 4.8 7.758 5.442 7.8 6.282C7.8 7.122 7.128 7.8 6 7.8C4.92 7.8 4.2 7.122 4.2 6.282ZM21 21H17.4C17.4 21 17.4 15.444 17.4 15C17.4 13.8 16.8 12.6 15.3 12.576H15.252C13.8 12.576 13.2 13.812 13.2 15C13.2 15.546 13.2 21 13.2 21H9.6V9.6H13.2V11.136C13.2 11.136 14.358 9.6 16.686 9.6C19.068 9.6 21 11.238 21 14.556V21Z" fill="#181818"/>
                                </svg>
                            </Link>
                            <Link to='https://www.youtube.com/channel/UCOnfryL_Sdi5A4_cCmfmQfQ' className='mr-[10px]' target="_blank" aria-label='Go to Encodelyt Youtube channel'>
                                <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="YouTube-Icon-Almost-Black-Logo.wine 1">
                                        <path id="Vector" d="M30.2837 6.86937C30.0116 5.84497 29.2101 5.03822 28.1923 4.76443C26.3475 4.26685 18.95 4.26685 18.95 4.26685C18.95 4.26685 11.5526 4.26685 9.70786 4.76443C8.69002 5.03822 7.8884 5.84497 7.61643 6.86937C7.12207 8.72623 7.12207 12.6002 7.12207 12.6002C7.12207 12.6002 7.12207 16.4742 7.61643 18.3311C7.8884 19.3555 8.69002 20.1621 9.70786 20.436C11.5526 20.9335 18.95 20.9335 18.95 20.9335C18.95 20.9335 26.3475 20.9335 28.1923 20.436C29.2101 20.1621 30.0116 19.3554 30.2837 18.3312C30.7781 16.4741 30.7781 12.6002 30.7781 12.6002C30.7781 12.6002 30.7781 8.72623 30.2837 6.86937Z" fill="#181818"/>
                                        <path id="Vector_2" d="M16.5308 16.1174L22.7136 12.6002L16.5308 9.08276V16.1174Z" fill="white"/>
                                    </g>
                                </svg>
                            </Link>
                            <Link to='https://api.whatsapp.com/send/?phone=56931762756&text&type=phone_number&app_absent=0' target='_blank' aria-label='Go to Encodelyt WhatsApp chat'>
                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0189 24.2596L10.4008 24.4859C12.0049 25.4379 13.8442 25.9415 15.7199 25.9424H15.7238C21.484 25.9424 26.1718 21.2555 26.1742 15.495C26.1753 12.7035 25.0893 10.0785 23.1162 8.10388C22.1484 7.13005 20.9971 6.35784 19.7289 5.83195C18.4607 5.30607 17.1008 5.03695 15.7279 5.04017C9.96331 5.04017 5.2753 9.72648 5.27325 15.4866C5.27041 17.4536 5.8243 19.3812 6.87089 21.0465L7.11954 21.4416L6.06361 25.2966L10.0189 24.2596ZM3.04468 28.283L4.82859 21.7697C3.72841 19.8636 3.1496 17.7012 3.15029 15.4858C3.15319 8.5552 8.79323 2.91687 15.724 2.91687C19.0873 2.91858 22.2441 4.22742 24.6183 6.6033C26.9925 8.97918 28.2987 12.1372 28.2975 15.4959C28.2945 22.426 22.6536 28.0653 15.7238 28.0653H15.7184C13.6142 28.0645 11.5466 27.5366 9.71005 26.5352L3.04468 28.283Z" fill="#181818"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.4524 10.102C12.2169 9.57888 11.9691 9.56829 11.7454 9.55923L11.1432 9.55188C10.9337 9.55188 10.5933 9.63049 10.3055 9.94493C10.0177 10.2594 9.20581 11.0193 9.20581 12.565C9.20581 14.1107 10.3316 15.6043 10.4885 15.8141C10.6454 16.024 12.6619 19.297 15.8553 20.5563C18.509 21.6028 19.049 21.3947 19.6253 21.3424C20.2015 21.2901 21.4841 20.5825 21.7457 19.8488C22.0073 19.1152 22.0075 18.4867 21.929 18.3553C21.8506 18.2238 21.6411 18.1457 21.3267 17.9885C21.0122 17.8313 19.4679 17.0714 19.1799 16.9664C18.892 16.8615 18.6827 16.8094 18.473 17.1238C18.2633 17.4383 17.6619 18.1456 17.4786 18.3553C17.2952 18.5649 17.1122 18.5913 16.7977 18.4342C16.4833 18.2772 15.4716 17.9453 14.2713 16.875C13.3374 16.0423 12.707 15.0139 12.5235 14.6996C12.3399 14.3853 12.504 14.2151 12.6615 14.0586C12.8025 13.9178 12.9756 13.6917 13.133 13.5083C13.2904 13.325 13.3422 13.1939 13.4468 12.9845C13.5514 12.7752 13.4992 12.5913 13.4206 12.4343C13.342 12.2772 12.7316 10.7233 12.4524 10.102Z" fill="#181818"/>
                                </svg> 
                            </Link>
                        </div>
                        <div className='mt-[14px]'>
                            <HoverableMail type='alicia'/>
                        </div>
                    </div>
                    {/* Encodelyt */}
                    <div className='max-tablet:mx-auto tablet:mx-[30px]'>
                        <div className='flex flex-col max-tablet:w-[158px] tablet:w-[217px] max-tablet:mt-[27px] tablet:mt-[50px] max-tablet:items-center'>
                            <div className='mb-[16px]'>
                                {encodelytLogo ? (
                                        <Link to='/'>
                                            <img
                                                className="w-full" 
                                                src={getFullUrl(encodelytLogo.attributes.url)}
                                                alt={encodelytLogo.attributes.alternativeText}
                                                style={{filter: 'grayscale(100%) contrast(500%) brightness(45%)'}}
                                            />
                                        </Link>
                                    ) :  <LoadingWheel type='alicia' />
                                }
                            </div>
                            <div className='flex flex-col p-[10px] max-tablet:text-center w-full'>
                                <Link to='/' className='mb-[10px]'>
                                    <span className='base-p-text'>Inicio</span>
                                </Link>
                                <Link to='/casos-de-exito' className='my-[10px]'>
                                    <span className='base-p-text'>Casos de éxito</span>
                                </Link>
                                <Link to='/blog' className='my-[10px]'>
                                    <span className='base-p-text'>Blog</span>
                                </Link>
                                <Link to={`/servicios/${serviceId}`} className='mt-[10px]'>
                                    <span className='base-p-text'>Servicios</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Partner list */}
                    <div className='max-tablet:mt-[30px] tablet:mt-[50px]'>
                        <PartnerList type='alicia' />
                    </div>
                </div>
            </div>
        </div>
        <div style={{ backgroundColor: '#181818' }}>
            <div className='flex justify-center'>
                <span className='base-p-text my-[10px]' style={{ color: "#FFFFFF" }}>© 2024 Encodelyt</span>
            </div>
        </div>
    </div>
  );
};