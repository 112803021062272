import React from 'react';
import '@/styles/alicia/Error.css';


export const Error = () => {

  return (
    <>
        <div className={`sign-in-error-container my-[10px] animate-fade-down animate-duration-300 animate-normal`}>
            <svg className='sign-in-error-icon' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 25.5C18.425 25.5 18.7813 25.3563 19.0688 25.0688C19.3563 24.7812 19.5 24.425 19.5 24C19.5 23.575 19.3563 23.2188 19.0688 22.9313C18.7813 22.6438 18.425 22.5 18 22.5C17.575 22.5 17.2188 22.6438 16.9313 22.9313C16.6438 23.2188 16.5 23.575 16.5 24C16.5 24.425 16.6438 24.7812 16.9313 25.0688C17.2188 25.3563 17.575 25.5 18 25.5ZM16.5 19.5H19.5V10.5H16.5V19.5ZM18 33C15.925 33 13.975 32.6063 12.15 31.8188C10.325 31.0313 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.96875 25.675 4.18125 23.85C3.39375 22.025 3 20.075 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.96875 12.15 4.18125C13.975 3.39375 15.925 3 18 3C20.075 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0313 10.325 31.8188 12.15C32.6063 13.975 33 15.925 33 18C33 20.075 32.6063 22.025 31.8188 23.85C31.0313 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0313 23.85 31.8188C22.025 32.6063 20.075 33 18 33ZM18 30C21.35 30 24.1875 28.8375 26.5125 26.5125C28.8375 24.1875 30 21.35 30 18C30 14.65 28.8375 11.8125 26.5125 9.4875C24.1875 7.1625 21.35 6 18 6C14.65 6 11.8125 7.1625 9.4875 9.4875C7.1625 11.8125 6 14.65 6 18C6 21.35 7.1625 24.1875 9.4875 26.5125C11.8125 28.8375 14.65 30 18 30Z" fill="#B00020"/>
            </svg>
            <span className='sign-in-error-text w-3/4 my-[20px]'>Revisa tu e-mail y/o contraseña nuevamente</span>
        </div>
    </>
  );
};