import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Title } from '@/components/base/Title';
import { ShareIcons } from '@/components/encodelyt/blog/ShareIcons';
import { MovableSmallCardList } from '@/components/encodelyt/MovableSmallCardList';
import { PostGrid } from '@/components/encodelyt/blog/PostGrid';
import withEncodelytSettings from '@/hocs/withEncodelytSettings';
import { fetchEncodelytBlogPost, 
         fetchEncodelytLastBlogPosts, 
         fetchEncodelytRandomBlogPosts, 
         getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';


const BlogPostPage = () => {
    const [title, setTitle] = useState(null);
    const [image, setImage] = useState(null);
    const [content, setContent] = useState(null);
    const [lastPosts, setLastPosts] = useState([]);
    const [randomPosts, setRandomPosts] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    // Get blog post instance data by id
    useEffect(()=> {
        const fetchData = async () => {
          setTitle(null);
          setImage(null);
          setContent(null);
          const data = await fetchEncodelytBlogPost(id);
          setTitle(data.title);
          setImage(data.image);
          setContent(data.content);
        }
        asyncFoldTry(fetchData, ()=>navigate('/not_found', {replace: true}));
        
    }, [id]);

    // Get last blog post instances
    useEffect(()=> {
        const fetchData = async () => {
          setLastPosts([]);
          const { data } = await fetchEncodelytLastBlogPosts(5, id);
          setLastPosts(data);
        }
        asyncFoldTry(fetchData);
    }, [id]);

    // Get random blog post instances
    useEffect(()=> {
        const fetchData = async () => {
          setRandomPosts([]);
          const data = await fetchEncodelytRandomBlogPosts(2, id);
          setRandomPosts(data);
        }
        asyncFoldTry(fetchData);
    }, [id]);

    return (
    <>
    <Helmet>
        <meta property='og:title' content='Title of the article'/>
        <meta property='og:image' content='//media.example.com/ 1234567.jpg'/>
        <meta property='og:description' content='Description that will show in the preview'/>
        <meta property='og:url' content='//www.example.com/URL of the article'/>
    </Helmet>
        
    <div className='max-w-[1440px] w-full h-full mx-auto max-desktop:px-[20px] desktop:px-[137px]'>
        <div className='mt-[35px] mb-[60px]'>
            <Title type='encodelyt' name='Blog' center={true} />
        </div>
        {/* Blog image */}
        {image ? (
            <img 
                className='max-phone:h-[206px] max-tablet:h-[256px] tablet:h-[504px] w-full object-cover rounded-[15px]'
                src={getFullUrl(image.data.attributes.url)}
                alt={image.data.attributes.alternativeText}
            />
        ) : <LoadingWheel type='encodelyt' />}  
        <div className='flex max-tablet:flex-col max-tablet:items-center mt-[24px] mb-[60px] gap-[24px]'>
            {/* Blog title */}
            <div className='w-full mt-[20px]'>
                <span className='base-i-large-title'>{title}</span>
            </div>
            {/* Share */}
            <div className='max-tablet:mx-auto tablet:max-w-[335px] tablet:w-full'>
                <div className='mb-[16px] max-tablet:text-center'>
                    <span className='base-p-text' style={{ fontWeight: "600" }}>
                        Comparte
                    </span>
                </div>
                <ShareIcons title={title} />
            </div>
        </div>
        {/* Content */}
        <div className='flex max-tablet:flex-col max-tablet:items-center gap-[24px] min-h-[550px]'>
            <div className='w-full' style={{ borderBottom: "4px solid #814CF2" }}>
                {/* Blog content */}
                <div className='flex flex-col'>
                    {content ? (
                        <BlocksRenderer
                            content={content} 
                            blocks={{
                                paragraph: ({ children }) => <span className="base-p-text mb-[24px]">{children}</span>,
                                image: ({ children, image }) => <img className="rounded-[15px] mb-[24px]" src={image.url}>{children}</img>,
                            }}
                        />
                    ) : <LoadingWheel type='encodelyt' />}
                    {/* Share */}
                    <div className='flex justify-end mb-[20px] mt-[50px]'>  
                        <ShareIcons title={title} />
                    </div>
                </div>
            </div>
            {/* Also interesting */}
            <MovableSmallCardList title='Tambien te puede interesar' entityList={lastPosts} entityType='blog' entityTitleGetter={(entity) => entity.attributes.title} />
        </div>
        <div className='mt-[33px] mb-[64px]'>
            <div className='mb-[20px]'>
                <Title type='encodelyt' name='Otras entradas' underline={false} />
            </div> 
            <PostGrid posts={randomPosts} />
        </div>
    </div>
    </>
    );
};

export default withEncodelytSettings(BlogPostPage);