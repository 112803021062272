import React from 'react';
import { getFullUrl } from '@/api/strapi';


export const Feature = ({ name, icon }) => {

  return (
    <div className='flex items-center'>
        <img   
          className='max-tablet:w-[30px] max-tablet:h-[30px] tablet:w-[40px] tablet:h-[40px]'
          src={getFullUrl(icon.attributes.url)}
          alt={icon.attributes.alternativeText}
        />
        <div className='base-p-text ml-[20px]'>
            <span className='text-white'>{name}</span>
        </div>
    </div>
  );
};