import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { trackPageView } from '@/api/base';
import ScrollToTop from '@/components/base/ScrollToTop';
import EncodelytHomePage from '@/pages/encodelyt/HomePage';
import EncodelytBlogPage from '@/pages/encodelyt/BlogPage';
import EncodelytBlogPostPage from '@/pages/encodelyt/BlogPostPage';
import EncodelytServicePage from '@/pages/encodelyt/ServicePage';
import EncodelytSuccessShowcasePage from '@/pages/encodelyt/SuccessShowcasePage';
import AliciaSignInPage from '@/pages/alicia/SignInPage';
import AliciaHomePage from '@/pages/alicia/HomePage';
import AliciaPanelHomePage from '@/pages/alicia/panel/HomePage';
import AliciaPanelSoundPage from '@/pages/alicia/panel/SoundPage';
import AliciaPanelMovementPage from '@/pages/alicia/panel/MovementPage';
import AliciaPanelContactPage from '@/pages/alicia/panel/ContactPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import "@/styles/App.css";


const GAWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      const availablePaths = ['/', '/blog', '/blog/', '/alicia', '/alicia/', '/alicia/sign-in', '/alicia/sign-in/'];
      if (availablePaths.includes(location.pathname) || location.pathname.startsWith('/servicios') ) {
        trackPageView(location);
      }
    }, 250); 

    return () => clearTimeout(timer);

  }, [location]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <GAWrapper />
        <Routes>
          <Route path='/' element={<EncodelytHomePage />} />
          <Route path="/blog" element={<EncodelytBlogPage />} />
          <Route path="/blog/:id" element={<EncodelytBlogPostPage />} />
          <Route path="/servicios/:id" element={<EncodelytServicePage />} />
          <Route path="/casos-de-exito" element={<EncodelytSuccessShowcasePage />} />
          <Route path="/alicia" element={<AliciaHomePage />} />
          <Route path="/alicia/sign-in" element={<AliciaSignInPage />} /> 
          <Route path="/alicia/panel" element={<AliciaPanelHomePage />} /> 
          <Route path="/alicia/panel/sonido" element={<AliciaPanelSoundPage />} /> 
          <Route path="/alicia/panel/movimiento" element={<AliciaPanelMovementPage />} /> 
          <Route path="/alicia/panel/contacto" element={<AliciaPanelContactPage />} /> 
          <Route path="/not_found" element={<NotFoundPage />} />
          <Route path="*"
            element={<Navigate to="/not_found" replace state={{ prevLocation: window.location.pathname }} />}
          />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  
  )
}

export default App