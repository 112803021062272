import React, { useState, useEffect, useRef } from 'react';


export const VolumeSelector = ({ initialValue, valueSetter, width }) => {
    const [value, setValue] = useState(initialValue);
    const [isDragging, setIsDragging] = useState(false);
    const pickerRef = useRef(null);

    const handleMouseClick = (event) => {
        event.preventDefault();
        const rect = pickerRef.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const percent = offsetX / rect.width;
        const clampedPercent = Math.max(0, Math.min((width-5)/width, percent));
        const newValue = clampedPercent * 100;
        const roundedValue = Math.round(newValue);
        setValue(roundedValue);
        valueSetter(Math.round(roundedValue * width/(width-5)));
    }

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging])

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (isDragging) {
            handleMouseClick(event);
        }
    };

    const handleMouseDown = (event) => {
        setIsDragging(true);
        handleMouseClick(event);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div 
            ref={pickerRef}
            className='relative h-[24px]' style={{ maxWidth: `${width}px`, width: "100%" }}
            onClick={handleMouseClick}
            onMouseDown={handleMouseDown}
        >
             <div className='absolute w-full h-[4px] rounded-[3px] top-[10px]' style={{ background: "#DB1F6B" }}></div>
             <div className='absolute h-[16px] w-[5px] rounded-[3px] top-[4px]' style={{ background: "#DB1F6B", left: `${value}%` }}></div>
        </div>
     
    );
};