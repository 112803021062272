import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { signOut } from "aws-amplify/auth"
import { Button } from '@/components/base/Button';


export const Navbar = ({ company_image }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [section, setSection] = useState(null);
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const phoneNavbarRef = useRef(null);

    const isPath = (path) => {
        return path == currentPath || path + '/' == currentPath;
    }

    const togglePanelVisibility = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    useEffect(() => {
        if (isPath('/alicia/panel')) {
            setSection('Vista general');
        } else if (isPath('/alicia/panel/sonido') || isPath('/alicia/panel/movimiento')) {
            setSection('Configuraciones');
        } else if (isPath('/alicia/panel/contacto')) {
            setSection('Contacto');
        }
    }, [currentPath]);

    useEffect(() => {
        const closePanelIfClickOuside = (e) => {
            if (phoneNavbarRef.current && !phoneNavbarRef.current.contains(e.target)) {
                setIsPanelVisible(false);
            }
        }
        document.addEventListener('mousedown', closePanelIfClickOuside);

        return () => {
            document.removeEventListener('mousedown', closePanelIfClickOuside);
        };
    }, [])

 

    const handleSignOut = async () => {
        await signOut()
        navigate('/alicia/sign-in');
    }

    const exitSvg = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H12V21H5ZM16 17L14.625 15.55L17.175 13H9V11H17.175L14.625 8.45L16 7L21 12L16 17Z" fill="white"/>
        </svg> 
    )
 
    return (
    <> 
        <div className='hidden tablet:block'>
            <div className='flex justify-end mt-[38px] mb-[33px] pl-[20px] pr-[30px]'>
                <Button 
                    name='Cerrar sesión' 
                    onClick={handleSignOut}
                    type='alicia-pink'
                    svgContent={exitSvg}
                    svgPosition='left'
                    cls='h-[40px] w-[213px]'
                    containerStyle={{
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        background: "var(--botones-cn-degradado, linear-gradient(180deg, #DB1F6B -5.68%, #C21455 100%))",
                        border: "none" 
                    }}
                    textStyle={{fontSize: "20px", textAlign: "right", paddingRight: "20px" }}
                />
            </div>
        </div>
        <div className='hidden max-tablet:block py-[8px] mb-[36px] relative' style={{ background: "#FFF" }} ref={phoneNavbarRef}>
            <div className='flex items-center mx-[20px]'>
                <svg className='w-[36px] h-[24px] shrink-0 cursor-pointer' viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={togglePanelVisibility}>
                    <path d="M0 24H36V20H0V24ZM0 14H36V10H0V14ZM0 0V4H36V0H0Z" fill="#181818"/>
                </svg> 
                <span className='base-p-small-title ml-[23px]' style={{ fontSize: '20px', fontWeight: '600'}}>{section}</span>
                <div className='flex grow justify-end mr-[3px]'>
                    <img className='max-w-[48px] w-full aspect-square rounded-full object-cover' src={company_image} />
                </div>
            </div>
            <div 
                className={`absolute top-[58px] z-50 rounded-[8px] pt-[22px] pb-[16px] px-[16px] max-w-[284px] w-full ${isPanelVisible ? '' : 'hidden'}`} 
                style={{ background: "#FFF", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
            >
                <span className='base-p-small-text' style={{ fontWeight: "700" }}>Vista general</span>
                <Link to='/alicia/panel'>
                    <div className='flex py-[10px] pr-[10px] pl-[24px] gap-[10px]'>
                        <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 19H9V13H15V19H18V10L12 5.5L6 10V19ZM4 21V9L12 3L20 9V21H13V15H11V21H4Z" fill={isPath('/alicia/panel') ? '#DB1F6B' : '#181818'}/>
                        </svg> 
                        <span className='base-p-small-text'>Home</span>
                    </div>
                </Link>
                <span className='base-p-small-text' style={{ fontWeight: "700" }}>Configuraciones</span>
                <Link to='/alicia/panel/sonido'>
                    <div className='flex py-[10px] pr-[10px] pl-[24px] gap-[10px]'>
                        <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 20.7251V18.6751C15.5 18.2418 16.7083 17.4084 17.625 16.1751C18.5417 14.9418 19 13.5418 19 11.9751C19 10.4084 18.5417 9.00843 17.625 7.7751C16.7083 6.54176 15.5 5.70843 14 5.2751V3.2251C16.0667 3.69176 17.75 4.7376 19.05 6.3626C20.35 7.9876 21 9.85843 21 11.9751C21 14.0918 20.35 15.9626 19.05 17.5876C17.75 19.2126 16.0667 20.2584 14 20.7251ZM3 15.0001V9.0001H7L12 4.0001V20.0001L7 15.0001H3ZM14 16.0001V7.9501C14.7833 8.31676 15.3958 8.86676 15.8375 9.6001C16.2792 10.3334 16.5 11.1334 16.5 12.0001C16.5 12.8501 16.2792 13.6376 15.8375 14.3626C15.3958 15.0876 14.7833 15.6334 14 16.0001ZM10 8.8501L7.85 11.0001H5V13.0001H7.85L10 15.1501V8.8501Z"
                                fill={isPath('/alicia/panel/sonido') ? '#DB1F6B' : '#181818'}
                            />
                        </svg> 
                        <span className='base-p-small-text'>Sonido</span>
                    </div>
                </Link>
                <Link to='/alicia/panel/movimiento'>
                    <div className='flex py-[10px] pr-[10px] pl-[24px] gap-[10px]'>
                        <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 22V20H20V22H4ZM12 19L8 15L9.4 13.6L11 15.15V8.85L9.4 10.4L8 9L12 5L16 9L14.6 10.4L13 8.85V15.15L14.6 13.6L16 15L12 19ZM4 4V2H20V4H4Z" fill={isPath('/alicia/panel/movimiento') ? '#DB1F6B' : '#181818'}/>
                        </svg> 
                        <span className='base-p-small-text'>Movimiento</span>
                    </div>
                </Link>
                <span className='base-p-small-text' style={{ fontWeight: "700" }}>Contacto</span>
                <Link to='/alicia/panel/contacto'>
                    <div className='flex py-[10px] pr-[10px] pl-[24px] gap-[10px]'>
                        <svg className='w-[24px] h-[24px] shrink-0' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                d="M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7875 2.22083 14.8125 2.6625C15.8375 3.10417 16.7375 3.7125 17.5125 4.4875C18.2875 5.2625 18.8958 6.1625 19.3375 7.1875C19.7792 8.2125 20 9.31667 20 10.5C20 11.75 19.7958 12.95 19.3875 14.1C18.9792 15.25 18.4208 16.3167 17.7125 17.3C17.0042 18.2833 16.1625 19.175 15.1875 19.975C14.2125 20.775 13.15 21.45 12 22ZM14 18.35C15.1833 17.35 16.1458 16.1792 16.8875 14.8375C17.6292 13.4958 18 12.05 18 10.5C18 8.68333 17.3708 7.14583 16.1125 5.8875C14.8542 4.62917 13.3167 4 11.5 4C9.68333 4 8.14583 4.62917 6.8875 5.8875C5.62917 7.14583 5 8.68333 5 10.5C5 12.3167 5.62917 13.8542 6.8875 15.1125C8.14583 16.3708 9.68333 17 11.5 17H14V18.35ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1125 7.0625 13.5375 6.6375C12.9625 6.2125 12.2833 6 11.5 6C10.7667 6 10.15 6.20417 9.65 6.6125C9.15 7.02083 8.8 7.51667 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.0875 10.475 7.8125C10.7083 7.5375 11.05 7.4 11.5 7.4C11.95 7.4 12.2875 7.525 12.5125 7.775C12.7375 8.025 12.85 8.3 12.85 8.6C12.85 8.88333 12.7667 9.1375 12.6 9.3625C12.4333 9.5875 12.2333 9.81667 12 10.05C11.4167 10.55 11.0625 10.9458 10.9375 11.2375C10.8125 11.5292 10.75 12.05 10.75 12.8Z"
                                fill={isPath('/alicia/panel/contacto') ? '#DB1F6B' : '#181818'}
                            />
                        </svg> 
                        <span className='base-p-small-text'>Contacto</span>
                    </div>
                </Link>
            </div>
        </div>
    </>
  
  );
};