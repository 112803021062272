import React, { useState, useEffect, useRef } from 'react';
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { Button } from '@/components/base/Button';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { Title } from '@/components/base/Title';
import { getFullUrl, fetchAliciaHome } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import { AnimatedBackground } from '@/components/alicia/AnimatedBackground';
import { FeatureList } from '@/components/alicia/home/FeatureList';
import { ObjectiveList } from '@/components/alicia/home/ObjectiveList';
import { StepList } from '@/components/alicia/home/StepList';
import { ClientCarrousel } from '@/components/alicia/home/ClientCarousel';
import { Meeting } from '@/components/base/Meeting';
import { Navbar } from '@/components/alicia/Navbar';
import withAliciaSettings from '@/hocs/withAliciaSettings'
import { TestimonialVideoCarousel } from '@/components/alicia/home/TestimonialVideoCarousel';


const HomePage = ({ logoV1, logoV2 }) => {
    const [introduction, setIntroduction] = useState(null);
    const [introductionImage, setIntroductionImage] = useState(null);
    const [introductionBackground, setIntroductionBackground] = useState(null);
    const [videoId, setVideoId] = useState(null);
    const [objectives, setObjectives] = useState([]);
    const [features, setFeatures] = useState([]);
    const [steps, setSteps] = useState([]);
    const [versatilityText, setVersatilityText] = useState(null);
    const [versatilityImage, setVersatilityImage] = useState(null);
    const [versatilityBackground, setVersatilityBackground] = useState(null);
    const [analyticsText, setAnalyticsText] = useState(null);
    const [analyticsImage, setAnalyticsImage] = useState(null);
    const [analyticsBackground, setAnalyticsBackground] = useState(null);
    const [clients, setClients] = useState([]);
    const [testimonyVideoIds, setTestimonyVideoIds] = useState([]);
    const meetingRef = useRef(null);

    useEffect(()=> {
        const fetchData = async () => {
          const data = await fetchAliciaHome();
          const intro = data.introduction;
          let introSpan;
          if (intro.includes('robot promotor')) {
            const splitedIntro = intro.split('robot promotor')
            introSpan = splitedIntro.map((element, index) => {
                return index == splitedIntro.length - 1 ? (
                <span key={index} className='base-p-title' style={{color: '#FFF'}}>{element}</span>
                ) : (
                <span key={index}>
                    <span className='base-p-title' style={{color: '#FFF'}}>{element}</span>
                    <span className='base-p-title' style={{color: '#FFF', fontWeight: "700" }}>robot promotor</span>
                </span>
                )
            })
          } else {
            introSpan = <span className='base-p-title'>{intro}</span>
          }
          setIntroduction(introSpan);
          setIntroductionImage(data.introduction_image ? data.introduction_image.data : null);
          setIntroductionBackground(data.introduction_background ? data.introduction_background.data : null);
          setVideoId(data.youtube_video_id);
          setObjectives(data.objectives ? data.objectives.data.map(objective => objective.attributes) : []);
          setFeatures(data.features ? data.features.data.map(feature => feature.attributes) : []);
          setSteps(data.steps ? data.steps.data.map(step => step.attributes) : []);
          setVersatilityText(data.versatility_text);
          setVersatilityImage(data.versatility_image ? data.versatility_image.data : null);
          setVersatilityBackground(data.versatility_background ? data.versatility_background.data : null);
          setAnalyticsText(data.analytics_text);
          setAnalyticsImage(data.analytics_image ? data.analytics_image.data : null);
          setAnalyticsBackground(data.analytics_background ? data.analytics_background.data : null);
          setClients(data.clients ? data.clients.data.map(client => client.attributes) : []);
          setTestimonyVideoIds(data.testimony_videos ? data.testimony_videos.data.map(video => video.attributes.youtube_video_id) : []);
        }
        asyncFoldTry(fetchData);
    }, []);

    const handleIntroductionButton = () => {
        if (meetingRef.current) {
          meetingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    
    return (
        <div className='grow'>
            {/* Introduction */}
            <div className='relative overflow-hidden' style={{ background: "var(--Linear, linear-gradient(180deg, #DF387B 0%, #A11346 100%))" }}>
                <AnimatedBackground />
                <Navbar logoV1={logoV1} logoV2={logoV2}/>
                <div className='max-w-[1440px] w-full h-full mx-auto flex w-full tablet:pt-[48px] max-tablet:flex-col-reverse max-tablet:items-center max-tablet:justify-center max-tablet:pb-[37px] tablet:pb-[96px] px-[20px] desktop:px-[137px] z-50'>     
                    <div className="tablet:min-w-[460px] max-w-[640px] w-full h-full relative">
                        <MouseParallaxContainer resetOnLeave={true}>
                        {introductionImage && introductionBackground ? (
                            <>
                                <MouseParallaxChild factorX={0.15} factorY={0.15} className='relative w-full h-full z-20'>
                                    <img
                                        className="w-full h-full object-cover p-[60px]" 
                                        src={getFullUrl(introductionImage.attributes.url)}
                                        alt={introductionImage.attributes.alternativeText}
                                    />
                                </MouseParallaxChild>
                                <MouseParallaxChild factorX={0.1} factorY={0.1} className='absolute w-full h-full z-10 top-0 left-0'>
                                    <img
                                        className="w-full h-full p-[40px]"
                                        src={getFullUrl(introductionBackground.attributes.url)}
                                        alt={introductionBackground.attributes.alternativeText}
                                    />
                                </MouseParallaxChild>
                            </>
                            ) : (
                            <div className='h-full flex items-center justify-center w-[691px]'>
                                <LoadingWheel type='alicia' />
                            </div>
                        )}
                        </MouseParallaxContainer>
                    </div>
                    <div className='max-w-[563px] z-50'>
                        {introduction ? (
                            <div className='max-tablet:mt-[31px] tablet:my-[47px] desktop:max-h-[245px]' style={{ overflow: 'hidden' }}>
                                {introduction}
                            </div>
                        ): <LoadingWheel type='alicia' margin={true} />}
                        <div className='min-w-[298px] max-w-[345px] hidden tablet:block'>
                            <Button name='Potencia tus ventas' onClick={handleIntroductionButton} type='alicia-pink'/>
                        </div>
                    </div>
                </div>
            </div>
            {/* Objectives */}
            <div className='max-w-[1440px] w-full h-full mx-auto max-tablet:pt-[12px] tablet:pt-[60px] pb-[60px] px-[20px] desktop:px-[137px]'>
                <div className='hidden max-tablet:block'>
                    <span className='base-p-large-title'>¿Qué hace a Alicia tan especial?</span>
                </div>
                <div className='text-center hidden tablet:block'>
                    <span className='base-p-large-title'>¿Qué hace a Alicia tan especial?</span>
                </div>
                {objectives.length ? <ObjectiveList objectives={objectives}/> : <LoadingWheel type='alicia' margin={true} />}
            </div>
            {/* Features */}
            {features.length ? <FeatureList features={features}/> : <LoadingWheel type='alicia' margin={true} />}
            {/* Steps */}
            <div className='max-w-[1440px] w-full h-full mx-auto max-tablet:mt-[99px] tablet:mt-[42px] desktop:mt-[60px] px-[20px] desktop:px-[137px]'>
                <div className='text-center'>
                    <span className='base-p-large-title'>¿Cómo funciona?</span>
                </div>
                {steps.length ? <StepList steps={steps}/> : <LoadingWheel type='alicia' margin={true} />}
            </div>
            {/* Versatility */}
            <div className='flex max-tablet:flex-col max-tablet:items-center tablet:justify-between max-w-[1440px] w-full mx-auto max-tablet:mt-[100px] tablet:mt-[96px] desktop:mt-[60px] px-[20px] desktop:px-[137px]'>
                <div className='flex flex-col max-tablet:mb-[18px] tablet:max-w-[560px] w-full'>
                    <div className='flex max-tablet:gap-[32px] tablet:gap-[24px] max-tablet:mb-[24px] tablet:mb-[71px]'>
                        <svg className='max-tablet:w-[48px] max-tablet:h-[48px] tablet:w-[59px] tablet:h-[59px] shrink-0' viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="29.5" cy="29.5" r="29.5" fill="#F04492"/>
                            <path d="M37.2917 32H17V28.6667H37.2917L27.9583 19.3333L30.3333 17L43.6667 30.3333L30.3333 43.6667L27.9583 41.3333L37.2917 32Z" fill="white"/>
                        </svg>
                        <span className='base-p-large-title'>Versatilidad</span>
                    </div>
                    {versatilityText ? (
                        <span className='base-p-title max-tablet:text-center'>{versatilityText}</span>
                    ) : <LoadingWheel type='alicia' />
                    }
                </div>
                <div className="tablet:min-w-[455px] max-w-[563px] w-full aspect-[6/5] tablet:mt-[60px] relative">
                    {versatilityImage && versatilityBackground ? (
                        <MouseParallaxContainer resetOnLeave={true} className='w-full h-full'>
                            <MouseParallaxChild factorX={0.15} factorY={0.15} className='absolute z-0 top-0 left-0 w-full h-full'>
                                <img
                                    className="w-full h-full p-[40px]"
                                    src={getFullUrl(versatilityBackground.attributes.url)}
                                    alt={versatilityBackground.attributes.alternativeText}
                                />
                            </MouseParallaxChild>
                            <img
                                className="relative w-full h-full object-cover p-[40px]" 
                                src={getFullUrl(versatilityImage.attributes.url)}
                                alt={versatilityImage.attributes.alternativeText}
                            />
                        </MouseParallaxContainer>
                        ) : <LoadingWheel type='alicia' />  
                    }
                </div>
            </div>
            {/* Analytics */}
            <div style={{ background: "#FDF2F8" }}>
                <div className='max-w-[1440px] w-full h-full mx-auto'>
                    <div className='flex max-tablet:flex-col-reverse max-tablet:items-center tablet:justify-between pt-[34px] max-tablet:pb-[24px] tablet:pb-[65px] px-[20px] desktop:px-[137px] relative'>
                        {analyticsBackground ? (
                            <img 
                                className='absolute w-full h-full z-0 top-0 left-0 object-cover' 
                                src={getFullUrl(analyticsBackground.attributes.url)} 
                                alt={analyticsBackground.attributes.alternativeText}
                            />
                        ) : null}
                        <div className='max-w-[563px] w-full aspect-square max-tablet:mt-[24px] tablet:mt-[14px] tablet:mr-[20px] z-10'>
                            {analyticsImage ? (
                                <img 
                                    className='w-full h-full object-cover' 
                                    src={getFullUrl(analyticsImage.attributes.url)}
                                    alt={analyticsImage.attributes.url}
                                />
                            ) : <LoadingWheel type='alicia'/>}
                        </div>
                        <div className='flex flex-col tablet:max-w-[563px] w-full z-10'>
                            <div className='flex max-tablet:gap-[32px] tablet:gap-[24px] max-tablet:mb-[24px] tablet:mb-[29px]'>
                                <svg className='max-tablet:w-[48px] max-tablet:h-[48px] tablet:w-[59px] tablet:h-[59px] shrink-0' viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="29.5" cy="29.5" r="29.5" fill="#F04492"/>
                                    <path d="M37.2917 32H17V28.6667H37.2917L27.9583 19.3333L30.3333 17L43.6667 30.3333L30.3333 43.6667L27.9583 41.3333L37.2917 32Z" fill="white"/>
                                </svg>
                                <span className='base-p-large-title mb-[29px]'>Revisa tus datos en tiempo real</span>
                            </div>
                            {versatilityText ? (
                                 <span className='base-p-title max-tablet:text-center'>{analyticsText}</span>
                            ) : <LoadingWheel type='alicia' />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* Alicia video */}
            <div className='pt-[45px] pb-[86px] relative min-h-[425px]' style={{ background: "linear-gradient(180deg, #DB1F6B 0%, #751139 69.74%, #86153D 69.74%)" }}>
                <svg className='absolute w-full h-[401px] z-0 bottom-0' viewBox="0 0 1440 401" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 400.998L719.273 401L1440 401V209.777C958.579 106.759 372.129 0.46819 0 0.00146484V400.998Z" fill="#C21455"/>
                </svg>
                <div className='max-w-[1440px] w-full h-full mx-auto px-[20px] desktop:px-[137px] relative'>
                    <div className='mb-[73px]'>
                        <Title type='alicia' name='Alicia en acción' center={true} />
                    </div>
                    {videoId ? (
                        <div className='flex justify-center'>
                            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}`} allowFullScreen style={{ border: "0px" }}></iframe>
                        </div>
                    ) : <LoadingWheel wheelColor='#FFF' />
                    }
                </div>
            </div>
            {/* Our clients */}
            <div ref={meetingRef} className='max-w-[1440px] w-full h-full mx-auto px-[20px] desktop:px-[137px] mt-[69px]'>
                <div className='text-center'>
                    <span className='base-p-large-title' style={{ color: "#DB1F6B" }}>Nuestros clientes</span>
                </div>
                <div className='my-[69px]'>
                    {clients.length ? <ClientCarrousel clients={clients}/> : <LoadingWheel type='alicia'/>}
                </div>
            </div>
            {/* Testimonials */}
            <div ref={meetingRef} className='max-w-[1440px] w-full h-full mx-auto px-[20px] desktop:px-[137px] mt-[16px]'>
                <div className='text-center mb-[32px]'>
                    <span className='base-p-large-title' style={{ color: "#DB1F6B" }}>Testimonios</span>
                </div>
                {testimonyVideoIds.length ? <TestimonialVideoCarousel videoIds={testimonyVideoIds}/> : <LoadingWheel type='alicia' />}
            </div>
            {/* Meeting */}
            <div ref={meetingRef} className='max-w-[1440px] w-full h-full mx-auto px-[20px] desktop:px-[137px] mt-[170px]'>
                <div className='text-center'>
                    <span className='base-p-large-title'>Agendemos una reunión</span>
                </div>
                <Meeting type='alicia'/>
            </div>
        </div>
    );
};

export default withAliciaSettings(HomePage);