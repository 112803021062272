
import React from 'react';
import { Link } from 'react-router-dom';


export const HoverableMail = ({ type }) => {
    let suffix;
    if (type === 'encodelyt') {
        import('@/styles/encodelyt/HoverableMail.css');
        suffix = '-encodelyt';
    } else if (type === 'alicia') {
        import('@/styles/alicia/HoverableMail.css');
        suffix = '-alicia';
    }

    return (
        <Link to='mailto:contacto@encodelyt.com'>
            <div className={`hoverable-mail${suffix} h-[28px]`}>
                <div className={`flex items-center gap-[8px]`}>
                    <svg className='h-[24px] w-[24px] shrink-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>
                    </svg>
                    <span className='base-p-text'>contacto@encodelyt.com</span>
                </div>
            </div>
        </Link>
    )
}