import React from 'react';
import '@/styles/alicia/AnimatedBackground.css';


export const AnimatedBackground = () => {
    return (
        <>
        <div className="wave absolute bottom-0 h-[300px] z-0"></div>
        <div className="wave absolute bottom-[10px] h-[300px] z-0"></div>
        <div className="absolute bottom-0 w-full h-[12%] z-10" style={{ background: "#F3D7E3" }}></div>
        </>
    )
}