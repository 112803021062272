import React from 'react';


export const SoundSegmentDropDown = ({ index, fieldValue, fieldSetter, options, width, openedDropDowns, openedDropDownsSetter }) => {
  
    const handleOpen = () => {
        if (index == null) return;
        const actualOpenState = openedDropDowns[index];
        let updatedOpenedDropDowns;
        if (actualOpenState === false) {
            updatedOpenedDropDowns = Array.from({ length: openedDropDowns.length }).fill(false);
            updatedOpenedDropDowns[index] = true;
        } else {
            updatedOpenedDropDowns = [...openedDropDowns];
            updatedOpenedDropDowns[index] = false;
        }
        openedDropDownsSetter(updatedOpenedDropDowns);
    }

    const handleOption = (option) => {
        if (index == null) return;
        fieldSetter(option);
        const updatedOpenedDropDowns = [...openedDropDowns];
        updatedOpenedDropDowns[index] = false;
        openedDropDownsSetter(updatedOpenedDropDowns);
    }

    return (
        <>
        {/* Desktop drop down */}
        <div className='hidden tablet:block'>
            <div className='flex relative gap-[10px]'>
                <span className='base-p-small-text overflow-hidden text-center text-nowrap' style={{ color: "#888", fontWeight: "300", fontStyle: "italic", width: width }}>{fieldValue}</span>
                <svg className={`w-[24px] h-[24px] mr-[3px] shrink-0 ${index == null ? '' : 'cursor-pointer'}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleOpen}>
                    <path 
                        d="M12 15L16 11H8L12 15ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" 
                        fill={`${index == null ? 'rgb(136, 136, 136)' : "#DB1F6B"}`}
                    />
                </svg>
                {index != null && openedDropDowns != null && openedDropDowns[index] ? (
                    <div className='absolute top-[25px] right-0 z-10 py-[8px] rounded-[4px]' style={{ background: "#FFF", boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)" }}>
                        {options.map((option, key) => (
                            <div key={key} className='cursor-pointer py-[8px] px-[12px] w-[180px] hover:bg-light-pink' onClick={() => handleOption(option)}>
                                <span className='base-p-small-text' style={{ color: "var(--M3-sys-light-on-surface, #1D1B20)" , fontWeight: "300"}}>{option}</span>
                            </div>
                        ))}
                    </div>
                ) : null} 
            </div>
        </div>
        {/* Phone drop down */}
        <div className='flex grow justify-center hidden max-tablet:block'>
        <div className='rounded-[30px] py-[3px] px-[7px] w-[174px]' style={{border: "1px solid #DB1F6B", background: "#FFF" }}>
            <div className='flex relative gap-[10px]'>
                <svg className={`w-[24px] h-[24px] shrink-0 ${index == null ? '' : 'cursor-pointer'}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleOpen}>
                    <path 
                        d="M12 15L16 11H8L12 15ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" 
                        fill={`${index == null ? 'rgb(136, 136, 136)' : "#DB1F6B"}`}
                    />
                </svg>
                <span className='base-p-small-text overflow-hidden text-nowrap w-full' style={{ color: "#888", fontWeight: "300", fontStyle: "italic" }}>{fieldValue}</span>
                {index != null && openedDropDowns != null && openedDropDowns[index] ? (
                    <div className='absolute top-[25px] left-0 z-10 py-[8px] rounded-[4px]' style={{ background: "#FFF", boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)" }}>
                        {options.map((option, key) => (
                            <div key={key} className='cursor-pointer py-[8px] px-[12px] w-[180px] hover:bg-light-pink' onClick={() => handleOption(option)}>
                                <span className='base-p-small-text' style={{ color: "var(--M3-sys-light-on-surface, #1D1B20)", fontWeight: "300"}}>{option}</span>
                            </div>
                        ))}
                    </div>
                ) : null} 
            </div>
        </div>

        </div>
        
        </>
  );
}