import React, { useState } from 'react';
import { Button } from '@/components/base/Button';
import { HoverableMail } from '@/components/base/HoverableMail';


export const Meeting = ({ type }) => {
    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    let header;
    if (type === 'encodelyt') {
        header = (
            <div className={`max-w-[455px] py-[100px] mx-auto ${isVisible ? '' : 'hidden' }`}>
                <Button name='AGENDAR UNA HORA' onClick={toggleVisibility} type='encodelyt-purple' />
                <div className='flex justify-center my-[36px]'>
                    <HoverableMail type={type} />
                </div>
            </div>
        )
    } else if (type === 'alicia') {
        header = (
            <div className={`max-w-[325px] py-[100px] mx-auto ${isVisible ? '' : 'hidden' }`}>
                <Button name='Horas disponibles' onClick={toggleVisibility} type='alicia-pink' />
                <div className='flex justify-center my-[36px]'>
                    <HoverableMail type={type} />
                </div>
            </div>
        )
    } else if (type === 'alicia-panel') {
        header = (
            <div className={`flex max-tablet:flex-col-reverse tablet:flex-col ${isVisible ? '' : 'hidden' }`}>
                <Button 
                    name='Abrir formulario' 
                    onClick={toggleVisibility}
                    svg={false}
                    type='alicia-pink'
                    cls='mx-auto h-[40px] w-[213px]'
                    containerStyle={{
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        background: "#DB1F6B",
                        border: "none" 
                    }}
                    textStyle={{fontSize: "20px" }}
                />
                {/* Desktop svg */}
                <svg className='mt-[73px] pl-[18px] mx-auto hidden tablet:block' width="306" height="241" viewBox="0 0 306 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M209.576 147.374L210.676 118.412L212.444 118.136C229.965 115.407 242.882 106.191 250.849 90.7533C255.806 81.1398 257.215 71.9438 257.561 68.9705C253.952 65.1881 239.767 49.0886 248.84 38.5311C251.702 35.2046 254.366 35.012 256.102 35.4358C259.075 36.1615 261.424 39.2504 263.216 42.8659C263.939 33.734 265.787 22.4317 270.447 19.7538C274.737 17.2942 278.402 16.9538 281.344 18.7263C284.965 20.9161 286.276 25.6939 286.751 28.9048C289.446 28.1278 291.696 28.3782 293.457 29.6626C297.153 32.3533 296.745 38.255 296.683 38.9229C294.879 61.4056 285.67 68.8164 282.166 70.8457C278.847 109.158 260.12 127.91 244.903 136.907C228.259 146.751 212.536 147.297 211.875 147.317L209.563 147.381L209.576 147.374ZM214.854 122.278L214.081 142.629C218.958 142.096 230.818 140.111 242.932 132.906C263.89 120.435 275.676 98.9989 277.969 69.2017L278.08 67.7439L279.409 67.2623C279.496 67.2302 290.441 62.6771 292.382 38.5375C292.487 37.2468 292.283 34.2927 290.973 33.3487C290.015 32.6552 288.173 32.9185 285.775 34.0872L282.734 35.5771L282.691 32.0836C282.66 30.0287 281.968 24.3068 279.162 22.6179C277.629 21.6931 275.398 22.0528 272.536 23.6967C269.285 25.5655 267.209 40.2008 267.085 53.2756C267.073 54.4636 266.208 55.4719 265.077 55.6196C263.945 55.7737 262.864 55.0159 262.567 53.8728C260.546 46.1795 257.475 40.3999 255.107 39.8219C254.248 39.61 253.222 40.188 252.06 41.5365C244.848 49.9234 261.127 66.3311 261.294 66.4981L262.036 67.2238L261.968 68.2834C261.937 68.765 261.127 80.2472 254.73 92.7312C248.945 104.027 237.351 118.168 214.842 122.297L214.854 122.278ZM266.745 52.6655C266.745 52.6655 266.745 52.6655 266.745 52.672C266.745 52.672 266.745 52.672 266.745 52.6655Z" fill="#DB1F6B"/>
                    <path d="M257.5 77.5001C257.5 77.5001 265.982 76.8704 277.255 84.3499L257.5 77.5001Z" fill="#5BD8FF"/>
                    <path d="M257.5 77.5001C257.5 77.5001 265.982 76.8704 277.255 84.3499" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M246.654 97.3269L266.127 111.231" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M237.382 108.45L253.145 126.988" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M225.327 114.938L236.454 137.185" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M279.5 70.0002C279.5 70.0002 272.718 73.2465 259 68.0002L279.5 70.0002Z" fill="#5BD8FF"/>
                    <path d="M279.5 70.0002C279.5 70.0002 272.718 73.2465 259 68.0002" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M285.5 32L278.988 45.9487C278.626 46.9777 277.476 47.5229 276.425 47.1615L264.273 42.9718" stroke="#DB1F6B" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M173.388 22.0999C192.387 22.0999 207.839 37.4886 207.839 56.4103V181.809C207.839 200.731 192.387 216.119 173.388 216.119H70.4852C51.4859 216.119 36.0341 200.731 36.0341 181.809V56.4103C36.0341 37.4886 51.4859 22.0999 70.4852 22.0999H173.388ZM173.388 17.6116H70.4852C48.9686 17.6116 31.5273 34.9816 31.5273 56.4103V181.809C31.5273 203.238 48.9686 220.608 70.4852 220.608H173.388C194.904 220.608 212.346 203.238 212.346 181.809V56.4103C212.346 34.9816 194.904 17.6116 173.388 17.6116Z" fill="#DB1F6B"/>
                    <path d="M172.964 43.5654H71.8357C62.3769 43.5654 54.709 51.1221 54.709 60.4437V103.622C54.709 112.943 62.3769 120.5 71.8357 120.5H172.964C182.423 120.5 190.091 112.943 190.091 103.622V60.4437C190.091 51.1221 182.423 43.5654 172.964 43.5654Z" fill="#DB1F6B"/>
                    <path d="M89.9456 86.2039C94.5546 86.2039 98.291 82.4689 98.291 77.8616C98.291 73.2543 94.5546 69.5193 89.9456 69.5193C85.3365 69.5193 81.6001 73.2543 81.6001 77.8616C81.6001 82.4689 85.3365 86.2039 89.9456 86.2039Z" fill="#FFFFFD"/>
                    <path d="M155.782 86.2039C160.391 86.2039 164.127 82.4689 164.127 77.8616C164.127 73.2543 160.391 69.5193 155.782 69.5193C151.173 69.5193 147.437 73.2543 147.437 77.8616C147.437 82.4689 151.173 86.2039 155.782 86.2039Z" fill="#FFFFFD"/>
                    <path d="M122.863 105.669C118.336 105.669 113.816 104.033 110.372 100.761C109.1 99.5525 109.1 97.588 110.372 96.3795C111.644 95.171 113.712 95.171 114.984 96.3795C119.328 100.507 126.399 100.507 130.743 96.3795C132.015 95.171 134.083 95.171 135.355 96.3795C136.627 97.588 136.627 99.5525 135.355 100.761C131.911 104.033 127.384 105.669 122.863 105.669Z" fill="#FFFFFD"/>
                    <path d="M165.055 220.607H141.873V224.607H165.055V220.607Z" fill="#DB1F6B"/>
                    <path d="M101.072 220.607H77.8906V224.607H101.072V220.607Z" fill="#DB1F6B"/>
                    <path d="M62.8818 137.636C65.4487 137.636 67.5307 139.724 67.5307 142.283C67.5307 144.843 65.4423 146.93 62.8818 146.93C60.3214 146.93 58.2329 144.843 58.2329 142.283C58.2329 139.724 60.3214 137.636 62.8818 137.636ZM62.8818 134.404C58.5303 134.404 55 137.933 55 142.283C55 146.633 58.5303 150.162 62.8818 150.162C67.2333 150.162 70.7636 146.633 70.7636 142.283C70.7636 137.933 67.2333 134.404 62.8818 134.404Z" fill="#DB1F6B"/>
                    <path d="M84.209 137.636C86.7759 137.636 88.8579 139.724 88.8579 142.283C88.8579 144.843 86.7694 146.93 84.209 146.93C81.6485 146.93 79.5601 144.843 79.5601 142.283C79.5601 139.724 81.6485 137.636 84.209 137.636ZM84.209 134.404C79.8575 134.404 76.3271 137.933 76.3271 142.283C76.3271 146.633 79.8575 150.162 84.209 150.162C88.5605 150.162 92.0908 146.633 92.0908 142.283C92.0908 137.933 88.5605 134.404 84.209 134.404Z" fill="#DB1F6B"/>
                    <rect x="68" y="180" width="108" height="10" rx="5" fill="#DB1F6B"/>
                </svg>
                {/* Phone svg */}
                <svg className='mx-auto mb-[28px] hidden max-tablet:block max-w-[306px] w-full h-[241px]' viewBox="0 0 306 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M204.86 22.0997C223.86 22.0997 239.311 37.4884 239.311 56.4101V181.809C239.311 200.73 223.86 216.119 204.86 216.119H101.958C82.9586 216.119 67.5068 200.73 67.5068 181.809V56.4101C67.5068 37.4884 82.9586 22.0997 101.958 22.0997H204.86ZM204.86 17.6113H101.958C80.4412 17.6113 63 34.9813 63 56.4101V181.809C63 203.237 80.4412 220.607 101.958 220.607H204.86C226.377 220.607 243.818 203.237 243.818 181.809V56.4101C243.818 34.9813 226.377 17.6113 204.86 17.6113Z" fill="#DB1F6B"/>
                    <path d="M204.437 43.5654H103.308C93.8495 43.5654 86.1816 51.1221 86.1816 60.4437V103.622C86.1816 112.943 93.8495 120.5 103.308 120.5H204.437C213.896 120.5 221.563 112.943 221.563 103.622V60.4437C221.563 51.1221 213.896 43.5654 204.437 43.5654Z" fill="#DB1F6B"/>
                    <path d="M121.418 86.2037C126.027 86.2037 129.764 82.4687 129.764 77.8613C129.764 73.254 126.027 69.519 121.418 69.519C116.809 69.519 113.073 73.254 113.073 77.8613C113.073 82.4687 116.809 86.2037 121.418 86.2037Z" fill="#FFFFFD"/>
                    <path d="M187.255 86.2037C191.864 86.2037 195.6 82.4687 195.6 77.8613C195.6 73.254 191.864 69.519 187.255 69.519C182.646 69.519 178.909 73.254 178.909 77.8613C178.909 82.4687 182.646 86.2037 187.255 86.2037Z" fill="#FFFFFD"/>
                    <path d="M154.336 105.669C149.809 105.669 145.289 104.033 141.845 100.761C140.573 99.5525 140.573 97.588 141.845 96.3795C143.117 95.171 145.184 95.171 146.456 96.3795C150.801 100.507 157.872 100.507 162.216 96.3795C163.488 95.171 165.556 95.171 166.828 96.3795C168.1 97.588 168.1 99.5525 166.828 100.761C163.383 104.033 158.856 105.669 154.336 105.669Z" fill="#FFFFFD"/>
                    <path d="M196.528 220.607H173.346V224.607H196.528V220.607Z" fill="#DB1F6B"/>
                    <path d="M132.545 220.607H109.363V224.607H132.545V220.607Z" fill="#DB1F6B"/>
                    <path d="M94.3545 137.636C96.9214 137.636 99.0034 139.724 99.0034 142.283C99.0034 144.843 96.9149 146.93 94.3545 146.93C91.794 146.93 89.7056 144.843 89.7056 142.283C89.7056 139.724 91.794 137.636 94.3545 137.636ZM94.3545 134.404C90.003 134.404 86.4727 137.933 86.4727 142.283C86.4727 146.633 90.003 150.162 94.3545 150.162C98.706 150.162 102.236 146.633 102.236 142.283C102.236 137.933 98.706 134.404 94.3545 134.404Z" fill="#DB1F6B"/>
                    <path d="M115.682 137.636C118.249 137.636 120.331 139.724 120.331 142.283C120.331 144.843 118.242 146.93 115.682 146.93C113.121 146.93 111.033 144.843 111.033 142.283C111.033 139.724 113.121 137.636 115.682 137.636ZM115.682 134.404C111.33 134.404 107.8 137.933 107.8 142.283C107.8 146.633 111.33 150.162 115.682 150.162C120.033 150.162 123.563 146.633 123.563 142.283C123.563 137.933 120.033 134.404 115.682 134.404Z" fill="#DB1F6B"/>
                    <rect x="99.4727" y="180" width="108" height="10" rx="5" fill="#DB1F6B"/>
                </svg>

            </div>
        )

    }

    return (
        <div>
            {isVisible ? (
                header
            )
             : (
                <div className={`pt-[27px] ${isVisible ? 'hidden' : '' }`} style={{ overflow: 'hidden' }}>
                    <iframe
                        className='w-[100%] tablet:w-[105%] h-[550px] tablet:h-[750px]'
                        src="https://meet.brevo.com/encodelyt-meeting/formulario-de-contacto"
                        style={{ overflow: 'auto' }}
                    ></iframe>
                    {type === 'encodelyt' || type == 'alicia' ? (
                        <div className='flex justify-center mb-[36px] mt-[16px]'>
                            <HoverableMail type={type} />
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    )
}