import React, { useState, useEffect} from 'react';
import { LoadingWheel } from '@/components/base/LoadingWheel';
import { fetchEncodelytPartnerLogos, getFullUrl } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';
import { Link } from 'react-router-dom';


export const PartnerList = ({ type }) => {
    const [partnerLogos, setPartnerLogos] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
          const data = await fetchEncodelytPartnerLogos();
          setPartnerLogos(data);
        }
        asyncFoldTry(fetchData);
    }, []);

    let cls;
    if (type === 'encodelyt') {
        cls = 'base-i-small-title font-medium';
    } else if (type === 'alicia') {
        cls = 'base-p-small-title';
    }

    return ( 
    <div className='flex justify-center'>        
        <div className='flex flex-col max-w-[335px] w-full'>
            <span className={`${cls} max-tablet:mb-[27px] tablet:mb-[39px] max-tablet:text-center`}>Nuestros socios</span>
            {partnerLogos ? (
                <div>
                    <div className='flex justify-between max-w-[311px] w-full mb-[27px]'>
                        <Link to='https://anid.cl/' target="_blank">
                            <img 
                                src={getFullUrl(partnerLogos.anid.data.attributes.url)}
                                alt={partnerLogos.anid.data.attributes.alternativeText}
                            />
                        </Link>
                        <Link to='https://openbeauchef.cl/' target="_blank">
                            <img 
                                src={getFullUrl(partnerLogos.openBeauchef.data.attributes.url)}
                                alt={partnerLogos.openBeauchef.data.attributes.alternativeText}
                            />
                        </Link>
                    </div>
                    <div className='flex justify-between'>
                        <Link to='https://www.corfo.cl/' target='_blank'>                    
                            <img 
                                src={getFullUrl(partnerLogos.corfo.data.attributes.url)}
                                alt={partnerLogos.corfo.data.attributes.alternativeText}
                            />
                        </Link>
                        <Link to='https://ohco.work/' target="_blank">
                            <img 
                                src={getFullUrl(partnerLogos.ohcowork.data.attributes.url)}
                                alt={partnerLogos.ohcowork.data.attributes.alternativeText}
                            />
                        </Link>
                    </div>
                </div>  
            ) :  <LoadingWheel type={type} />
        }
        </div>
    </div> 
    )
};