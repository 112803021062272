import React, {useState } from 'react';


export const SoundSegmentSwitch = ({ fieldValue, fieldSetter }) => {
    const [checked, setChecked] = useState(fieldValue);

    const handleChange = () => {
        if (!fieldSetter) return;
        const newChecked = !checked;
        setChecked(newChecked);
        fieldSetter(newChecked);
      };

    return (
        <div className='flex items-center'>
            <span className='base-p-small-text w-[65px] mr-[9px] hidden max-tablet:block' style={{ color: "#888", fontStyle: "italic" }}>{checked ? 'Activo' : 'Inactivo'}</span>
            <label className={`flex items-center ${fieldSetter ? 'cursor-pointer' : ''}`}>
                <div className="relative">
                    <input
                        type="checkbox"
                        className="hidden"
                        checked={checked}
                        onChange={handleChange}
                    />
                    <div className="toggle__line w-[52px] h-[32px] rounded-[100px]" style={{ background: `${checked ? '#007126' : '#888'}` }}></div>
                    <div className={`toggle__dot absolute w-[24px] h-[24px] rounded-full top-[4px] right-[24px] ${checked ? 'translate-x-[20px]' : ''}`} style={{ background: '#FFF' }}></div>
                </div>
            </label>    
        </div>
    )   
}