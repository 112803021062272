import React, { useState, useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navbar } from '@/components/encodelyt/Navbar';
import { Footer } from '@/components/encodelyt/Footer';
import { fetchEncodelytLogo, fetchEncodelytFirst5Services } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';


const withEncodelytSettings = (Component) => {
    return function WithEncodelytSettings(props) {
        const [logo, setLogo] = useState(null);
        const [services, setServices] = useState([]);
        const startRef = useRef(null);

        useEffect(() => {
            const fetchData = async () => {
                const data = await fetchEncodelytLogo();
                setLogo(data.v1 ? data.v1.data : null);
            }
            asyncFoldTry(fetchData);
        }, []);

        useEffect(() => {
            const fetchData = async () => {
                const { data } = await fetchEncodelytFirst5Services();
                setServices(data);
            }
            asyncFoldTry(fetchData);
        }, []);

        return (
            <>
            <HelmetProvider>
                <Helmet>
                    <title>Encodelyt</title>
                    <link rel="icon" type="image/svg+xml" href="/encodelyt.svg" />
                </Helmet>
                <div ref={startRef} className='flex flex-col w-full min-h-screen'>
                    <Navbar logo={logo} services={services}/>
                    <div className='w-full h-full grow max-tablet:mt-[131px]'>
                        <Component {...props} services={services} />
                    </div>
                    <Footer logo={logo} serviceId={services[0]?.id} startRef={startRef} />
                    </div>    
            </HelmetProvider>
            </>
        );
    }
};

export default withEncodelytSettings;