import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from '@/components/alicia/Footer';
import { fetchAliciaLogo, fetchEncodelytFirst5Services } from '@/api/strapi';
import { asyncFoldTry } from '@/api/base';


const withAliciaSettings = (Component) => {
  return function WithAliciaSettings(props) {
    const [logoV1, setLogoV1] = useState(null);
    const [logoV2, setLogoV2] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    
    useEffect(()=> {
      const fetchData = async () => {
        const data = await fetchAliciaLogo();
        setLogoV1(data.v1 ? data.v1.data : null);
        setLogoV2(data.v2 ? data.v2.data : null);
      }
      asyncFoldTry(fetchData);
    }, []);

    useEffect(() => {
      const fetchData = async () => {
          const { data } = await fetchEncodelytFirst5Services();
          setServiceId(data[0]?.id);
      }
      asyncFoldTry(fetchData);
  }, []);

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>αlicia</title>
            <link rel="icon" type="image/svg+xml" href="/alicia.svg" />
          </Helmet>
          <div className='flex flex-col w-full min-h-screen'>
            <div className='grow'>
              <Component {...props} logoV1={logoV1} logoV2={logoV2} />
            </div>
            <Footer logo={logoV2} serviceId={serviceId} />
          </div>
        </HelmetProvider>
      </>
    );
  }
};

export default withAliciaSettings;