import React from 'react';
import { getFullUrl } from '@/api/strapi';
import '@/styles/encodelyt/Service.css';


export const Industry = ({ name, icon }) => {
  return (
    <div 
        className='flex flex-col items-center rounded-[15px] gap-[8px] w-[217px] px-[20px] py-[16px]' 
        style={{ background: '#FFFFFF' }}
    >
        <img 
          className='h-[80px]' 
          src={getFullUrl(icon.attributes.url)}
          alt={icon.attributes.alternativeText}
        />
        <div className='p-[10px]'>
            <span className='base-i-small-text'>{name}</span>
        </div>
    </div>
  );
};
