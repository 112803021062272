import React from 'react';
import { getFullUrl } from '@/api/strapi';


export const Step = ({ description, image }) => {

  return (
    <div className='flex flex-col'>
        <img 
          src={getFullUrl(image.attributes.url)}
          alt={image.attributes.alternativeText}
        />
        <div className='max-desktop:max-w-[75%] mx-auto max-desktop:mt-[20px] desktop:mt-[56px]'>
            <span className='base-p-small-title text-center max-desktop:mt-[20px] desktop:mt-[56px]'>
                {description}
            </span>
        </div>
    </div>
  );
};